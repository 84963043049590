import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BetaAccessLandingComponent } from './beta-access-landing/beta-access-landing.component';
import { BetaAccessFormModule } from '../beta-access-form/beta-access-form.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularTiltModule } from 'angular-tilt';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [BetaAccessLandingComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        RouterModule,
        BrowserAnimationsModule,
        AngularTiltModule,
        MatSliderModule,
        MatIconModule,
        MatButtonModule,
        BetaAccessFormModule,
        MatSelectModule
    ]
})
export class BetaAccessLandingModule {
}
