<form [formGroup]="questionFormGroup">
    <mat-form-field appearance="fill"
                    class="question">
        <mat-label>{{ "GENERATION.TEXT_QUESTION" | translate }}</mat-label>
        <input id="textQuestion"
               matInput
               formControlName="textQuestionControl">
        <mat-error *ngIf="
        selectedQuestionType === questionTypes.TEXT &&
        questionFormGroup.get('textQuestionControl').hasError('required') &&
        questionFormGroup.get('textQuestionControl').touched">
            {{ "ERROR.QUESTION_REQUIRED" | translate }}
        </mat-error>
    </mat-form-field>
    <div *ngIf="selectedQuestionType === questionTypes.IMAGE ||
    selectedQuestionType === questionTypes.VIDEO ||
    selectedQuestionType === questionTypes.AUDIO">
        <div class="form-group">
            <label for="file">File</label>
            <input formControlName="file"
                   id="file"
                   type="file"
                   class="form-control"
                   [accept]="selectedQuestionType === questionTypes.IMAGE ? '.jpg,.png' :
                   selectedQuestionType === questionTypes.VIDEO ? '.mp4' : '.mp3,.wav'"
                   (change)="onFileChange($event)">
            <div *ngIf="questionFormGroup.controls.file.touched && questionFormGroup.controls.file.invalid" class="alert alert-danger">
                <div *ngIf="questionFormGroup.controls.file.errors.required">File is required.</div>
            </div>
        </div>

        <img [src]="fileSrc" *ngIf="fileSrc && selectedQuestionType === questionTypes.IMAGE" style="height: 300px; width:500px">
        <video [src]="fileSrc" *ngIf="fileSrc && selectedQuestionType === questionTypes.VIDEO" height="200" controls></video>
        <audio *ngIf="fileSrc && selectedQuestionType === questionTypes.AUDIO" controls><source [src]="domSanitizer.bypassSecurityTrustResourceUrl(fileSrc)"></audio>
    </div>
    <!--<div *ngIf="selectedQuestionType === questionTypes.VIDEO">
        <label for="file">File</label>
        <input formControlName="file"
               id="videoFile"
               type="file"
               class="form-control"
               (change)="onFileChange($event)">
        <div *ngIf="questionFormGroup.controls.file.touched && questionFormGroup.controls.file.invalid" class="alert alert-danger">
            <div *ngIf="questionFormGroup.controls.file.errors.required">File is required.</div>
        </div>

        <video [src]="videoSrc" *ngIf="videoSrc" height="200" controls></video>
    </div>-->
</form>
