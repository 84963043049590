import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    exports: [
        BrowserAnimationsModule,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatCardModule,
        MatSelectModule,
        MatSnackBarModule,
        MatStepperModule,
        MatButtonToggleModule,
        MatSliderModule,
        MatDialogModule,

        /*
         MatCheckboxModule,
         MatDividerModule,
         MatTableModule,
         MatSortModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatSelectModule,
         MatDatepickerModule,
         MatNativeDateModule,
         MatExpansionModule,
         MatSlideToggleModule,
         DragDropModule,
         MatTabsModule,
         MatRadioModule,
         MatGridListModule,
         MatBottomSheetModule,
         ScrollingModule,
         MatTooltipModule,
         MatToolbarModule,
         MatBadgeModule,
         MatMenuModule,
         PaginatorI18nModule,
         MatSidenavModule,
         MatAutocompleteModule
        * */
    ]
})
export class MaterialAllInOneModule { }
