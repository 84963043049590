import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoomManagerComponent } from './room-manager/room-manager.component';
import { RoomManagerService } from './room-manager.service';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
    declarations: [
        RoomManagerComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild(),
        QRCodeModule
    ],
    providers: [
        RoomManagerService
    ]
})
export class RoomManagerModule {}
