import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { QuizInfo, QuizQuestionInfo, QuizConstructorService } from '../quiz-constructor.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-quiz-editor',
    templateUrl: './quiz-editor.component.html',
    styleUrls: ['./quiz-editor.component.scss']
})
export class QuizEditorComponent implements OnInit, OnDestroy {

    quizInfoS: Subscription;
    quizInfo: QuizInfo;

    constructor(
        private router: Router,
        private quizService: QuizConstructorService
    ) { }

    ngOnInit() {
        this.quizInfo = JSON.parse(sessionStorage.getItem('SelectedQuizInfo'));
        this.quizInfoS = this.quizService.quizSubject.subscribe(quizInfo => {
            this.quizInfo = quizInfo;
        });
        this.quizService.getQuiz(this.quizInfo.id);
        /*this.quizInfoS = this.quizService.getQuiz(this.quizInfo.id).subscribe(quizInfo => {
            this.quizInfo = quizInfo;
        });*/
    }

    updateQuestion(quizQuestionInfo: QuizQuestionInfo) {

    }

    createQuizQuestion() {
        this.router.navigate(['/question-generation']);
    }

    ngOnDestroy() {
        this.quizInfoS.unsubscribe();
    }

    removeQuestion(questionId: string) {
        this.quizService.deleteQuestion(questionId);
    }

    backToQuizList() {
        this.router.navigate(['quiz-list']);
    }
}
