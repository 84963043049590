import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

    public lang = 'en';

    constructor(public translate: TranslateService) {
        if (localStorage.getItem('selectedLang')) {
            this.lang = localStorage.getItem('selectedLang');
        } else if (translate.getBrowserLang() === 'ru') {
            this.lang = 'ru';
        }
    }

    ngOnInit() {
    }

}
