import { Component, OnInit } from '@angular/core';
import { RoomManagerService } from '../room-manager.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { PlayerInfo } from '../../../../proto/generated/room_pb';

@Component({
    selector: 'app-room-manager',
    templateUrl: './room-manager.component.html',
    styleUrls: ['./room-manager.component.scss']
})
export class RoomManagerComponent implements OnInit {

    public isHost = false;
    public action = '';
    public roomId = '';
    public players = new Array<PlayerInfo>();

    constructor(
        private roomManagerService: RoomManagerService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.roomManagerService.waitRoomMessageResponseSubject.subscribe(roomState => {
            console.log('Got new room state');
            this.players = new Array(...roomState.getConnectedplayersList());
        });
        this.activatedRoute.queryParams.subscribe(params => {
            const action: 'run' | 'join' = params.action;
            if (action === 'run') {
                // Subscribe to get response message once on create
                this.roomManagerService.createRoomMessageResponseSubject
                    .pipe(first())
                    .subscribe(createRoomMessageResponse => {
                        const roomId = createRoomMessageResponse.getRoomid().toString();
                        localStorage.setItem('roomId', roomId);
                        this.roomId = roomId;
                    });
                this.isHost = true;
                const quizId: string = params.id;
                console.log(`Run game ${params.game} with id ${quizId}`);
                this.roomManagerService.createRoom(quizId);
            } else if (action === 'join') {
                // Subscribe to get response message once on join
                this.roomManagerService.joinRoomMessageResponseSubject
                    .pipe(first())
                    .subscribe(joinRoomMessageResponse => {
                        const roomId = joinRoomMessageResponse.getRoomid().toString();
                        localStorage.setItem('roomId', roomId);
                        this.roomId = roomId;
                        this.roomManagerService.roomStatusRequest(roomId);
                    });
                this.roomId = params.id;
                this.roomManagerService.joinRoom(this.roomId);
            }
        });
    }

    startButtonHandler() {
        this.roomManagerService.startRequest(this.roomId);
    }
}
