import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as auth from 'firebase/auth';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat';
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import firebase from 'firebase/compat/app';
// import auth = firebase.auth;
// import { auth } from 'firebase/app';

@Injectable()
export class AuthService {



    public authSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public checkAuthSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private userData: any;

    constructor(
        public angularFireAuth: AngularFireAuth,
        private httpClient: HttpClient
    ) {
        // console.log('CHECK SESSION');
        this.checkSession();
        /*angularFireAuth.authState.subscribe(user => {
            console.log('USER 1', user);
            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
                this.checkSession();
            } else {
                this.checkAuthSubject.next(false);
                localStorage.setItem('user', null);
            }
        });*/
    }

    public authWithGoogle() {
        this.authLogin(new auth.GoogleAuthProvider());
    }

    private authLogin(provider) {
        this.angularFireAuth.signInWithPopup(provider)
            .then(result => {
                result.user.getIdToken()
                    .then(token => {
                        this.httpClient.post<{ cookie: string }>(
                            environment.URLPrefix + '/auth/login',
                            {},
                            {
                                headers: {
                                    Authorization: 'Bearer ' + token
                                }
                            }
                        ).subscribe(ans => {
                            // console.log('AUTH RESULT ', ans);
                            console.log('AUTH RESPONSE', ans);
                            if (ans.cookie) {
                                localStorage.setItem('sid', ans.cookie);
                                this.authSubject.next(true);
                            } else {
                                console.log('Sign in response not contains sid');
                                this.authSubject.error('Sign in response not contains sid');
                            }
                        });
                    });
            })
            .catch(error => {
                console.log(error);
                this.authSubject.error('Sign in with popup error');
            });
    }

    public signOut() {
        this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/auth/logout',
            {}
        ).subscribe(ans => {
            console.log(ans);
        });
    }

    private checkSession() {
        return this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/auth/checkCookie',
            {}
        ).subscribe(
            ans => {
                console.log(ans);
                this.checkAuthSubject.next(false);
                if (ans.result) {
                    this.authSubject.next(true);
                } else {
                    // this.authSubject.error('Check session answer error');
                    console.log('Check session answer error');
                }
            },
            error => {
                this.checkAuthSubject.next(false);
                // this.authSubject.error('Check session error');
                console.log('Check session error', error);
            }
        );
    }
}
