<div class="header">{{ "GENERATION.CREATING_QUESTION" | translate }}</div>
<mat-vertical-stepper [linear]="true">
    <mat-step>
        <form>
            <ng-template matStepLabel style="'background-color: black;'">{{ "GENERATION.QUESTION_TYPE" | translate }}</ng-template>
            <!--<div class="buttons">
                <div (click)="changeSelectedType(questionTypes.TEXT)">Text</div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedType(questionTypes.IMAGE)">
                    Image
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedType(questionTypes.VIDEO)">
                    Video
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedType(questionTypes.AUDIO)">
                    Audio
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
            </div>-->
            <mat-form-field appearance="fill">
                <mat-label>{{ "GENERATION.QUESTION_TYPE" | translate }}</mat-label>
                <mat-select [(value)]="selectedQuestionType">
                    <mat-option [value]="questionTypes.TEXT">{{ "GENERATION.QUESTION_TYPE_TEXT" | translate }}</mat-option>
                    <mat-option [value]="questionTypes.IMAGE" [disabled]="!premium">
                        {{ "GENERATION.QUESTION_TYPE_IMAGE" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                    <mat-option [value]="questionTypes.VIDEO" [disabled]="!premium">
                        {{ "GENERATION.QUESTION_TYPE_VIDEO" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                    <mat-option [value]="questionTypes.AUDIO" [disabled]="!premium">
                        {{ "GENERATION.QUESTION_TYPE_AUDIO" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <form>
            <ng-template matStepLabel>{{ "GENERATION.QUESTION_SETTINGS" | translate }}</ng-template>
            <app-question-constructor [selectedQuestionTypeSetter]="selectedQuestionType"></app-question-constructor>
            <div>
                <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <form>
            <ng-template matStepLabel>{{ "GENERATION.ANSWER_TYPE" | translate }}</ng-template>
            <mat-form-field appearance="fill">
                <mat-label>{{ "GENERATION.ANSWER_TYPE" | translate }}</mat-label>
                <mat-select [(value)]="selectedAnswerType">
                    <mat-option [value]="answerTypes.ONE">{{ "GENERATION.ONE_ANSWER" | translate }}</mat-option>
                    <mat-option [value]="answerTypes.MULTI">{{ "GENERATION.MULTIPLE_ANSWER" | translate }}</mat-option>
                    <mat-option [value]="answerTypes.SPEED">{{ "GENERATION.WHO_IS_FIRST" | translate }}</mat-option>
                    <mat-option [value]="answerTypes.CUSTOM" [disabled]="!premium">
                        {{ "GENERATION.CUSTOM_INPUT" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!--<div class="buttons">
                <div (click)="changeSelectedAnswerType(answerTypes.ONE)">One correct</div>
                <div (click)="changeSelectedAnswerType(answerTypes.MULTI)">Multi</div>
                <div (click)="changeSelectedAnswerType(answerTypes.SPEED)">Speed answer</div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedAnswerType(answerTypes.CUSTOM)">
                    Custom input
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
            </div>-->
            <div>
                <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <form>
            <ng-template matStepLabel>{{ "GENERATION.ANSWER_SETTINGS" | translate }}</ng-template>
            <app-answer-constructor [selectedAnswerType]="selectedAnswerType"></app-answer-constructor>
            <div>
                <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>{{ "GENERATION.SHOWING_ANSWER" | translate }}</ng-template>
        <button (click)="saveQuestion()">Save</button>
        <p>You are now done.</p>
        <div>
            <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
            <!--            <button mat-button (click)="stepper.reset()">Reset</button>-->
        </div>
    </mat-step>
</mat-vertical-stepper>

<!--
<div class="header">Creating question</div>
<div class="buttons">
    <div (click)="changeSelectedType(questionTypes.TEXT)">Text</div>
    <div [ngClass]="premium ? '' : 'disabled'"
         (click)="changeSelectedType(questionTypes.IMAGE)">
        Image
        <mat-icon *ngIf="!premium">loyalty</mat-icon>
    </div>
    <div [ngClass]="premium ? '' : 'disabled'"
         (click)="changeSelectedType(questionTypes.VIDEO)">
        Video
        <mat-icon *ngIf="!premium">loyalty</mat-icon>
    </div>
    <div [ngClass]="premium ? '' : 'disabled'"
         (click)="changeSelectedType(questionTypes.AUDIO)">
        Audio
        <mat-icon *ngIf="!premium">loyalty</mat-icon>
    </div>
</div>
<app-answer-constructor *ngIf="selectedQuestionType === questionTypes.TEXT"></app-answer-constructor>
-->
