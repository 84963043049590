import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionType } from '../../quiz-constructor.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-question-constructor',
    templateUrl: './question-constructor.component.html',
    styleUrls: ['./question-constructor.component.scss']
})
export class QuestionConstructorComponent implements OnInit {

    @Input()
    set selectedQuestionTypeSetter(questionType: QuestionType) {
        this.selectedQuestionType = questionType;
        if (this.selectedQuestionType === QuestionType.TEXT) {
            this.textQuestionControl.addValidators([Validators.required]);
        } else {
            this.textQuestionControl.removeValidators([Validators.required]);
        }
    }
    public selectedQuestionType = QuestionType.TEXT;

    public questionFormGroup: FormGroup;
    public questionTypes = QuestionType;
    public textQuestion = '';
    public textQuestionControl = new FormControl(
        this.textQuestion,
        [
            Validators.min(1),
            Validators.max(1000)
        ]
    );

    fileSrc: string;

    constructor(
        private formBuilder: FormBuilder,
        public domSanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.questionFormGroup = this.formBuilder.group({
            textQuestionControl: this.textQuestionControl,
            file: new FormControl('', [Validators.required]),
            fileSource: new FormControl('', [Validators.required])
        });
    }

    onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.fileSrc = reader.result as string;

                this.questionFormGroup.patchValue({
                    fileSource: reader.result
                });

            };

        }
    }
}
