import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

export interface User {
    id: number;
    nickname: string;
    avatarUrl: string;
}

export interface UserList {
    users: Array<User>;
}

@Injectable()
export class FriendsService {

    public friendsListSubject = new Subject<UserList>();
    public searchListSubject = new Subject<UserList>();
    public incomingListSubject = new Subject<UserList>();
    public outgoingListSubject = new Subject<UserList>();

    constructor(private httpClient: HttpClient) { }

    public friendsInfoRequest() {
        this.friendsRequest();
        this.incomingFriendsRequest();
        this.outgoingFriendsRequest();
    }

    public friendsRequest() {
        this.httpClient.post<UserList>(
            environment.URLPrefix + '/user/friends',
            {}
        ).subscribe(ans => {
            this.friendsListSubject.next(ans);
        });
    }

    public searchFriends(searchString: string) {
        this.httpClient.post<UserList>(
            environment.URLPrefix + '/user/search',
            encodeURIComponent(JSON.stringify({searchString}))
        ).subscribe(ans => {
            this.searchListSubject.next(ans);
        });
    }

    public incomingFriendsRequest() {
        this.httpClient.post<UserList>(
            environment.URLPrefix + '/user/incomingFriendRequests',
            {}
        ).subscribe(ans => {
            this.incomingListSubject.next(ans);
        });
    }

    public outgoingFriendsRequest() {
        this.httpClient.post<UserList>(
            environment.URLPrefix + '/user/outgoingFriendRequests',
            {}
        ).subscribe(ans => {
            this.outgoingListSubject.next(ans);
        });
    }

    public addFriend(id: number) {
        this.httpClient.post<UserList>(
            environment.URLPrefix + '/user/addFriend',
            encodeURIComponent(JSON.stringify({id}))
        ).subscribe(ans => {
            this.friendsInfoRequest();
        });
    }

    public removeFriend(id: number) {
        this.httpClient.post<UserList>(
            environment.URLPrefix + '/user/removeFriend',
            encodeURIComponent(JSON.stringify({id}))
        ).subscribe(ans => {
            this.friendsInfoRequest();
        });
    }
}
