import { WebSocketConnect } from './WebSocketConnect';
import { IMessageStream } from './IMessageStream';

class StreamReaderCallback {
  public fnc: (res: any) => void | Promise<any>; // callback func
  public err: (err: any) => void;
  public method: (args: any) => void; // function in StreamReader
  public arg: any;
  constructor(method: (args: any) => void, fnc: (res: any) => void | Promise<any>, err: (err: any) => void, arg?: any) {
    this.fnc = fnc;
    this.method = method;
    this.arg = arg;
    this.err = err;
  }
}

export class StreamReader {
  private cache: Array<any>;
  private callbacks: Array<StreamReaderCallback> = [];
  protected ms: IMessageStream;
  constructor(ms: IMessageStream) {
    this.cache = new Array();
    this.ms = ms;
    this.ms.onmessage = this._next.bind(this);
    this.ms.onclose = this._close.bind(this, 'Connection closed');
    this.ms.onerror = this._close.bind(this, 'Connection error');
  }
  
  protected _next(data: any) {
    this.cache.push(data);
    this._startCallbacks();
    if (this.cache.length > 200) {
      console.log('MEMORY LEAK DETECTED');
    }
  }

  protected getCallbacksCount(): number {
    return this.callbacks.length;
  }

  protected getCacheCount(): number {
    return this.cache.length;
  }

  protected _close(reason: string) {
    for (const callback of this.callbacks) {
      callback.err.call(this, reason);
    }
  }

  public readLine(fnc: (args: any) => void, err?: (mee: string) => void) {
    this.callbacks.push(new StreamReaderCallback(this._readLine.bind(this), fnc, err));
    this._startCallbacks();
    return this;
  }

  public clearCallbacks() {
    this.callbacks = [];
  }

  public destroy() {
    this.clearCallbacks();
    this.cache = [];
  }

  public readXml(fnc: any, err?: any) {
    this.callbacks.push(new StreamReaderCallback(this._readXml.bind(this), fnc, err));
    this._startCallbacks();
    return this;
  }

  public readBytes(size: number, fnc: (args: any) => void, err?: (mee: string) => void): StreamReader {
    this.callbacks.push(new StreamReaderCallback(this._readBytes.bind(this), fnc, err, size));
    this._startCallbacks();
    return this;
  }

  public asyncReadLine(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.readLine(resolve, reject);
    });
  }

  public async asyncReadXml(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.readXml(resolve, reject);
    });
  }

  public asyncReadBytes(size: number): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.readBytes(size, resolve, reject);
    });
  }

  protected clearCache() {
    this.cache = [];
  }

  private _readBytes(size: number): any {
    let res: any = null;
    if (this.cache.length) {
      const findres: Array<number> = this._searchLenInCache(size);
      if (findres) {
        res = this._getMessageFromCache(findres[0], findres[1]);
      }
    }
    return res;
  }

  private readUint8String(buf: any): string {
    if (typeof buf === 'string') {
      return buf;
    }
    if (buf instanceof ArrayBuffer) {
      return String.fromCharCode.apply(null, new Uint8Array(buf));
    }
    if (buf instanceof Uint8Array) {
      return String.fromCharCode.apply(null, buf);
    }
  }

  private _readXml() {
    let res: any = null;
    if (this.cache.length) {
      const findres: Array<number> = this._searchTagInCache();
      if (findres) {
        res = this._getMessageFromCache(findres[0], findres[1]);
      }
    }
    return res;
  }

  private _readLine(): any {
    let res: any = null;
    if (this.cache.length) {
      const findres: Array<number> = this._searchSymbolInCache();
      if (findres) {
        res = this._getMessageFromCache(findres[0], findres[1]);
      }
    }
    return res;
  }

  private _getMessageFromCache(endcache: number, endindx: number): ArrayBuffer | string {
    if (this.cache.length <= endcache) {
      return null;
    }
    if (this.cache[endcache].length <= endindx) {
      return null;
    }

    const first: any = this.cache[0];
    let res: any = '';
    if (typeof first === 'string') {
      for (let i = 0; i < endcache; i++) {
        res += this.cache[i];
      }
      res += this.cache[endcache].substr(0, endindx + 1);
    } else if (first instanceof ArrayBuffer) {
      let fulllen: number = endindx + 1;
      for (let i = 0; i < endcache; i++) {
        fulllen += this.cache[i].byteLength;
      }

      const arrbuff = new ArrayBuffer(fulllen);
      const view: Uint8Array = new Uint8Array(arrbuff);
      let k = 0;
      for (let i = 0; i <= endcache; i++) {
        const ens: number = (i === endcache) ? endindx + 1 : this.cache[i].byteLength;
        const vw: Uint8Array = new Uint8Array(this.cache[i]);
        for (let j = 0; j < ens; j++) {
          view[k++] = vw[j];
        }
      }
      res = arrbuff;
    } else if (first instanceof Uint8Array) {
      let fulllen: number = endindx + 1;
      for (let i = 0; i < endcache; i++) {
        fulllen += this.cache[i].byteLength;
      }

      const arrbuff = new Uint8Array(fulllen);
      let k = 0;
      for (let i = 0; i <= endcache; i++) {
        const ens = (i === endcache) ? endindx + 1 : this.cache[i].byteLength;
        const vw: Uint8Array = this.cache[i];
        for (let j = 0; j < ens; j++) {
          arrbuff[k++] = vw[j];
        }
      }
      res = arrbuff;
    }
    if (endcache > 0) {
      this.cache.splice(0, endcache);
    }
    if (endindx === (this.cache[0].length || this.cache[0].byteLength) - 1) {
      this.cache.splice(0, 1);
    } else {
      this.cache[0] = this.cache[0].slice(endindx + 1);
    }
    return res;
  }

  private _searchSymbolInCache(symbol: string = '\n'): number[] {
    for (let i = 0; i < this.cache.length; i++) {
      const item = this.cache[i];
      if (typeof item === 'string') {
        const ind = item.indexOf(symbol);
        if (ind > -1) {
          return [i, ind];
        }
      } else if (item instanceof Uint8Array) {
        const ind = item.indexOf(symbol.charCodeAt(0));
        if (ind > -1) {
          return [i, ind];
        }
      } else if (item instanceof ArrayBuffer) {
        const buf: Uint8Array = new Uint8Array(item);
        const ind = buf.indexOf(symbol.charCodeAt(0));
        if (ind > -1) {
          return [i, ind];
        }
      }
    }
    return null;
  }

  private _searchTagInCache(): number[] {
    let item: string = this.cache[0];
    let tag = item.substring(0, item.indexOf('>') + 1);
    if (tag.includes('/')) {
      return [
        0,
        tag.length
      ];
    } else {
      if (tag.includes(' ')) {
        tag = '</' + tag.substring(1, tag.indexOf(' ')) + '>';
      } else {
        tag = '</' + tag.substring(1, tag.length);
      }
      for (let i = 0; i < this.cache.length; i++) {
        item = this.cache[i];
        const index = item.indexOf(tag);
        if (index > -1) {
          return [
            i,
            index + tag.length
          ];
        }
      }
    }
    return null;
  }

  private _searchLenInCache(len: number): Array<number> {
    let fullsize = 0;
    for (let i = 0; i < this.cache.length; i++) {
      const cur = (this.cache[i].length || this.cache[i].byteLength);
      if (fullsize + cur >= len) {
        return [i, len - fullsize - 1];
      }
      fullsize += cur;
    }
    return null;
  }

  protected _startCallbacks() {
    if (this.callbacks.length) {
      const src: StreamReaderCallback = this.callbacks[0];
      const res: any = src.method.call(this, src.arg);
      if (res !== null) {
        this.callbacks.shift();
        if (src.fnc instanceof Function) {
          src.fnc.call(this, res);
        } else if ((src.fnc as {}) instanceof Promise) {
          (src.fnc as Promise<string | ArrayBuffer>).then(res);
        }

        this._startCallbacks();
      }
    }
  }
}
