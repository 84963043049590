import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                // Accept: 'application/json',
                'Gambo-Cookie': localStorage.getItem('sid') || '',
                // Cookie: `JSESSIONID=${localStorage.getItem('sid')}`,
                'App-Version-Unity': environment.appVersion,
                'Language-Unity': localStorage.getItem('locale-code') || 'en',
                // 'Access-Control-Allow-Origin': document.location.protocol + '//' + location.hostname
            },
            withCredentials: true
        });

        return next.handle(req);
    }
}
