import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth/auth.component';
import { AuthService } from './auth/auth.service';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialAllInOneModule
    ],
    declarations: [
        AuthComponent
    ],
    providers: [
        AuthService
    ]
})
export class AuthModule {
}
