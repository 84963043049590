import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private gtmService: GoogleTagManagerService
    ) {
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url
                };
                this.gtmService.pushTag(gtmTag);
            }
        });
        this.iconRegistry();
    }

    private iconRegistry() {
        this.matIconRegistry.addSvgIcon(
            'apple',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/apple.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'instagram',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/instagram.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'boxes',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/boxes.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'easy',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/easy.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'eco',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/eco.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'fast',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/fast.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'pause',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/pause.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'remote',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/remote.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'suitcase',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/suitcase.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unbreakable',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/unbreakable.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unlimited',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/unlimited.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'worldwide',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/benefits/worldwide.svg')
        );
    }
}
