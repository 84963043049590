<div *ngIf="roomId.length > 0">
    <qrcode [qrdata]="'https://gambo.app/room-manager?action=join&game=quiz&id=' + roomId"
            [width]="256"
            [errorCorrectionLevel]="'M'">
    </qrcode>
</div>
<div *ngIf="players">
    <mat-card *ngFor="let player of players"
              class="quiz-card">
        <mat-card-header>
            <mat-card-title>{{ player.getNickname() }}</mat-card-title>
        </mat-card-header>
<!--        <mat-card-subtitle>{{ player.get }}</mat-card-subtitle>-->
<!--        <mat-card-content>{{ player.description }}</mat-card-content>-->
    </mat-card>
</div>
<button mat-raised-button
        color="primary"
        *ngIf="isHost"
        (click)="startButtonHandler()">
    START
</button>
