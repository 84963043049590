<form [formGroup]="answerFormGroup">
    <div>{{ "GENERATION.ANSWER_TIME" | translate }}</div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>{{ "GENERATION.SECONDS" | translate }}</mat-label>
            <input matInput type="number" formControlName="timeControl">
        </mat-form-field>
        <!--<mat-slider [(value)]="time"
                    [displayWith]="formatTimeLabel"
                    thumbLabel
                    step="5"
                    min="5"
                    max="3600"
                    aria-label="units">
        </mat-slider>-->
    </div>
    <div>{{ "GENERATION.NUMBER_OF_POINTS" | translate }}</div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>{{ "GENERATION.POINTS" | translate }}</mat-label>
            <input matInput type="number" formControlName="pointsControl">
        </mat-form-field>
        <!--<mat-slider [(value)]="time"
                    [displayWith]="formatTimeLabel"
                    thumbLabel
                    step="5"
                    min="5"
                    max="3600"
                    aria-label="units">
        </mat-slider>-->
    </div>
    <div *ngIf="selectedAnswerType === answerTypes.ONE || selectedAnswerType === answerTypes.MULTI">
        <div>{{ "GENERATION.NUMBER_OF_ANSWERS" | translate }}</div>
        <mat-button-toggle-group name="numberOfAnswersControl"
                                 [(value)]="numberOfAnswers">
            <mat-button-toggle [value]="2">2</mat-button-toggle>
            <mat-button-toggle [value]="4">4</mat-button-toggle>
            <mat-button-toggle [value]="6">6</mat-button-toggle>
            <mat-button-toggle [value]="8">8</mat-button-toggle>
        </mat-button-toggle-group>
    <!--    <mat-form-field appearance="fill"-->
    <!--                    class="question">-->
    <!--        <mat-label>Question</mat-label>-->
    <!--        <input matInput formControlName="question">-->
    <!--    </mat-form-field>-->
        <div class="answers">
            <mat-form-field *ngFor="let counter of counter(numberOfAnswers); let i = index"
                            appearance="fill">
                <mat-label>{{ "GENERATION.ANSWER" | translate }} {{ i + 1 }}</mat-label>
                <input matInput formControlName="{{ 'textAnswer' + (i + 1) + 'Control' }}">
                <button matSuffix mat-icon-button aria-label="Check" (click)="setCorrectAnswer(i)">
                    <mat-icon [ngClass]="correctAnswers.includes(i) ? 'green-icon' : 'gray-icon'">check_box</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
</form>
