<div *ngIf="lang === 'en'" class="content">
    <h1>Privacy policy</h1>
    <p>Gambo (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Gambo.</p>
    <p>This Privacy Policy applies to our website, https://gambo.app, and its associated subdomains (collectively, our “Service”). By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy.</p>
    <h2>Definitions and key terms</h2>
    <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</p>
    <ul>
        <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
        <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Gambo that is responsible for your information under this Privacy Policy.</li>
        <li>Customer: refers to the company, organization or person that signs up to use the Gambo Service to manage the relationships with your consumers or service users.</li>
        <li>Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Gambo and use the services.</li>
        <li>Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</li>
        <li>Service: refers to the service provided by Gambo as described in the relative terms (if available) and on this platform.</li>
        <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
        <li>Website: Gambo’s site, which can be accessed via this URL: https://gambo.app</li>
        <li>You: a person or entity that is registered with Gambo to use the Services.</li>
    </ul>
    <h2>What Information Do We Collect?</h2>
    <p>We collect information from you when you visit our website, register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.</p>
    <ul>
        <li>Email Addresses</li>
        <li>Mobile device operating system</li>
    </ul>
    <h2>How Do We Use The Information We Collect?</h2>
    <p>Any of the information we collect from you may be used in one of the following ways:</p>
    <ul>
        <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
        <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)</li>
        <li>To administer a contest, promotion, survey or other site feature</li>
        <li>To send periodic emails</li>
    </ul>
    <h2>How Do We Use Your Email Address?</h2>
    <p>By submitting your email address on this website, you agree to receive emails from us. You can cancel your participation by sending an email to info@gambo.app. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us.</p>
    <h2>How Long Do We Keep Your Information?</h2>
    <p>We keep your information only so long as we need it to provide Gambo to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can't identify you.</p>
    <h2>How Do We Protect Your Information?</h2>
    <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology and then stored into database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. We cannot, however, ensure or warrant the absolute security of any information you transmit to Gambo or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.</p>
    <h2>Your Consent</h2>
    <p>By using our site, or registering an account, you consent to this Privacy Policy.</p>
    <h2>Links to Other Websites</h2>
    <p>This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by Gambo. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p>
    <h2>Cookies</h2>
    <p>Gambo uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
    <h2>Remarketing Services</h2>
    <p>We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or retargeting) is the practice of serving ads across the internet to people who have already visited your website. It allows your company to seem like they're “following” people around the internet by serving ads on the websites and platforms they use most.</p>
    <h2>Kids' Privacy</h2>
    <p>We do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us at info@gambo.app. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
    <h2>Changes To Our Privacy Policy</h2>
    <p>If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.</p>
    <h2>Third-Party Services</h2>
    <p>We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</p>
    <p>You acknowledge and agree that Gambo shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Gambo does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
    <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p>
    <h2>Information about General Data Protection Regulation (GDPR)</h2>
    <p>We may be collecting and using information from you if you are from the European Economic Area (EEA), and in this section of our Privacy Policy we are going to explain exactly how and why is this data collected, and how we maintain this data under protection from being replicated or used in the wrong way.</p>
    <h2>What is GDPR?</h2>
    <p>GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by companies and enhances the control the EU residents have, over their personal data.</p>
    <p>The GDPR is relevant to any globally operating company and not just the EU-based businesses and EU residents. Our customers’ data is important irrespective of where they are located, which is why we have implemented GDPR controls as our baseline standard for all our operations worldwide.</p>
    <h2>What is personal data?</h2>
    <p>Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information that could be used on its own, or in combination with other pieces of information, to identify a person. Personal data extends beyond a person’s name or email address. Some examples include financial information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual orientation, and ethnicity.</p>
    <p>The Data Protection Principles include requirements such as:</p>
    <ul>
        <li>Personal data collected must be processed in a fair, legal, and transparent way and should only be used in a way that a person would reasonably expect.</li>
        <li>Personal data should only be collected to fulfil a specific purpose and it should only be used for that purpose. Organizations must specify why they need the personal data when they collect it.</li>
        <li>Personal data should be held no longer than necessary to fulfil its purpose.</li>
        <li>People covered by the GDPR have the right to access their own personal data. They can also request a copy of their data, and that their data be updated, deleted, restricted, or moved to another organization.</li>
    </ul>
    <h2>California Residents</h2>
    <p>The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>
    <p>We are also required to communicate information about rights California residents have under California law. You may exercise the following rights:</p>
    <ul>
        <li>Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.</li>
        <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</li>
        <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</li>
        <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
    </ul>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at info@gambo.app.</p>
    <p>We do not sell the Personal Information of our users.</p>
    <p>For more information about these rights, please contact us at info@gambo.app.</p>
    <h2>Contact Us</h2>
    <p>Don't hesitate to contact us if you have any questions via email: info@gambo.app.</p>
</div>
<div *ngIf="lang === 'ru'"
     class="content">
    <h1>Политика конфиденциальности</h1>
    <h2>1. Основные понятия</h2>
    <p>Сайт – сайт сервиса настольных игр в дополненной реальности Gambo, расположенный в сети Интернет по адресу: gambo.app домен сайта.</p>
    <p>Администрация Сайта – ООО "Гэмбо Лаб", 197373, город Санкт-Петербург, проспект Авиаконструкторов, дом 25 литер а, квартира 119, info@gambo.app.</p>
    <p>Пользователь – физическое или юридическое лицо, разместившее свою персональную информацию посредством Формы обратной связи на сайте с последующей целью передачи данных Администрации Сайта.</p>
    <p>Форма обратной связи – специальная форма, где Пользователь размещает свою персональную информацию с целью передачи данных Администрации Сайта.</p>
    <h2>2. Общие положения</h2>
    <p>2.1. Настоящая Политика конфиденциальности является официальным типовым документом Администрации Сайта и определяет порядок обработки и защиты информации о физических и юридических лицах, использующих Форму обратной связи на Сайте.</p>
    <p>2.2. Целью настоящей Политики конфиденциальности является обеспечение надлежащей защиты информации о Пользователе, в т.ч. его персональных данных от несанкционированного доступа и разглашения.</p>
    <p>2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях регулируются настоящей Политикой конфиденциальности и действующим законодательством Российской Федерации.</p>
    <p>2.4. Действующая редакция Политики конфиденциальности, является публичным документом, разработана Администрацией Сайта и доступна любому Пользователю сети Интернет при переходе по гипертекстовой ссылке «Политика конфиденциальности».</p>
    <p>2.5. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности.</p>
    <p>2.6. При внесении изменений в Политику конфиденциальности, Администрация Сайта уведомляет об этом Пользователя путём размещения новой редакции Политики конфиденциальности на Сайте gambo.app.</p>
    <p>2.7. Используя Форму обратной связи, Пользователь выражает свое согласие с условиями настоящей Политики конфиденциальности.</p>
    <p>2.8. Администрация Сайта не проверяет достоверность получаемой (собираемой) информации о Пользователе.</p>
    <h2>3. Условия и цели сбора и обработки персональных данных пользователей</h2>
    <p>3.1. Персональные данные Пользователя такие как: имя, фамилия, отчество, e-mail, телефон и др., передаются Пользователем Администрации Сайта с согласия Пользователя.</p>
    <p>3.2. Передача персональных данных Пользователем Администрации Сайта через Форму обратной связи означает согласие Пользователя на передачу его персональных данных.</p>
    <p>3.3. Администрация Сайта осуществляет обработку информации о Пользователе, в т.ч. его персональных данных, таких как: имя, фамилия, отчество, e-mail, телефон и др., а также дополнительной информации о Пользователе, предоставляемой им по своему желанию, в целях выполнения обязательств перед Пользователем Сайта.</p>
    <p>3.4. Обработка персональных данных осуществляется на основе принципов:</p>
    <p>а) законности целей и способов обработки персональных данных и добросовестности;</p>
    <p>б) соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных;</p>
    <p>в) соответствия объёма и характера обрабатываемых персональных данных способам обработки персональных данных и целям обработки персональных данных;</p>
    <p>г) недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих персональные данные.</p>
    <p>3.5. Администрация Сайта осуществляет обработку персональных данных Пользователя с его согласия в целях информирования о стадии готовности сервиса, предоставления доступа к сервису/тестированию сервиса, используя предоставленные данные в сервисах Google Play Store и App Store, продажи услуг, предлагаемых на Сайте или в сервисе Gambo.</p>
    <h2>4. Хранение и использование персональных данных</h2>
    Персональные данные Пользователя хранятся исключительно на электронных носителях и используются строго по назначению, оговоренному в п.3 настоящей Политики конфиденциальности.
    <h2>5. Передача персональных данных</h2>
    <p>5.1. Персональные данные Пользователя не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой конфиденциальности, указанных в Согласии с рассылкой.</p>
    <p>5.2. Предоставление персональных данных Пользователя по запросу государственных органов, органов местного самоуправления осуществляется в порядке, предусмотренном законодательством Российской Федерации.</p>
    <h2>6. Сроки хранения и уничтожение персональных данных</h2>
    <p>6.1. Персональные данные Пользователя хранятся на электронном носителе сайта бессрочно.</p>
    <p>6.2. Персональные данные Пользователя уничтожаются при желании самого пользователя на основании его обращения, либо по инициативе Администратора сайта без объяснения причин путём удаления Администрацией Сайта информации, размещённой Пользователем.</p>
    <h2>7. Права и обязанности пользователей</h2>
    <p>Пользователи вправе на основании запроса получать от Администрации Сайта информацию, касающуюся обработки его персональных данных.</p>
    <h2>8. Меры по защите информации о пользователях</h2>
    <p>Администратор Сайта принимает технические и организационно-правовые меры в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий.</p>
    <h2>9. Обращения пользователей</h2>
    <p>9.1. Пользователь вправе направлять Администрации Сайта свои запросы, в т.ч. относительно использования/удаления его персональных данных, предусмотренные п.3 настоящей Политики конфиденциальности в электронной форме на e-mail, указанный в п.1.</p>
    <p>9.2. Запрос, направляемый Пользователем, должен содержать следующую информацию:</p>
    <p>для физического лица:</p>
    <p>– e-mail, который был указан в Форме обратной связи;</p>
    <p>– дату регистрации через Форму обратной связи;</p>
    <p>– текст запроса в свободной форме.</p>
    <p>для юридического лица:</p>
    <p>– запрос в свободной форме на фирменном бланке;</p>
    <p>– e-mail, который был указан в Форме обратной связи;</p>
    <p>– дата регистрации через Форму обратной связи;</p>
    <p>– запрос должен быть подписан уполномоченным лицом с приложением документов, подтверждающих полномочия лица.</p>
    <p>9.3. Администрация Сайта обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в течение 30 дней с момента поступления обращения.</p>
    <p>9.4. Вся корреспонденция, полученная Администрацией от Пользователя (обращения в письменной/электронной форме) относится к информации ограниченного доступа и без письменного согласия Пользователя разглашению не подлежит. Персональные данные и иная информация о Пользователе, направившем запрос, не могут быть без специального согласия Пользователя использованы иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных законодательством.</p>
</div>
