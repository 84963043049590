import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AppRoutingModule } from '../../app/app-routing.module';

@NgModule({
    declarations: [PrivacyPolicyComponent],
    exports: [PrivacyPolicyComponent],
    imports: [
        CommonModule,
        AppRoutingModule
    ]
})
export class PrivacyPolicyModule {

}
