<div mat-dialog-content>
    <p>{{ 'RC.ENTER_DESCRIPTION' | translate }}</p>
    <mat-form-field appearance="fill"
                    class="dialog-input">
<!--        <mat-label>{{ 'RC.DESCRIPTION' | translate }}</mat-label>-->
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field appearance="fill"
                    class="dialog-input">
        <mat-label>Description</mat-label>
        <input matInput [(ngModel)]="data.description">
    </mat-form-field>
</div>
<div mat-dialog-actions
     class="dialog-actions">
    <button mat-button (click)="onNoClick()"><!--{{ 'RC.CANCEL' | translate }}-->Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial><!--{{ 'RC.SAVE' | translate }}-->Save</button>
</div>
