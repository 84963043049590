import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

    constructor(
        private router: Router,
        public authService: AuthService
    ) {
        authService.authSubject.subscribe(
            success => {
                console.log('RESULT');
                if (success) {
                    // TODO redirect to previous page
                    this.router.navigate(['games']);
                }
            },
            error => console.log(error)
        );
    }

    public signIn() {
        this.authService.authWithGoogle();
    }
}
