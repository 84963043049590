import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnswerType, QuestionType, QuizInfo, QuizConstructorService } from '../../quiz-constructor.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-question-generation',
    templateUrl: './question-generation.component.html',
    styleUrls: ['./question-generation.component.scss']
})
export class QuestionGenerationComponent implements OnInit {

    private quizInfo: QuizInfo;

    public premium = true;

    public questionTypes = QuestionType;
    public selectedQuestionType = QuestionType.TEXT;

    public answerTypes = AnswerType;
    public selectedAnswerType = AnswerType.ONE;

    constructor(
        private snackBar: MatSnackBar,
        private quizService: QuizConstructorService,
        private router: Router
    ) { }

    ngOnInit() {
        this.quizInfo = JSON.parse(sessionStorage.getItem('SelectedQuizInfo'));
        console.log(this.quizInfo);
    }

    changeSelectedType(questionType: QuestionType) {
        if (questionType !== QuestionType.TEXT) {
            this.snackBar.open(
                'Buy subscription',
                '',
                {
                    duration: 3000
                }
            );
        } else {
            this.selectedQuestionType = questionType;
        }
    }

    changeSelectedAnswerType(answerType: AnswerType) {
        if (answerType === AnswerType.CUSTOM) {
            this.snackBar.open(
                'Buy subscription',
                '',
                {
                    duration: 3000
                }
            );
        } else {
            this.selectedAnswerType = answerType;
        }
    }

    saveQuestion() {
        this.quizService.createQuestion(this.quizInfo.id, {
            number: this.quizInfo.questions.length,
            type: this.selectedQuestionType,
            value: 'Question',
            answerType: this.selectedAnswerType,
            correctAnswer: 'Answer'
        }).subscribe(result => {
            console.log(result);
            this.router.navigate(['/quiz-editor']);
        });
    }

}
