import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizComponent } from './quiz/quiz.component';
import { JoinComponent } from './join/join.component';
import { AnswerConstructorComponent } from './question-generation/answer-constructor/answer-constructor.component';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';
import { QuestionConstructorComponent } from './question-generation/question-constructor/question-constructor.component';
import { QuestionGenerationComponent } from './question-generation/question-generation/question-generation.component';
import { QuizListComponent } from './quiz-list/quiz-list.component';
import { QuizConstructorService } from './quiz-constructor.service';
import { CreateQuizDialogComponent } from './quiz-list/create-quiz-dialog/create-quiz-dialog.component';
import { QuizEditorComponent } from './quiz-editor/quiz-editor.component';
import { QuizRunnerComponent } from '../quiz-runner/quiz-runner/quiz-runner.component';

@NgModule({
    declarations: [
        QuizComponent,
        JoinComponent,
        QuestionConstructorComponent,
        AnswerConstructorComponent,
        QuestionGenerationComponent,
        QuizListComponent,
        CreateQuizDialogComponent,
        QuizEditorComponent,
        QuizRunnerComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild()
    ],
    providers: [
        QuizConstructorService
    ]
})
export class QuizConstructorModule {}
