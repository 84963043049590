// source: gambo.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.app.gambo.BackgammonCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonMoveType', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonPointColor', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonPointColorAndCount', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.BackgammonType', null, global);
goog.exportSymbol('proto.app.gambo.CheckersCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.CheckersCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.CheckersFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.CheckersFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.CheckersListOfCheckersPoint', null, global);
goog.exportSymbol('proto.app.gambo.CheckersMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.CheckersMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.CheckersPoint', null, global);
goog.exportSymbol('proto.app.gambo.CheckersPointColor', null, global);
goog.exportSymbol('proto.app.gambo.CheckersPointState', null, global);
goog.exportSymbol('proto.app.gambo.CheckersRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.CheckersRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.CheckersStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.CheckersTypedPoint', null, global);
goog.exportSymbol('proto.app.gambo.ChessCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.ChessCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.ChessFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.ChessFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.ChessListOfChessPoint', null, global);
goog.exportSymbol('proto.app.gambo.ChessMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.ChessMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.ChessMoveType', null, global);
goog.exportSymbol('proto.app.gambo.ChessPoint', null, global);
goog.exportSymbol('proto.app.gambo.ChessPointColor', null, global);
goog.exportSymbol('proto.app.gambo.ChessPointState', null, global);
goog.exportSymbol('proto.app.gambo.ChessRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.ChessRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.ChessStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.ChessTypedPoint', null, global);
goog.exportSymbol('proto.app.gambo.DominoesCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DominoesCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DominoesFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DominoesFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DominoesMoveAction', null, global);
goog.exportSymbol('proto.app.gambo.DominoesMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DominoesMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DominoesMoveSide', null, global);
goog.exportSymbol('proto.app.gambo.DominoesRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DominoesRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DominoesStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DominoesTableTile', null, global);
goog.exportSymbol('proto.app.gambo.DominoesTile', null, global);
goog.exportSymbol('proto.app.gambo.DominoesTileDirection', null, global);
goog.exportSymbol('proto.app.gambo.DurakAllowedAction', null, global);
goog.exportSymbol('proto.app.gambo.DurakCard', null, global);
goog.exportSymbol('proto.app.gambo.DurakCardSuit', null, global);
goog.exportSymbol('proto.app.gambo.DurakCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DurakCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DurakFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DurakFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DurakMoveAction', null, global);
goog.exportSymbol('proto.app.gambo.DurakMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DurakMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DurakRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.DurakRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.DurakStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.ErrorClientMessage', null, global);
goog.exportSymbol('proto.app.gambo.ErrorServerMessage', null, global);
goog.exportSymbol('proto.app.gambo.GameUserInfo', null, global);
goog.exportSymbol('proto.app.gambo.GeneralMessage', null, global);
goog.exportSymbol('proto.app.gambo.GeneralMessage.MsgCase', null, global);
goog.exportSymbol('proto.app.gambo.GoCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.GoCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.GoFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.GoFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.GoMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.GoMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.GoMoveType', null, global);
goog.exportSymbol('proto.app.gambo.GoPoint', null, global);
goog.exportSymbol('proto.app.gambo.GoPointColor', null, global);
goog.exportSymbol('proto.app.gambo.GoRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.GoRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.GoStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.GoTypedPoint', null, global);
goog.exportSymbol('proto.app.gambo.ListOfInt32', null, global);
goog.exportSymbol('proto.app.gambo.MessageType', null, global);
goog.exportSymbol('proto.app.gambo.PairOfDurakCards', null, global);
goog.exportSymbol('proto.app.gambo.PingMessage', null, global);
goog.exportSymbol('proto.app.gambo.PongMessage', null, global);
goog.exportSymbol('proto.app.gambo.QuizAction', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonAction', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizButtonStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizMoveResponseType', null, global);
goog.exportSymbol('proto.app.gambo.QuizQuestion', null, global);
goog.exportSymbol('proto.app.gambo.QuizQuestionAnswerType', null, global);
goog.exportSymbol('proto.app.gambo.QuizQuestionContentType', null, global);
goog.exportSymbol('proto.app.gambo.QuizRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.QuizRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.QuizScoreData', null, global);
goog.exportSymbol('proto.app.gambo.QuizStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleCurrentStateMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleCurrentStateMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleFinishMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleFinishMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleMoveMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleMoveMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattlePlaceShipsMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattlePlaceShipsMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattlePlayersReadyResponse', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattlePoint', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattlePointType', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleRestartMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleRestartMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleShip', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.SeaBattleTypedPoint', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GeneralMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.app.gambo.GeneralMessage.oneofGroups_);
};
goog.inherits(proto.app.gambo.GeneralMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GeneralMessage.displayName = 'proto.app.gambo.GeneralMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.PingMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.PingMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.PingMessage.displayName = 'proto.app.gambo.PingMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.PongMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.PongMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.PongMessage.displayName = 'proto.app.gambo.PongMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ErrorServerMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ErrorServerMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ErrorServerMessage.displayName = 'proto.app.gambo.ErrorServerMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ErrorClientMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ErrorClientMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ErrorClientMessage.displayName = 'proto.app.gambo.ErrorClientMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleStartGameMessageRequest.displayName = 'proto.app.gambo.SeaBattleStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesStartGameMessageRequest.displayName = 'proto.app.gambo.DominoesStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesTile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesTile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesTile.displayName = 'proto.app.gambo.DominoesTile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleMoveMessageRequest.displayName = 'proto.app.gambo.SeaBattleMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.SeaBattleMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.SeaBattleMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleMoveMessageResponse.displayName = 'proto.app.gambo.SeaBattleMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.SeaBattlePlaceShipsMessageRequest.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.SeaBattlePlaceShipsMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattlePlaceShipsMessageRequest.displayName = 'proto.app.gambo.SeaBattlePlaceShipsMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattlePlaceShipsMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattlePlaceShipsMessageResponse.displayName = 'proto.app.gambo.SeaBattlePlaceShipsMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattlePlayersReadyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattlePlayersReadyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattlePlayersReadyResponse.displayName = 'proto.app.gambo.SeaBattlePlayersReadyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleCurrentStateMessageRequest.displayName = 'proto.app.gambo.SeaBattleCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.SeaBattleCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.SeaBattleCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleCurrentStateMessageResponse.displayName = 'proto.app.gambo.SeaBattleCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleTypedPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleTypedPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleTypedPoint.displayName = 'proto.app.gambo.SeaBattleTypedPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleShip = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleShip, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleShip.displayName = 'proto.app.gambo.SeaBattleShip';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattlePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattlePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattlePoint.displayName = 'proto.app.gambo.SeaBattlePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesMoveMessageRequest.displayName = 'proto.app.gambo.DominoesMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesMoveMessageResponse.displayName = 'proto.app.gambo.DominoesMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesCurrentStateMessageRequest.displayName = 'proto.app.gambo.DominoesCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.DominoesCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.DominoesCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesCurrentStateMessageResponse.displayName = 'proto.app.gambo.DominoesCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesTableTile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesTableTile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesTableTile.displayName = 'proto.app.gambo.DominoesTableTile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesRestartMessageRequest.displayName = 'proto.app.gambo.DominoesRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesRestartMessageResponse.displayName = 'proto.app.gambo.DominoesRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesFinishMessageRequest.displayName = 'proto.app.gambo.DominoesFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DominoesFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DominoesFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DominoesFinishMessageResponse.displayName = 'proto.app.gambo.DominoesFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleRestartMessageRequest.displayName = 'proto.app.gambo.SeaBattleRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleRestartMessageResponse.displayName = 'proto.app.gambo.SeaBattleRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleFinishMessageRequest.displayName = 'proto.app.gambo.SeaBattleFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.SeaBattleFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.SeaBattleFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.SeaBattleFinishMessageResponse.displayName = 'proto.app.gambo.SeaBattleFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GameUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GameUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GameUserInfo.displayName = 'proto.app.gambo.GameUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizButtonStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonStartGameMessageRequest.displayName = 'proto.app.gambo.QuizButtonStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizButtonMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonMoveMessageRequest.displayName = 'proto.app.gambo.QuizButtonMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.QuizButtonMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.QuizButtonMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonMoveMessageResponse.displayName = 'proto.app.gambo.QuizButtonMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizButtonCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonCurrentStateMessageRequest.displayName = 'proto.app.gambo.QuizButtonCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.QuizButtonCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.QuizButtonCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonCurrentStateMessageResponse.displayName = 'proto.app.gambo.QuizButtonCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizButtonRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonRestartMessageRequest.displayName = 'proto.app.gambo.QuizButtonRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizButtonRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonRestartMessageResponse.displayName = 'proto.app.gambo.QuizButtonRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizButtonFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonFinishMessageRequest.displayName = 'proto.app.gambo.QuizButtonFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizButtonFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizButtonFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizButtonFinishMessageResponse.displayName = 'proto.app.gambo.QuizButtonFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersStartGameMessageRequest.displayName = 'proto.app.gambo.CheckersStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersPoint.displayName = 'proto.app.gambo.CheckersPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersTypedPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersTypedPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersTypedPoint.displayName = 'proto.app.gambo.CheckersTypedPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersMoveMessageRequest.displayName = 'proto.app.gambo.CheckersMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.CheckersMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.CheckersMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersMoveMessageResponse.displayName = 'proto.app.gambo.CheckersMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersCurrentStateMessageRequest.displayName = 'proto.app.gambo.CheckersCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.CheckersCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.CheckersCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersCurrentStateMessageResponse.displayName = 'proto.app.gambo.CheckersCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersRestartMessageRequest.displayName = 'proto.app.gambo.CheckersRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersRestartMessageResponse.displayName = 'proto.app.gambo.CheckersRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersFinishMessageRequest.displayName = 'proto.app.gambo.CheckersFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CheckersFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersFinishMessageResponse.displayName = 'proto.app.gambo.CheckersFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CheckersListOfCheckersPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.CheckersListOfCheckersPoint.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.CheckersListOfCheckersPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CheckersListOfCheckersPoint.displayName = 'proto.app.gambo.CheckersListOfCheckersPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakStartGameMessageRequest.displayName = 'proto.app.gambo.DurakStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakMoveMessageRequest.displayName = 'proto.app.gambo.DurakMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.DurakMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.DurakMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakMoveMessageResponse.displayName = 'proto.app.gambo.DurakMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakCurrentStateMessageRequest.displayName = 'proto.app.gambo.DurakCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.DurakCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.DurakCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakCurrentStateMessageResponse.displayName = 'proto.app.gambo.DurakCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakRestartMessageRequest.displayName = 'proto.app.gambo.DurakRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakRestartMessageResponse.displayName = 'proto.app.gambo.DurakRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakFinishMessageRequest.displayName = 'proto.app.gambo.DurakFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakFinishMessageResponse.displayName = 'proto.app.gambo.DurakFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.PairOfDurakCards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.PairOfDurakCards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.PairOfDurakCards.displayName = 'proto.app.gambo.PairOfDurakCards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.DurakCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.DurakCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.DurakCard.displayName = 'proto.app.gambo.DurakCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessStartGameMessageRequest.displayName = 'proto.app.gambo.ChessStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessPoint.displayName = 'proto.app.gambo.ChessPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessTypedPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessTypedPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessTypedPoint.displayName = 'proto.app.gambo.ChessTypedPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessMoveMessageRequest.displayName = 'proto.app.gambo.ChessMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.ChessMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.ChessMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessMoveMessageResponse.displayName = 'proto.app.gambo.ChessMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessCurrentStateMessageRequest.displayName = 'proto.app.gambo.ChessCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.ChessCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.ChessCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessCurrentStateMessageResponse.displayName = 'proto.app.gambo.ChessCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessRestartMessageRequest.displayName = 'proto.app.gambo.ChessRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessRestartMessageResponse.displayName = 'proto.app.gambo.ChessRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessFinishMessageRequest.displayName = 'proto.app.gambo.ChessFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.ChessFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessFinishMessageResponse.displayName = 'proto.app.gambo.ChessFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ChessListOfChessPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.ChessListOfChessPoint.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.ChessListOfChessPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ChessListOfChessPoint.displayName = 'proto.app.gambo.ChessListOfChessPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoStartGameMessageRequest.displayName = 'proto.app.gambo.GoStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoPoint.displayName = 'proto.app.gambo.GoPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoTypedPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoTypedPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoTypedPoint.displayName = 'proto.app.gambo.GoTypedPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoMoveMessageRequest.displayName = 'proto.app.gambo.GoMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.GoMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.GoMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoMoveMessageResponse.displayName = 'proto.app.gambo.GoMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoCurrentStateMessageRequest.displayName = 'proto.app.gambo.GoCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.GoCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.GoCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoCurrentStateMessageResponse.displayName = 'proto.app.gambo.GoCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoRestartMessageRequest.displayName = 'proto.app.gambo.GoRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoRestartMessageResponse.displayName = 'proto.app.gambo.GoRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoFinishMessageRequest.displayName = 'proto.app.gambo.GoFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.GoFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.GoFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.GoFinishMessageResponse.displayName = 'proto.app.gambo.GoFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonPointColorAndCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.BackgammonPointColorAndCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonPointColorAndCount.displayName = 'proto.app.gambo.BackgammonPointColorAndCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.ListOfInt32 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.ListOfInt32.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.ListOfInt32, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.ListOfInt32.displayName = 'proto.app.gambo.ListOfInt32';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.BackgammonStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonStartGameMessageRequest.displayName = 'proto.app.gambo.BackgammonStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.BackgammonMoveMessageRequest.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.BackgammonMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonMoveMessageRequest.displayName = 'proto.app.gambo.BackgammonMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.BackgammonMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.BackgammonMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonMoveMessageResponse.displayName = 'proto.app.gambo.BackgammonMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.BackgammonCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonCurrentStateMessageRequest.displayName = 'proto.app.gambo.BackgammonCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.BackgammonCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.BackgammonCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonCurrentStateMessageResponse.displayName = 'proto.app.gambo.BackgammonCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.BackgammonRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonRestartMessageRequest.displayName = 'proto.app.gambo.BackgammonRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.BackgammonRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonRestartMessageResponse.displayName = 'proto.app.gambo.BackgammonRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.BackgammonFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonFinishMessageRequest.displayName = 'proto.app.gambo.BackgammonFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.BackgammonFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.BackgammonFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.BackgammonFinishMessageResponse.displayName = 'proto.app.gambo.BackgammonFinishMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizQuestion.displayName = 'proto.app.gambo.QuizQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizScoreData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.QuizScoreData.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.QuizScoreData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizScoreData.displayName = 'proto.app.gambo.QuizScoreData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizStartGameMessageRequest.displayName = 'proto.app.gambo.QuizStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizMoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizMoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizMoveMessageRequest.displayName = 'proto.app.gambo.QuizMoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizMoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.QuizMoveMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.QuizMoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizMoveMessageResponse.displayName = 'proto.app.gambo.QuizMoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizCurrentStateMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizCurrentStateMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizCurrentStateMessageRequest.displayName = 'proto.app.gambo.QuizCurrentStateMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizCurrentStateMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.QuizCurrentStateMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.QuizCurrentStateMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizCurrentStateMessageResponse.displayName = 'proto.app.gambo.QuizCurrentStateMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizRestartMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizRestartMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizRestartMessageRequest.displayName = 'proto.app.gambo.QuizRestartMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizRestartMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizRestartMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizRestartMessageResponse.displayName = 'proto.app.gambo.QuizRestartMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizFinishMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizFinishMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizFinishMessageRequest.displayName = 'proto.app.gambo.QuizFinishMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.QuizFinishMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.QuizFinishMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.QuizFinishMessageResponse.displayName = 'proto.app.gambo.QuizFinishMessageResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.app.gambo.GeneralMessage.oneofGroups_ = [[4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82]];

/**
 * @enum {number}
 */
proto.app.gambo.GeneralMessage.MsgCase = {
  MSG_NOT_SET: 0,
  PINGMESSAGE: 4,
  PONGMESSAGE: 5,
  ERRORSERVERMESSAGE: 6,
  ERRORCLIENTMESSAGE: 7,
  DOMINOESSTARTGAMEMESSAGEREQUEST: 8,
  DOMINOESMOVEMESSAGEREQUEST: 9,
  DOMINOESMOVEMESSAGERESPONSE: 10,
  DOMINOESCURRENTSTATEMESSAGEREQUEST: 11,
  DOMINOESCURRENTSTATEMESSAGERESPONSE: 12,
  DOMINOESRESTARTMESSAGEREQUEST: 13,
  DOMINOESRESTARTMESSAGERESPONSE: 14,
  DOMINOESFINISHMESSAGEREQUEST: 15,
  DOMINOESFINISHMESSAGERESPONSE: 16,
  SEABATTLESTARTGAMEMESSAGEREQUEST: 17,
  SEABATTLEMOVEMESSAGEREQUEST: 18,
  SEABATTLEMOVEMESSAGERESPONSE: 19,
  SEABATTLEPLACESHIPSMESSAGEREQUEST: 20,
  SEABATTLEPLACESHIPSMESSAGERESPONSE: 21,
  SEABATTLEPLAYERSREADYRESPONSE: 22,
  SEABATTLECURRENTSTATEMESSAGEREQUEST: 23,
  SEABATTLECURRENTSTATEMESSAGERESPONSE: 24,
  SEABATTLERESTARTMESSAGEREQUEST: 25,
  SEABATTLERESTARTMESSAGERESPONSE: 26,
  SEABATTLEFINISHMESSAGEREQUEST: 27,
  SEABATTLEFINISHMESSAGERESPONSE: 28,
  QUIZBUTTONSTARTGAMEMESSAGEREQUEST: 29,
  QUIZBUTTONMOVEMESSAGEREQUEST: 30,
  QUIZBUTTONMOVEMESSAGERESPONSE: 31,
  QUIZBUTTONCURRENTSTATEMESSAGEREQUEST: 32,
  QUIZBUTTONCURRENTSTATEMESSAGERESPONSE: 33,
  QUIZBUTTONRESTARTMESSAGEREQUEST: 34,
  QUIZBUTTONRESTARTMESSAGERESPONSE: 35,
  QUIZBUTTONFINISHMESSAGEREQUEST: 36,
  QUIZBUTTONFINISHMESSAGERESPONSE: 37,
  CHECKERSSTARTGAMEMESSAGEREQUEST: 38,
  CHECKERSMOVEMESSAGEREQUEST: 39,
  CHECKERSMOVEMESSAGERESPONSE: 40,
  CHECKERSCURRENTSTATEMESSAGEREQUEST: 41,
  CHECKERSCURRENTSTATEMESSAGERESPONSE: 42,
  CHECKERSRESTARTMESSAGEREQUEST: 43,
  CHECKERSRESTARTMESSAGERESPONSE: 44,
  CHECKERSFINISHMESSAGEREQUEST: 45,
  CHECKERSFINISHMESSAGERESPONSE: 46,
  DURAKSTARTGAMEMESSAGEREQUEST: 47,
  DURAKMOVEMESSAGEREQUEST: 48,
  DURAKMOVEMESSAGERESPONSE: 49,
  DURAKCURRENTSTATEMESSAGEREQUEST: 50,
  DURAKCURRENTSTATEMESSAGERESPONSE: 51,
  DURAKRESTARTMESSAGEREQUEST: 52,
  DURAKRESTARTMESSAGERESPONSE: 53,
  DURAKFINISHMESSAGEREQUEST: 54,
  DURAKFINISHMESSAGERESPONSE: 55,
  CHESSSTARTGAMEMESSAGEREQUEST: 56,
  CHESSMOVEMESSAGEREQUEST: 57,
  CHESSMOVEMESSAGERESPONSE: 58,
  CHESSCURRENTSTATEMESSAGEREQUEST: 59,
  CHESSCURRENTSTATEMESSAGERESPONSE: 60,
  CHESSRESTARTMESSAGEREQUEST: 61,
  CHESSRESTARTMESSAGERESPONSE: 62,
  CHESSFINISHMESSAGEREQUEST: 63,
  CHESSFINISHMESSAGERESPONSE: 64,
  GOSTARTGAMEMESSAGEREQUEST: 65,
  GOMOVEMESSAGEREQUEST: 66,
  GOMOVEMESSAGERESPONSE: 67,
  GOCURRENTSTATEMESSAGEREQUEST: 68,
  GOCURRENTSTATEMESSAGERESPONSE: 69,
  GORESTARTMESSAGEREQUEST: 70,
  GORESTARTMESSAGERESPONSE: 71,
  GOFINISHMESSAGEREQUEST: 72,
  GOFINISHMESSAGERESPONSE: 73,
  BACKGAMMONSTARTGAMEMESSAGEREQUEST: 74,
  BACKGAMMONMOVEMESSAGEREQUEST: 75,
  BACKGAMMONMOVEMESSAGERESPONSE: 76,
  BACKGAMMONCURRENTSTATEMESSAGEREQUEST: 77,
  BACKGAMMONCURRENTSTATEMESSAGERESPONSE: 78,
  BACKGAMMONRESTARTMESSAGEREQUEST: 79,
  BACKGAMMONRESTARTMESSAGERESPONSE: 80,
  BACKGAMMONFINISHMESSAGEREQUEST: 81,
  BACKGAMMONFINISHMESSAGERESPONSE: 82
};

/**
 * @return {proto.app.gambo.GeneralMessage.MsgCase}
 */
proto.app.gambo.GeneralMessage.prototype.getMsgCase = function() {
  return /** @type {proto.app.gambo.GeneralMessage.MsgCase} */(jspb.Message.computeOneofCase(this, proto.app.gambo.GeneralMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GeneralMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GeneralMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GeneralMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GeneralMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cookie: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pingmessage: (f = msg.getPingmessage()) && proto.app.gambo.PingMessage.toObject(includeInstance, f),
    pongmessage: (f = msg.getPongmessage()) && proto.app.gambo.PongMessage.toObject(includeInstance, f),
    errorservermessage: (f = msg.getErrorservermessage()) && proto.app.gambo.ErrorServerMessage.toObject(includeInstance, f),
    errorclientmessage: (f = msg.getErrorclientmessage()) && proto.app.gambo.ErrorClientMessage.toObject(includeInstance, f),
    dominoesstartgamemessagerequest: (f = msg.getDominoesstartgamemessagerequest()) && proto.app.gambo.DominoesStartGameMessageRequest.toObject(includeInstance, f),
    dominoesmovemessagerequest: (f = msg.getDominoesmovemessagerequest()) && proto.app.gambo.DominoesMoveMessageRequest.toObject(includeInstance, f),
    dominoesmovemessageresponse: (f = msg.getDominoesmovemessageresponse()) && proto.app.gambo.DominoesMoveMessageResponse.toObject(includeInstance, f),
    dominoescurrentstatemessagerequest: (f = msg.getDominoescurrentstatemessagerequest()) && proto.app.gambo.DominoesCurrentStateMessageRequest.toObject(includeInstance, f),
    dominoescurrentstatemessageresponse: (f = msg.getDominoescurrentstatemessageresponse()) && proto.app.gambo.DominoesCurrentStateMessageResponse.toObject(includeInstance, f),
    dominoesrestartmessagerequest: (f = msg.getDominoesrestartmessagerequest()) && proto.app.gambo.DominoesRestartMessageRequest.toObject(includeInstance, f),
    dominoesrestartmessageresponse: (f = msg.getDominoesrestartmessageresponse()) && proto.app.gambo.DominoesRestartMessageResponse.toObject(includeInstance, f),
    dominoesfinishmessagerequest: (f = msg.getDominoesfinishmessagerequest()) && proto.app.gambo.DominoesFinishMessageRequest.toObject(includeInstance, f),
    dominoesfinishmessageresponse: (f = msg.getDominoesfinishmessageresponse()) && proto.app.gambo.DominoesFinishMessageResponse.toObject(includeInstance, f),
    seabattlestartgamemessagerequest: (f = msg.getSeabattlestartgamemessagerequest()) && proto.app.gambo.SeaBattleStartGameMessageRequest.toObject(includeInstance, f),
    seabattlemovemessagerequest: (f = msg.getSeabattlemovemessagerequest()) && proto.app.gambo.SeaBattleMoveMessageRequest.toObject(includeInstance, f),
    seabattlemovemessageresponse: (f = msg.getSeabattlemovemessageresponse()) && proto.app.gambo.SeaBattleMoveMessageResponse.toObject(includeInstance, f),
    seabattleplaceshipsmessagerequest: (f = msg.getSeabattleplaceshipsmessagerequest()) && proto.app.gambo.SeaBattlePlaceShipsMessageRequest.toObject(includeInstance, f),
    seabattleplaceshipsmessageresponse: (f = msg.getSeabattleplaceshipsmessageresponse()) && proto.app.gambo.SeaBattlePlaceShipsMessageResponse.toObject(includeInstance, f),
    seabattleplayersreadyresponse: (f = msg.getSeabattleplayersreadyresponse()) && proto.app.gambo.SeaBattlePlayersReadyResponse.toObject(includeInstance, f),
    seabattlecurrentstatemessagerequest: (f = msg.getSeabattlecurrentstatemessagerequest()) && proto.app.gambo.SeaBattleCurrentStateMessageRequest.toObject(includeInstance, f),
    seabattlecurrentstatemessageresponse: (f = msg.getSeabattlecurrentstatemessageresponse()) && proto.app.gambo.SeaBattleCurrentStateMessageResponse.toObject(includeInstance, f),
    seabattlerestartmessagerequest: (f = msg.getSeabattlerestartmessagerequest()) && proto.app.gambo.SeaBattleRestartMessageRequest.toObject(includeInstance, f),
    seabattlerestartmessageresponse: (f = msg.getSeabattlerestartmessageresponse()) && proto.app.gambo.SeaBattleRestartMessageResponse.toObject(includeInstance, f),
    seabattlefinishmessagerequest: (f = msg.getSeabattlefinishmessagerequest()) && proto.app.gambo.SeaBattleFinishMessageRequest.toObject(includeInstance, f),
    seabattlefinishmessageresponse: (f = msg.getSeabattlefinishmessageresponse()) && proto.app.gambo.SeaBattleFinishMessageResponse.toObject(includeInstance, f),
    quizbuttonstartgamemessagerequest: (f = msg.getQuizbuttonstartgamemessagerequest()) && proto.app.gambo.QuizButtonStartGameMessageRequest.toObject(includeInstance, f),
    quizbuttonmovemessagerequest: (f = msg.getQuizbuttonmovemessagerequest()) && proto.app.gambo.QuizButtonMoveMessageRequest.toObject(includeInstance, f),
    quizbuttonmovemessageresponse: (f = msg.getQuizbuttonmovemessageresponse()) && proto.app.gambo.QuizButtonMoveMessageResponse.toObject(includeInstance, f),
    quizbuttoncurrentstatemessagerequest: (f = msg.getQuizbuttoncurrentstatemessagerequest()) && proto.app.gambo.QuizButtonCurrentStateMessageRequest.toObject(includeInstance, f),
    quizbuttoncurrentstatemessageresponse: (f = msg.getQuizbuttoncurrentstatemessageresponse()) && proto.app.gambo.QuizButtonCurrentStateMessageResponse.toObject(includeInstance, f),
    quizbuttonrestartmessagerequest: (f = msg.getQuizbuttonrestartmessagerequest()) && proto.app.gambo.QuizButtonRestartMessageRequest.toObject(includeInstance, f),
    quizbuttonrestartmessageresponse: (f = msg.getQuizbuttonrestartmessageresponse()) && proto.app.gambo.QuizButtonRestartMessageResponse.toObject(includeInstance, f),
    quizbuttonfinishmessagerequest: (f = msg.getQuizbuttonfinishmessagerequest()) && proto.app.gambo.QuizButtonFinishMessageRequest.toObject(includeInstance, f),
    quizbuttonfinishmessageresponse: (f = msg.getQuizbuttonfinishmessageresponse()) && proto.app.gambo.QuizButtonFinishMessageResponse.toObject(includeInstance, f),
    checkersstartgamemessagerequest: (f = msg.getCheckersstartgamemessagerequest()) && proto.app.gambo.CheckersStartGameMessageRequest.toObject(includeInstance, f),
    checkersmovemessagerequest: (f = msg.getCheckersmovemessagerequest()) && proto.app.gambo.CheckersMoveMessageRequest.toObject(includeInstance, f),
    checkersmovemessageresponse: (f = msg.getCheckersmovemessageresponse()) && proto.app.gambo.CheckersMoveMessageResponse.toObject(includeInstance, f),
    checkerscurrentstatemessagerequest: (f = msg.getCheckerscurrentstatemessagerequest()) && proto.app.gambo.CheckersCurrentStateMessageRequest.toObject(includeInstance, f),
    checkerscurrentstatemessageresponse: (f = msg.getCheckerscurrentstatemessageresponse()) && proto.app.gambo.CheckersCurrentStateMessageResponse.toObject(includeInstance, f),
    checkersrestartmessagerequest: (f = msg.getCheckersrestartmessagerequest()) && proto.app.gambo.CheckersRestartMessageRequest.toObject(includeInstance, f),
    checkersrestartmessageresponse: (f = msg.getCheckersrestartmessageresponse()) && proto.app.gambo.CheckersRestartMessageResponse.toObject(includeInstance, f),
    checkersfinishmessagerequest: (f = msg.getCheckersfinishmessagerequest()) && proto.app.gambo.CheckersFinishMessageRequest.toObject(includeInstance, f),
    checkersfinishmessageresponse: (f = msg.getCheckersfinishmessageresponse()) && proto.app.gambo.CheckersFinishMessageResponse.toObject(includeInstance, f),
    durakstartgamemessagerequest: (f = msg.getDurakstartgamemessagerequest()) && proto.app.gambo.DurakStartGameMessageRequest.toObject(includeInstance, f),
    durakmovemessagerequest: (f = msg.getDurakmovemessagerequest()) && proto.app.gambo.DurakMoveMessageRequest.toObject(includeInstance, f),
    durakmovemessageresponse: (f = msg.getDurakmovemessageresponse()) && proto.app.gambo.DurakMoveMessageResponse.toObject(includeInstance, f),
    durakcurrentstatemessagerequest: (f = msg.getDurakcurrentstatemessagerequest()) && proto.app.gambo.DurakCurrentStateMessageRequest.toObject(includeInstance, f),
    durakcurrentstatemessageresponse: (f = msg.getDurakcurrentstatemessageresponse()) && proto.app.gambo.DurakCurrentStateMessageResponse.toObject(includeInstance, f),
    durakrestartmessagerequest: (f = msg.getDurakrestartmessagerequest()) && proto.app.gambo.DurakRestartMessageRequest.toObject(includeInstance, f),
    durakrestartmessageresponse: (f = msg.getDurakrestartmessageresponse()) && proto.app.gambo.DurakRestartMessageResponse.toObject(includeInstance, f),
    durakfinishmessagerequest: (f = msg.getDurakfinishmessagerequest()) && proto.app.gambo.DurakFinishMessageRequest.toObject(includeInstance, f),
    durakfinishmessageresponse: (f = msg.getDurakfinishmessageresponse()) && proto.app.gambo.DurakFinishMessageResponse.toObject(includeInstance, f),
    chessstartgamemessagerequest: (f = msg.getChessstartgamemessagerequest()) && proto.app.gambo.ChessStartGameMessageRequest.toObject(includeInstance, f),
    chessmovemessagerequest: (f = msg.getChessmovemessagerequest()) && proto.app.gambo.ChessMoveMessageRequest.toObject(includeInstance, f),
    chessmovemessageresponse: (f = msg.getChessmovemessageresponse()) && proto.app.gambo.ChessMoveMessageResponse.toObject(includeInstance, f),
    chesscurrentstatemessagerequest: (f = msg.getChesscurrentstatemessagerequest()) && proto.app.gambo.ChessCurrentStateMessageRequest.toObject(includeInstance, f),
    chesscurrentstatemessageresponse: (f = msg.getChesscurrentstatemessageresponse()) && proto.app.gambo.ChessCurrentStateMessageResponse.toObject(includeInstance, f),
    chessrestartmessagerequest: (f = msg.getChessrestartmessagerequest()) && proto.app.gambo.ChessRestartMessageRequest.toObject(includeInstance, f),
    chessrestartmessageresponse: (f = msg.getChessrestartmessageresponse()) && proto.app.gambo.ChessRestartMessageResponse.toObject(includeInstance, f),
    chessfinishmessagerequest: (f = msg.getChessfinishmessagerequest()) && proto.app.gambo.ChessFinishMessageRequest.toObject(includeInstance, f),
    chessfinishmessageresponse: (f = msg.getChessfinishmessageresponse()) && proto.app.gambo.ChessFinishMessageResponse.toObject(includeInstance, f),
    gostartgamemessagerequest: (f = msg.getGostartgamemessagerequest()) && proto.app.gambo.GoStartGameMessageRequest.toObject(includeInstance, f),
    gomovemessagerequest: (f = msg.getGomovemessagerequest()) && proto.app.gambo.GoMoveMessageRequest.toObject(includeInstance, f),
    gomovemessageresponse: (f = msg.getGomovemessageresponse()) && proto.app.gambo.GoMoveMessageResponse.toObject(includeInstance, f),
    gocurrentstatemessagerequest: (f = msg.getGocurrentstatemessagerequest()) && proto.app.gambo.GoCurrentStateMessageRequest.toObject(includeInstance, f),
    gocurrentstatemessageresponse: (f = msg.getGocurrentstatemessageresponse()) && proto.app.gambo.GoCurrentStateMessageResponse.toObject(includeInstance, f),
    gorestartmessagerequest: (f = msg.getGorestartmessagerequest()) && proto.app.gambo.GoRestartMessageRequest.toObject(includeInstance, f),
    gorestartmessageresponse: (f = msg.getGorestartmessageresponse()) && proto.app.gambo.GoRestartMessageResponse.toObject(includeInstance, f),
    gofinishmessagerequest: (f = msg.getGofinishmessagerequest()) && proto.app.gambo.GoFinishMessageRequest.toObject(includeInstance, f),
    gofinishmessageresponse: (f = msg.getGofinishmessageresponse()) && proto.app.gambo.GoFinishMessageResponse.toObject(includeInstance, f),
    backgammonstartgamemessagerequest: (f = msg.getBackgammonstartgamemessagerequest()) && proto.app.gambo.BackgammonStartGameMessageRequest.toObject(includeInstance, f),
    backgammonmovemessagerequest: (f = msg.getBackgammonmovemessagerequest()) && proto.app.gambo.BackgammonMoveMessageRequest.toObject(includeInstance, f),
    backgammonmovemessageresponse: (f = msg.getBackgammonmovemessageresponse()) && proto.app.gambo.BackgammonMoveMessageResponse.toObject(includeInstance, f),
    backgammoncurrentstatemessagerequest: (f = msg.getBackgammoncurrentstatemessagerequest()) && proto.app.gambo.BackgammonCurrentStateMessageRequest.toObject(includeInstance, f),
    backgammoncurrentstatemessageresponse: (f = msg.getBackgammoncurrentstatemessageresponse()) && proto.app.gambo.BackgammonCurrentStateMessageResponse.toObject(includeInstance, f),
    backgammonrestartmessagerequest: (f = msg.getBackgammonrestartmessagerequest()) && proto.app.gambo.BackgammonRestartMessageRequest.toObject(includeInstance, f),
    backgammonrestartmessageresponse: (f = msg.getBackgammonrestartmessageresponse()) && proto.app.gambo.BackgammonRestartMessageResponse.toObject(includeInstance, f),
    backgammonfinishmessagerequest: (f = msg.getBackgammonfinishmessagerequest()) && proto.app.gambo.BackgammonFinishMessageRequest.toObject(includeInstance, f),
    backgammonfinishmessageresponse: (f = msg.getBackgammonfinishmessageresponse()) && proto.app.gambo.BackgammonFinishMessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GeneralMessage}
 */
proto.app.gambo.GeneralMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GeneralMessage;
  return proto.app.gambo.GeneralMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GeneralMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GeneralMessage}
 */
proto.app.gambo.GeneralMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.gambo.MessageType} */ (reader.readEnum());
      msg.setMessagetype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCookie(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppversion(value);
      break;
    case 4:
      var value = new proto.app.gambo.PingMessage;
      reader.readMessage(value,proto.app.gambo.PingMessage.deserializeBinaryFromReader);
      msg.setPingmessage(value);
      break;
    case 5:
      var value = new proto.app.gambo.PongMessage;
      reader.readMessage(value,proto.app.gambo.PongMessage.deserializeBinaryFromReader);
      msg.setPongmessage(value);
      break;
    case 6:
      var value = new proto.app.gambo.ErrorServerMessage;
      reader.readMessage(value,proto.app.gambo.ErrorServerMessage.deserializeBinaryFromReader);
      msg.setErrorservermessage(value);
      break;
    case 7:
      var value = new proto.app.gambo.ErrorClientMessage;
      reader.readMessage(value,proto.app.gambo.ErrorClientMessage.deserializeBinaryFromReader);
      msg.setErrorclientmessage(value);
      break;
    case 8:
      var value = new proto.app.gambo.DominoesStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.DominoesStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setDominoesstartgamemessagerequest(value);
      break;
    case 9:
      var value = new proto.app.gambo.DominoesMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.DominoesMoveMessageRequest.deserializeBinaryFromReader);
      msg.setDominoesmovemessagerequest(value);
      break;
    case 10:
      var value = new proto.app.gambo.DominoesMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.DominoesMoveMessageResponse.deserializeBinaryFromReader);
      msg.setDominoesmovemessageresponse(value);
      break;
    case 11:
      var value = new proto.app.gambo.DominoesCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.DominoesCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setDominoescurrentstatemessagerequest(value);
      break;
    case 12:
      var value = new proto.app.gambo.DominoesCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.DominoesCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setDominoescurrentstatemessageresponse(value);
      break;
    case 13:
      var value = new proto.app.gambo.DominoesRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.DominoesRestartMessageRequest.deserializeBinaryFromReader);
      msg.setDominoesrestartmessagerequest(value);
      break;
    case 14:
      var value = new proto.app.gambo.DominoesRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.DominoesRestartMessageResponse.deserializeBinaryFromReader);
      msg.setDominoesrestartmessageresponse(value);
      break;
    case 15:
      var value = new proto.app.gambo.DominoesFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.DominoesFinishMessageRequest.deserializeBinaryFromReader);
      msg.setDominoesfinishmessagerequest(value);
      break;
    case 16:
      var value = new proto.app.gambo.DominoesFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.DominoesFinishMessageResponse.deserializeBinaryFromReader);
      msg.setDominoesfinishmessageresponse(value);
      break;
    case 17:
      var value = new proto.app.gambo.SeaBattleStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.SeaBattleStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setSeabattlestartgamemessagerequest(value);
      break;
    case 18:
      var value = new proto.app.gambo.SeaBattleMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.SeaBattleMoveMessageRequest.deserializeBinaryFromReader);
      msg.setSeabattlemovemessagerequest(value);
      break;
    case 19:
      var value = new proto.app.gambo.SeaBattleMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.SeaBattleMoveMessageResponse.deserializeBinaryFromReader);
      msg.setSeabattlemovemessageresponse(value);
      break;
    case 20:
      var value = new proto.app.gambo.SeaBattlePlaceShipsMessageRequest;
      reader.readMessage(value,proto.app.gambo.SeaBattlePlaceShipsMessageRequest.deserializeBinaryFromReader);
      msg.setSeabattleplaceshipsmessagerequest(value);
      break;
    case 21:
      var value = new proto.app.gambo.SeaBattlePlaceShipsMessageResponse;
      reader.readMessage(value,proto.app.gambo.SeaBattlePlaceShipsMessageResponse.deserializeBinaryFromReader);
      msg.setSeabattleplaceshipsmessageresponse(value);
      break;
    case 22:
      var value = new proto.app.gambo.SeaBattlePlayersReadyResponse;
      reader.readMessage(value,proto.app.gambo.SeaBattlePlayersReadyResponse.deserializeBinaryFromReader);
      msg.setSeabattleplayersreadyresponse(value);
      break;
    case 23:
      var value = new proto.app.gambo.SeaBattleCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.SeaBattleCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setSeabattlecurrentstatemessagerequest(value);
      break;
    case 24:
      var value = new proto.app.gambo.SeaBattleCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.SeaBattleCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setSeabattlecurrentstatemessageresponse(value);
      break;
    case 25:
      var value = new proto.app.gambo.SeaBattleRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.SeaBattleRestartMessageRequest.deserializeBinaryFromReader);
      msg.setSeabattlerestartmessagerequest(value);
      break;
    case 26:
      var value = new proto.app.gambo.SeaBattleRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.SeaBattleRestartMessageResponse.deserializeBinaryFromReader);
      msg.setSeabattlerestartmessageresponse(value);
      break;
    case 27:
      var value = new proto.app.gambo.SeaBattleFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.SeaBattleFinishMessageRequest.deserializeBinaryFromReader);
      msg.setSeabattlefinishmessagerequest(value);
      break;
    case 28:
      var value = new proto.app.gambo.SeaBattleFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.SeaBattleFinishMessageResponse.deserializeBinaryFromReader);
      msg.setSeabattlefinishmessageresponse(value);
      break;
    case 29:
      var value = new proto.app.gambo.QuizButtonStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.QuizButtonStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setQuizbuttonstartgamemessagerequest(value);
      break;
    case 30:
      var value = new proto.app.gambo.QuizButtonMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.QuizButtonMoveMessageRequest.deserializeBinaryFromReader);
      msg.setQuizbuttonmovemessagerequest(value);
      break;
    case 31:
      var value = new proto.app.gambo.QuizButtonMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.QuizButtonMoveMessageResponse.deserializeBinaryFromReader);
      msg.setQuizbuttonmovemessageresponse(value);
      break;
    case 32:
      var value = new proto.app.gambo.QuizButtonCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.QuizButtonCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setQuizbuttoncurrentstatemessagerequest(value);
      break;
    case 33:
      var value = new proto.app.gambo.QuizButtonCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.QuizButtonCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setQuizbuttoncurrentstatemessageresponse(value);
      break;
    case 34:
      var value = new proto.app.gambo.QuizButtonRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.QuizButtonRestartMessageRequest.deserializeBinaryFromReader);
      msg.setQuizbuttonrestartmessagerequest(value);
      break;
    case 35:
      var value = new proto.app.gambo.QuizButtonRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.QuizButtonRestartMessageResponse.deserializeBinaryFromReader);
      msg.setQuizbuttonrestartmessageresponse(value);
      break;
    case 36:
      var value = new proto.app.gambo.QuizButtonFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.QuizButtonFinishMessageRequest.deserializeBinaryFromReader);
      msg.setQuizbuttonfinishmessagerequest(value);
      break;
    case 37:
      var value = new proto.app.gambo.QuizButtonFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.QuizButtonFinishMessageResponse.deserializeBinaryFromReader);
      msg.setQuizbuttonfinishmessageresponse(value);
      break;
    case 38:
      var value = new proto.app.gambo.CheckersStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.CheckersStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setCheckersstartgamemessagerequest(value);
      break;
    case 39:
      var value = new proto.app.gambo.CheckersMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.CheckersMoveMessageRequest.deserializeBinaryFromReader);
      msg.setCheckersmovemessagerequest(value);
      break;
    case 40:
      var value = new proto.app.gambo.CheckersMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.CheckersMoveMessageResponse.deserializeBinaryFromReader);
      msg.setCheckersmovemessageresponse(value);
      break;
    case 41:
      var value = new proto.app.gambo.CheckersCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.CheckersCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setCheckerscurrentstatemessagerequest(value);
      break;
    case 42:
      var value = new proto.app.gambo.CheckersCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.CheckersCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setCheckerscurrentstatemessageresponse(value);
      break;
    case 43:
      var value = new proto.app.gambo.CheckersRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.CheckersRestartMessageRequest.deserializeBinaryFromReader);
      msg.setCheckersrestartmessagerequest(value);
      break;
    case 44:
      var value = new proto.app.gambo.CheckersRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.CheckersRestartMessageResponse.deserializeBinaryFromReader);
      msg.setCheckersrestartmessageresponse(value);
      break;
    case 45:
      var value = new proto.app.gambo.CheckersFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.CheckersFinishMessageRequest.deserializeBinaryFromReader);
      msg.setCheckersfinishmessagerequest(value);
      break;
    case 46:
      var value = new proto.app.gambo.CheckersFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.CheckersFinishMessageResponse.deserializeBinaryFromReader);
      msg.setCheckersfinishmessageresponse(value);
      break;
    case 47:
      var value = new proto.app.gambo.DurakStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.DurakStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setDurakstartgamemessagerequest(value);
      break;
    case 48:
      var value = new proto.app.gambo.DurakMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.DurakMoveMessageRequest.deserializeBinaryFromReader);
      msg.setDurakmovemessagerequest(value);
      break;
    case 49:
      var value = new proto.app.gambo.DurakMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.DurakMoveMessageResponse.deserializeBinaryFromReader);
      msg.setDurakmovemessageresponse(value);
      break;
    case 50:
      var value = new proto.app.gambo.DurakCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.DurakCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setDurakcurrentstatemessagerequest(value);
      break;
    case 51:
      var value = new proto.app.gambo.DurakCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.DurakCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setDurakcurrentstatemessageresponse(value);
      break;
    case 52:
      var value = new proto.app.gambo.DurakRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.DurakRestartMessageRequest.deserializeBinaryFromReader);
      msg.setDurakrestartmessagerequest(value);
      break;
    case 53:
      var value = new proto.app.gambo.DurakRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.DurakRestartMessageResponse.deserializeBinaryFromReader);
      msg.setDurakrestartmessageresponse(value);
      break;
    case 54:
      var value = new proto.app.gambo.DurakFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.DurakFinishMessageRequest.deserializeBinaryFromReader);
      msg.setDurakfinishmessagerequest(value);
      break;
    case 55:
      var value = new proto.app.gambo.DurakFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.DurakFinishMessageResponse.deserializeBinaryFromReader);
      msg.setDurakfinishmessageresponse(value);
      break;
    case 56:
      var value = new proto.app.gambo.ChessStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.ChessStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setChessstartgamemessagerequest(value);
      break;
    case 57:
      var value = new proto.app.gambo.ChessMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.ChessMoveMessageRequest.deserializeBinaryFromReader);
      msg.setChessmovemessagerequest(value);
      break;
    case 58:
      var value = new proto.app.gambo.ChessMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.ChessMoveMessageResponse.deserializeBinaryFromReader);
      msg.setChessmovemessageresponse(value);
      break;
    case 59:
      var value = new proto.app.gambo.ChessCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.ChessCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setChesscurrentstatemessagerequest(value);
      break;
    case 60:
      var value = new proto.app.gambo.ChessCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.ChessCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setChesscurrentstatemessageresponse(value);
      break;
    case 61:
      var value = new proto.app.gambo.ChessRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.ChessRestartMessageRequest.deserializeBinaryFromReader);
      msg.setChessrestartmessagerequest(value);
      break;
    case 62:
      var value = new proto.app.gambo.ChessRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.ChessRestartMessageResponse.deserializeBinaryFromReader);
      msg.setChessrestartmessageresponse(value);
      break;
    case 63:
      var value = new proto.app.gambo.ChessFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.ChessFinishMessageRequest.deserializeBinaryFromReader);
      msg.setChessfinishmessagerequest(value);
      break;
    case 64:
      var value = new proto.app.gambo.ChessFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.ChessFinishMessageResponse.deserializeBinaryFromReader);
      msg.setChessfinishmessageresponse(value);
      break;
    case 65:
      var value = new proto.app.gambo.GoStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.GoStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setGostartgamemessagerequest(value);
      break;
    case 66:
      var value = new proto.app.gambo.GoMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.GoMoveMessageRequest.deserializeBinaryFromReader);
      msg.setGomovemessagerequest(value);
      break;
    case 67:
      var value = new proto.app.gambo.GoMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.GoMoveMessageResponse.deserializeBinaryFromReader);
      msg.setGomovemessageresponse(value);
      break;
    case 68:
      var value = new proto.app.gambo.GoCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.GoCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setGocurrentstatemessagerequest(value);
      break;
    case 69:
      var value = new proto.app.gambo.GoCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.GoCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setGocurrentstatemessageresponse(value);
      break;
    case 70:
      var value = new proto.app.gambo.GoRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.GoRestartMessageRequest.deserializeBinaryFromReader);
      msg.setGorestartmessagerequest(value);
      break;
    case 71:
      var value = new proto.app.gambo.GoRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.GoRestartMessageResponse.deserializeBinaryFromReader);
      msg.setGorestartmessageresponse(value);
      break;
    case 72:
      var value = new proto.app.gambo.GoFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.GoFinishMessageRequest.deserializeBinaryFromReader);
      msg.setGofinishmessagerequest(value);
      break;
    case 73:
      var value = new proto.app.gambo.GoFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.GoFinishMessageResponse.deserializeBinaryFromReader);
      msg.setGofinishmessageresponse(value);
      break;
    case 74:
      var value = new proto.app.gambo.BackgammonStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.BackgammonStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setBackgammonstartgamemessagerequest(value);
      break;
    case 75:
      var value = new proto.app.gambo.BackgammonMoveMessageRequest;
      reader.readMessage(value,proto.app.gambo.BackgammonMoveMessageRequest.deserializeBinaryFromReader);
      msg.setBackgammonmovemessagerequest(value);
      break;
    case 76:
      var value = new proto.app.gambo.BackgammonMoveMessageResponse;
      reader.readMessage(value,proto.app.gambo.BackgammonMoveMessageResponse.deserializeBinaryFromReader);
      msg.setBackgammonmovemessageresponse(value);
      break;
    case 77:
      var value = new proto.app.gambo.BackgammonCurrentStateMessageRequest;
      reader.readMessage(value,proto.app.gambo.BackgammonCurrentStateMessageRequest.deserializeBinaryFromReader);
      msg.setBackgammoncurrentstatemessagerequest(value);
      break;
    case 78:
      var value = new proto.app.gambo.BackgammonCurrentStateMessageResponse;
      reader.readMessage(value,proto.app.gambo.BackgammonCurrentStateMessageResponse.deserializeBinaryFromReader);
      msg.setBackgammoncurrentstatemessageresponse(value);
      break;
    case 79:
      var value = new proto.app.gambo.BackgammonRestartMessageRequest;
      reader.readMessage(value,proto.app.gambo.BackgammonRestartMessageRequest.deserializeBinaryFromReader);
      msg.setBackgammonrestartmessagerequest(value);
      break;
    case 80:
      var value = new proto.app.gambo.BackgammonRestartMessageResponse;
      reader.readMessage(value,proto.app.gambo.BackgammonRestartMessageResponse.deserializeBinaryFromReader);
      msg.setBackgammonrestartmessageresponse(value);
      break;
    case 81:
      var value = new proto.app.gambo.BackgammonFinishMessageRequest;
      reader.readMessage(value,proto.app.gambo.BackgammonFinishMessageRequest.deserializeBinaryFromReader);
      msg.setBackgammonfinishmessagerequest(value);
      break;
    case 82:
      var value = new proto.app.gambo.BackgammonFinishMessageResponse;
      reader.readMessage(value,proto.app.gambo.BackgammonFinishMessageResponse.deserializeBinaryFromReader);
      msg.setBackgammonfinishmessageresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GeneralMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GeneralMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GeneralMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GeneralMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCookie();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPingmessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.gambo.PingMessage.serializeBinaryToWriter
    );
  }
  f = message.getPongmessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.app.gambo.PongMessage.serializeBinaryToWriter
    );
  }
  f = message.getErrorservermessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.app.gambo.ErrorServerMessage.serializeBinaryToWriter
    );
  }
  f = message.getErrorclientmessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.app.gambo.ErrorClientMessage.serializeBinaryToWriter
    );
  }
  f = message.getDominoesstartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.app.gambo.DominoesStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDominoesmovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.app.gambo.DominoesMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDominoesmovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.app.gambo.DominoesMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDominoescurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.app.gambo.DominoesCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDominoescurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.app.gambo.DominoesCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDominoesrestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.app.gambo.DominoesRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDominoesrestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.app.gambo.DominoesRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDominoesfinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.app.gambo.DominoesFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDominoesfinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.app.gambo.DominoesFinishMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlestartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.app.gambo.SeaBattleStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlemovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.app.gambo.SeaBattleMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlemovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.app.gambo.SeaBattleMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getSeabattleplaceshipsmessagerequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.app.gambo.SeaBattlePlaceShipsMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSeabattleplaceshipsmessageresponse();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.app.gambo.SeaBattlePlaceShipsMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getSeabattleplayersreadyresponse();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.app.gambo.SeaBattlePlayersReadyResponse.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlecurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.app.gambo.SeaBattleCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlecurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.app.gambo.SeaBattleCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlerestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.app.gambo.SeaBattleRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlerestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.app.gambo.SeaBattleRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlefinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.app.gambo.SeaBattleFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSeabattlefinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.app.gambo.SeaBattleFinishMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttonstartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.app.gambo.QuizButtonStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttonmovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.app.gambo.QuizButtonMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttonmovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.app.gambo.QuizButtonMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttoncurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.app.gambo.QuizButtonCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttoncurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.app.gambo.QuizButtonCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttonrestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.app.gambo.QuizButtonRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttonrestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.app.gambo.QuizButtonRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttonfinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.app.gambo.QuizButtonFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getQuizbuttonfinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.app.gambo.QuizButtonFinishMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getCheckersstartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.app.gambo.CheckersStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getCheckersmovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.app.gambo.CheckersMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getCheckersmovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.app.gambo.CheckersMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getCheckerscurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.app.gambo.CheckersCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getCheckerscurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.app.gambo.CheckersCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getCheckersrestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.app.gambo.CheckersRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getCheckersrestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.app.gambo.CheckersRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getCheckersfinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.app.gambo.CheckersFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getCheckersfinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.app.gambo.CheckersFinishMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDurakstartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.app.gambo.DurakStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDurakmovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      proto.app.gambo.DurakMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDurakmovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      proto.app.gambo.DurakMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDurakcurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.app.gambo.DurakCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDurakcurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.app.gambo.DurakCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDurakrestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.app.gambo.DurakRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDurakrestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.app.gambo.DurakRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDurakfinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.app.gambo.DurakFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getDurakfinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.app.gambo.DurakFinishMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getChessstartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.app.gambo.ChessStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getChessmovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.app.gambo.ChessMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getChessmovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.app.gambo.ChessMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getChesscurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.app.gambo.ChessCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getChesscurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      proto.app.gambo.ChessCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getChessrestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.app.gambo.ChessRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getChessrestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      proto.app.gambo.ChessRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getChessfinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      proto.app.gambo.ChessFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getChessfinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      proto.app.gambo.ChessFinishMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getGostartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      proto.app.gambo.GoStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getGomovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      proto.app.gambo.GoMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getGomovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      proto.app.gambo.GoMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getGocurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      proto.app.gambo.GoCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getGocurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      69,
      f,
      proto.app.gambo.GoCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getGorestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      proto.app.gambo.GoRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getGorestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      proto.app.gambo.GoRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getGofinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      72,
      f,
      proto.app.gambo.GoFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getGofinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      73,
      f,
      proto.app.gambo.GoFinishMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonstartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      proto.app.gambo.BackgammonStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonmovemessagerequest();
  if (f != null) {
    writer.writeMessage(
      75,
      f,
      proto.app.gambo.BackgammonMoveMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonmovemessageresponse();
  if (f != null) {
    writer.writeMessage(
      76,
      f,
      proto.app.gambo.BackgammonMoveMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getBackgammoncurrentstatemessagerequest();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      proto.app.gambo.BackgammonCurrentStateMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getBackgammoncurrentstatemessageresponse();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      proto.app.gambo.BackgammonCurrentStateMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonrestartmessagerequest();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      proto.app.gambo.BackgammonRestartMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonrestartmessageresponse();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      proto.app.gambo.BackgammonRestartMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonfinishmessagerequest();
  if (f != null) {
    writer.writeMessage(
      81,
      f,
      proto.app.gambo.BackgammonFinishMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonfinishmessageresponse();
  if (f != null) {
    writer.writeMessage(
      82,
      f,
      proto.app.gambo.BackgammonFinishMessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional MessageType messageType = 1;
 * @return {!proto.app.gambo.MessageType}
 */
proto.app.gambo.GeneralMessage.prototype.getMessagetype = function() {
  return /** @type {!proto.app.gambo.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.gambo.MessageType} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.setMessagetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string cookie = 2;
 * @return {string}
 */
proto.app.gambo.GeneralMessage.prototype.getCookie = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.setCookie = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string appVersion = 3;
 * @return {string}
 */
proto.app.gambo.GeneralMessage.prototype.getAppversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.setAppversion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PingMessage pingMessage = 4;
 * @return {?proto.app.gambo.PingMessage}
 */
proto.app.gambo.GeneralMessage.prototype.getPingmessage = function() {
  return /** @type{?proto.app.gambo.PingMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.PingMessage, 4));
};


/**
 * @param {?proto.app.gambo.PingMessage|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setPingmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearPingmessage = function() {
  return this.setPingmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasPingmessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PongMessage pongMessage = 5;
 * @return {?proto.app.gambo.PongMessage}
 */
proto.app.gambo.GeneralMessage.prototype.getPongmessage = function() {
  return /** @type{?proto.app.gambo.PongMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.PongMessage, 5));
};


/**
 * @param {?proto.app.gambo.PongMessage|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setPongmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearPongmessage = function() {
  return this.setPongmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasPongmessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorServerMessage errorServerMessage = 6;
 * @return {?proto.app.gambo.ErrorServerMessage}
 */
proto.app.gambo.GeneralMessage.prototype.getErrorservermessage = function() {
  return /** @type{?proto.app.gambo.ErrorServerMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ErrorServerMessage, 6));
};


/**
 * @param {?proto.app.gambo.ErrorServerMessage|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setErrorservermessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearErrorservermessage = function() {
  return this.setErrorservermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasErrorservermessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ErrorClientMessage errorClientMessage = 7;
 * @return {?proto.app.gambo.ErrorClientMessage}
 */
proto.app.gambo.GeneralMessage.prototype.getErrorclientmessage = function() {
  return /** @type{?proto.app.gambo.ErrorClientMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ErrorClientMessage, 7));
};


/**
 * @param {?proto.app.gambo.ErrorClientMessage|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setErrorclientmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearErrorclientmessage = function() {
  return this.setErrorclientmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasErrorclientmessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DominoesStartGameMessageRequest dominoesStartGameMessageRequest = 8;
 * @return {?proto.app.gambo.DominoesStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoesstartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.DominoesStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesStartGameMessageRequest, 8));
};


/**
 * @param {?proto.app.gambo.DominoesStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoesstartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoesstartgamemessagerequest = function() {
  return this.setDominoesstartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoesstartgamemessagerequest = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DominoesMoveMessageRequest dominoesMoveMessageRequest = 9;
 * @return {?proto.app.gambo.DominoesMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoesmovemessagerequest = function() {
  return /** @type{?proto.app.gambo.DominoesMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesMoveMessageRequest, 9));
};


/**
 * @param {?proto.app.gambo.DominoesMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoesmovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoesmovemessagerequest = function() {
  return this.setDominoesmovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoesmovemessagerequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DominoesMoveMessageResponse dominoesMoveMessageResponse = 10;
 * @return {?proto.app.gambo.DominoesMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoesmovemessageresponse = function() {
  return /** @type{?proto.app.gambo.DominoesMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesMoveMessageResponse, 10));
};


/**
 * @param {?proto.app.gambo.DominoesMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoesmovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoesmovemessageresponse = function() {
  return this.setDominoesmovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoesmovemessageresponse = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DominoesCurrentStateMessageRequest dominoesCurrentStateMessageRequest = 11;
 * @return {?proto.app.gambo.DominoesCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoescurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.DominoesCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesCurrentStateMessageRequest, 11));
};


/**
 * @param {?proto.app.gambo.DominoesCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoescurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoescurrentstatemessagerequest = function() {
  return this.setDominoescurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoescurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DominoesCurrentStateMessageResponse dominoesCurrentStateMessageResponse = 12;
 * @return {?proto.app.gambo.DominoesCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoescurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.DominoesCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesCurrentStateMessageResponse, 12));
};


/**
 * @param {?proto.app.gambo.DominoesCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoescurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoescurrentstatemessageresponse = function() {
  return this.setDominoescurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoescurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DominoesRestartMessageRequest dominoesRestartMessageRequest = 13;
 * @return {?proto.app.gambo.DominoesRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoesrestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.DominoesRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesRestartMessageRequest, 13));
};


/**
 * @param {?proto.app.gambo.DominoesRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoesrestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoesrestartmessagerequest = function() {
  return this.setDominoesrestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoesrestartmessagerequest = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DominoesRestartMessageResponse dominoesRestartMessageResponse = 14;
 * @return {?proto.app.gambo.DominoesRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoesrestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.DominoesRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesRestartMessageResponse, 14));
};


/**
 * @param {?proto.app.gambo.DominoesRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoesrestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoesrestartmessageresponse = function() {
  return this.setDominoesrestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoesrestartmessageresponse = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional DominoesFinishMessageRequest dominoesFinishMessageRequest = 15;
 * @return {?proto.app.gambo.DominoesFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoesfinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.DominoesFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesFinishMessageRequest, 15));
};


/**
 * @param {?proto.app.gambo.DominoesFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoesfinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoesfinishmessagerequest = function() {
  return this.setDominoesfinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoesfinishmessagerequest = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional DominoesFinishMessageResponse dominoesFinishMessageResponse = 16;
 * @return {?proto.app.gambo.DominoesFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDominoesfinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.DominoesFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesFinishMessageResponse, 16));
};


/**
 * @param {?proto.app.gambo.DominoesFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDominoesfinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDominoesfinishmessageresponse = function() {
  return this.setDominoesfinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDominoesfinishmessageresponse = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional SeaBattleStartGameMessageRequest seaBattleStartGameMessageRequest = 17;
 * @return {?proto.app.gambo.SeaBattleStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlestartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.SeaBattleStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleStartGameMessageRequest, 17));
};


/**
 * @param {?proto.app.gambo.SeaBattleStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlestartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlestartgamemessagerequest = function() {
  return this.setSeabattlestartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlestartgamemessagerequest = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional SeaBattleMoveMessageRequest seaBattleMoveMessageRequest = 18;
 * @return {?proto.app.gambo.SeaBattleMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlemovemessagerequest = function() {
  return /** @type{?proto.app.gambo.SeaBattleMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleMoveMessageRequest, 18));
};


/**
 * @param {?proto.app.gambo.SeaBattleMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlemovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlemovemessagerequest = function() {
  return this.setSeabattlemovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlemovemessagerequest = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional SeaBattleMoveMessageResponse seaBattleMoveMessageResponse = 19;
 * @return {?proto.app.gambo.SeaBattleMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlemovemessageresponse = function() {
  return /** @type{?proto.app.gambo.SeaBattleMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleMoveMessageResponse, 19));
};


/**
 * @param {?proto.app.gambo.SeaBattleMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlemovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlemovemessageresponse = function() {
  return this.setSeabattlemovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlemovemessageresponse = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional SeaBattlePlaceShipsMessageRequest seaBattlePlaceShipsMessageRequest = 20;
 * @return {?proto.app.gambo.SeaBattlePlaceShipsMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattleplaceshipsmessagerequest = function() {
  return /** @type{?proto.app.gambo.SeaBattlePlaceShipsMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePlaceShipsMessageRequest, 20));
};


/**
 * @param {?proto.app.gambo.SeaBattlePlaceShipsMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattleplaceshipsmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattleplaceshipsmessagerequest = function() {
  return this.setSeabattleplaceshipsmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattleplaceshipsmessagerequest = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional SeaBattlePlaceShipsMessageResponse seaBattlePlaceShipsMessageResponse = 21;
 * @return {?proto.app.gambo.SeaBattlePlaceShipsMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattleplaceshipsmessageresponse = function() {
  return /** @type{?proto.app.gambo.SeaBattlePlaceShipsMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePlaceShipsMessageResponse, 21));
};


/**
 * @param {?proto.app.gambo.SeaBattlePlaceShipsMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattleplaceshipsmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattleplaceshipsmessageresponse = function() {
  return this.setSeabattleplaceshipsmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattleplaceshipsmessageresponse = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional SeaBattlePlayersReadyResponse seaBattlePlayersReadyResponse = 22;
 * @return {?proto.app.gambo.SeaBattlePlayersReadyResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattleplayersreadyresponse = function() {
  return /** @type{?proto.app.gambo.SeaBattlePlayersReadyResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePlayersReadyResponse, 22));
};


/**
 * @param {?proto.app.gambo.SeaBattlePlayersReadyResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattleplayersreadyresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattleplayersreadyresponse = function() {
  return this.setSeabattleplayersreadyresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattleplayersreadyresponse = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional SeaBattleCurrentStateMessageRequest seaBattleCurrentStateMessageRequest = 23;
 * @return {?proto.app.gambo.SeaBattleCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlecurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.SeaBattleCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleCurrentStateMessageRequest, 23));
};


/**
 * @param {?proto.app.gambo.SeaBattleCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlecurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlecurrentstatemessagerequest = function() {
  return this.setSeabattlecurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlecurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional SeaBattleCurrentStateMessageResponse seaBattleCurrentStateMessageResponse = 24;
 * @return {?proto.app.gambo.SeaBattleCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlecurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.SeaBattleCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleCurrentStateMessageResponse, 24));
};


/**
 * @param {?proto.app.gambo.SeaBattleCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlecurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlecurrentstatemessageresponse = function() {
  return this.setSeabattlecurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlecurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional SeaBattleRestartMessageRequest seaBattleRestartMessageRequest = 25;
 * @return {?proto.app.gambo.SeaBattleRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlerestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.SeaBattleRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleRestartMessageRequest, 25));
};


/**
 * @param {?proto.app.gambo.SeaBattleRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlerestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlerestartmessagerequest = function() {
  return this.setSeabattlerestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlerestartmessagerequest = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional SeaBattleRestartMessageResponse seaBattleRestartMessageResponse = 26;
 * @return {?proto.app.gambo.SeaBattleRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlerestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.SeaBattleRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleRestartMessageResponse, 26));
};


/**
 * @param {?proto.app.gambo.SeaBattleRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlerestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlerestartmessageresponse = function() {
  return this.setSeabattlerestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlerestartmessageresponse = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional SeaBattleFinishMessageRequest seaBattleFinishMessageRequest = 27;
 * @return {?proto.app.gambo.SeaBattleFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlefinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.SeaBattleFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleFinishMessageRequest, 27));
};


/**
 * @param {?proto.app.gambo.SeaBattleFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlefinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlefinishmessagerequest = function() {
  return this.setSeabattlefinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlefinishmessagerequest = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional SeaBattleFinishMessageResponse seaBattleFinishMessageResponse = 28;
 * @return {?proto.app.gambo.SeaBattleFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getSeabattlefinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.SeaBattleFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattleFinishMessageResponse, 28));
};


/**
 * @param {?proto.app.gambo.SeaBattleFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setSeabattlefinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearSeabattlefinishmessageresponse = function() {
  return this.setSeabattlefinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasSeabattlefinishmessageresponse = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional QuizButtonStartGameMessageRequest quizButtonStartGameMessageRequest = 29;
 * @return {?proto.app.gambo.QuizButtonStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttonstartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.QuizButtonStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonStartGameMessageRequest, 29));
};


/**
 * @param {?proto.app.gambo.QuizButtonStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttonstartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttonstartgamemessagerequest = function() {
  return this.setQuizbuttonstartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttonstartgamemessagerequest = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional QuizButtonMoveMessageRequest quizButtonMoveMessageRequest = 30;
 * @return {?proto.app.gambo.QuizButtonMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttonmovemessagerequest = function() {
  return /** @type{?proto.app.gambo.QuizButtonMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonMoveMessageRequest, 30));
};


/**
 * @param {?proto.app.gambo.QuizButtonMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttonmovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttonmovemessagerequest = function() {
  return this.setQuizbuttonmovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttonmovemessagerequest = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional QuizButtonMoveMessageResponse quizButtonMoveMessageResponse = 31;
 * @return {?proto.app.gambo.QuizButtonMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttonmovemessageresponse = function() {
  return /** @type{?proto.app.gambo.QuizButtonMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonMoveMessageResponse, 31));
};


/**
 * @param {?proto.app.gambo.QuizButtonMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttonmovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttonmovemessageresponse = function() {
  return this.setQuizbuttonmovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttonmovemessageresponse = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional QuizButtonCurrentStateMessageRequest quizButtonCurrentStateMessageRequest = 32;
 * @return {?proto.app.gambo.QuizButtonCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttoncurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.QuizButtonCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonCurrentStateMessageRequest, 32));
};


/**
 * @param {?proto.app.gambo.QuizButtonCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttoncurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttoncurrentstatemessagerequest = function() {
  return this.setQuizbuttoncurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttoncurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional QuizButtonCurrentStateMessageResponse quizButtonCurrentStateMessageResponse = 33;
 * @return {?proto.app.gambo.QuizButtonCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttoncurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.QuizButtonCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonCurrentStateMessageResponse, 33));
};


/**
 * @param {?proto.app.gambo.QuizButtonCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttoncurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttoncurrentstatemessageresponse = function() {
  return this.setQuizbuttoncurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttoncurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional QuizButtonRestartMessageRequest quizButtonRestartMessageRequest = 34;
 * @return {?proto.app.gambo.QuizButtonRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttonrestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.QuizButtonRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonRestartMessageRequest, 34));
};


/**
 * @param {?proto.app.gambo.QuizButtonRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttonrestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttonrestartmessagerequest = function() {
  return this.setQuizbuttonrestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttonrestartmessagerequest = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional QuizButtonRestartMessageResponse quizButtonRestartMessageResponse = 35;
 * @return {?proto.app.gambo.QuizButtonRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttonrestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.QuizButtonRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonRestartMessageResponse, 35));
};


/**
 * @param {?proto.app.gambo.QuizButtonRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttonrestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttonrestartmessageresponse = function() {
  return this.setQuizbuttonrestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttonrestartmessageresponse = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional QuizButtonFinishMessageRequest quizButtonFinishMessageRequest = 36;
 * @return {?proto.app.gambo.QuizButtonFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttonfinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.QuizButtonFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonFinishMessageRequest, 36));
};


/**
 * @param {?proto.app.gambo.QuizButtonFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttonfinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttonfinishmessagerequest = function() {
  return this.setQuizbuttonfinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttonfinishmessagerequest = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional QuizButtonFinishMessageResponse quizButtonFinishMessageResponse = 37;
 * @return {?proto.app.gambo.QuizButtonFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getQuizbuttonfinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.QuizButtonFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizButtonFinishMessageResponse, 37));
};


/**
 * @param {?proto.app.gambo.QuizButtonFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setQuizbuttonfinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearQuizbuttonfinishmessageresponse = function() {
  return this.setQuizbuttonfinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasQuizbuttonfinishmessageresponse = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional CheckersStartGameMessageRequest checkersStartGameMessageRequest = 38;
 * @return {?proto.app.gambo.CheckersStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckersstartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.CheckersStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersStartGameMessageRequest, 38));
};


/**
 * @param {?proto.app.gambo.CheckersStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckersstartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckersstartgamemessagerequest = function() {
  return this.setCheckersstartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckersstartgamemessagerequest = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional CheckersMoveMessageRequest checkersMoveMessageRequest = 39;
 * @return {?proto.app.gambo.CheckersMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckersmovemessagerequest = function() {
  return /** @type{?proto.app.gambo.CheckersMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersMoveMessageRequest, 39));
};


/**
 * @param {?proto.app.gambo.CheckersMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckersmovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckersmovemessagerequest = function() {
  return this.setCheckersmovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckersmovemessagerequest = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional CheckersMoveMessageResponse checkersMoveMessageResponse = 40;
 * @return {?proto.app.gambo.CheckersMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckersmovemessageresponse = function() {
  return /** @type{?proto.app.gambo.CheckersMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersMoveMessageResponse, 40));
};


/**
 * @param {?proto.app.gambo.CheckersMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckersmovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckersmovemessageresponse = function() {
  return this.setCheckersmovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckersmovemessageresponse = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional CheckersCurrentStateMessageRequest checkersCurrentStateMessageRequest = 41;
 * @return {?proto.app.gambo.CheckersCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckerscurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.CheckersCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersCurrentStateMessageRequest, 41));
};


/**
 * @param {?proto.app.gambo.CheckersCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckerscurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckerscurrentstatemessagerequest = function() {
  return this.setCheckerscurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckerscurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional CheckersCurrentStateMessageResponse checkersCurrentStateMessageResponse = 42;
 * @return {?proto.app.gambo.CheckersCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckerscurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.CheckersCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersCurrentStateMessageResponse, 42));
};


/**
 * @param {?proto.app.gambo.CheckersCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckerscurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckerscurrentstatemessageresponse = function() {
  return this.setCheckerscurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckerscurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional CheckersRestartMessageRequest checkersRestartMessageRequest = 43;
 * @return {?proto.app.gambo.CheckersRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckersrestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.CheckersRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersRestartMessageRequest, 43));
};


/**
 * @param {?proto.app.gambo.CheckersRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckersrestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckersrestartmessagerequest = function() {
  return this.setCheckersrestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckersrestartmessagerequest = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional CheckersRestartMessageResponse checkersRestartMessageResponse = 44;
 * @return {?proto.app.gambo.CheckersRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckersrestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.CheckersRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersRestartMessageResponse, 44));
};


/**
 * @param {?proto.app.gambo.CheckersRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckersrestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 44, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckersrestartmessageresponse = function() {
  return this.setCheckersrestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckersrestartmessageresponse = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional CheckersFinishMessageRequest checkersFinishMessageRequest = 45;
 * @return {?proto.app.gambo.CheckersFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckersfinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.CheckersFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersFinishMessageRequest, 45));
};


/**
 * @param {?proto.app.gambo.CheckersFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckersfinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckersfinishmessagerequest = function() {
  return this.setCheckersfinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckersfinishmessagerequest = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional CheckersFinishMessageResponse checkersFinishMessageResponse = 46;
 * @return {?proto.app.gambo.CheckersFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getCheckersfinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.CheckersFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersFinishMessageResponse, 46));
};


/**
 * @param {?proto.app.gambo.CheckersFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setCheckersfinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearCheckersfinishmessageresponse = function() {
  return this.setCheckersfinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasCheckersfinishmessageresponse = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional DurakStartGameMessageRequest durakStartGameMessageRequest = 47;
 * @return {?proto.app.gambo.DurakStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakstartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.DurakStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakStartGameMessageRequest, 47));
};


/**
 * @param {?proto.app.gambo.DurakStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakstartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakstartgamemessagerequest = function() {
  return this.setDurakstartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakstartgamemessagerequest = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional DurakMoveMessageRequest durakMoveMessageRequest = 48;
 * @return {?proto.app.gambo.DurakMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakmovemessagerequest = function() {
  return /** @type{?proto.app.gambo.DurakMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakMoveMessageRequest, 48));
};


/**
 * @param {?proto.app.gambo.DurakMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakmovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakmovemessagerequest = function() {
  return this.setDurakmovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakmovemessagerequest = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional DurakMoveMessageResponse durakMoveMessageResponse = 49;
 * @return {?proto.app.gambo.DurakMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakmovemessageresponse = function() {
  return /** @type{?proto.app.gambo.DurakMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakMoveMessageResponse, 49));
};


/**
 * @param {?proto.app.gambo.DurakMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakmovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakmovemessageresponse = function() {
  return this.setDurakmovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakmovemessageresponse = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional DurakCurrentStateMessageRequest durakCurrentStateMessageRequest = 50;
 * @return {?proto.app.gambo.DurakCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakcurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.DurakCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCurrentStateMessageRequest, 50));
};


/**
 * @param {?proto.app.gambo.DurakCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakcurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakcurrentstatemessagerequest = function() {
  return this.setDurakcurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakcurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional DurakCurrentStateMessageResponse durakCurrentStateMessageResponse = 51;
 * @return {?proto.app.gambo.DurakCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakcurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.DurakCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCurrentStateMessageResponse, 51));
};


/**
 * @param {?proto.app.gambo.DurakCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakcurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakcurrentstatemessageresponse = function() {
  return this.setDurakcurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakcurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional DurakRestartMessageRequest durakRestartMessageRequest = 52;
 * @return {?proto.app.gambo.DurakRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakrestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.DurakRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakRestartMessageRequest, 52));
};


/**
 * @param {?proto.app.gambo.DurakRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakrestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakrestartmessagerequest = function() {
  return this.setDurakrestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakrestartmessagerequest = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional DurakRestartMessageResponse durakRestartMessageResponse = 53;
 * @return {?proto.app.gambo.DurakRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakrestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.DurakRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakRestartMessageResponse, 53));
};


/**
 * @param {?proto.app.gambo.DurakRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakrestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakrestartmessageresponse = function() {
  return this.setDurakrestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakrestartmessageresponse = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional DurakFinishMessageRequest durakFinishMessageRequest = 54;
 * @return {?proto.app.gambo.DurakFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakfinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.DurakFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakFinishMessageRequest, 54));
};


/**
 * @param {?proto.app.gambo.DurakFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakfinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakfinishmessagerequest = function() {
  return this.setDurakfinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakfinishmessagerequest = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional DurakFinishMessageResponse durakFinishMessageResponse = 55;
 * @return {?proto.app.gambo.DurakFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getDurakfinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.DurakFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakFinishMessageResponse, 55));
};


/**
 * @param {?proto.app.gambo.DurakFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setDurakfinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearDurakfinishmessageresponse = function() {
  return this.setDurakfinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasDurakfinishmessageresponse = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional ChessStartGameMessageRequest chessStartGameMessageRequest = 56;
 * @return {?proto.app.gambo.ChessStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getChessstartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.ChessStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessStartGameMessageRequest, 56));
};


/**
 * @param {?proto.app.gambo.ChessStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChessstartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 56, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChessstartgamemessagerequest = function() {
  return this.setChessstartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChessstartgamemessagerequest = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional ChessMoveMessageRequest chessMoveMessageRequest = 57;
 * @return {?proto.app.gambo.ChessMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getChessmovemessagerequest = function() {
  return /** @type{?proto.app.gambo.ChessMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessMoveMessageRequest, 57));
};


/**
 * @param {?proto.app.gambo.ChessMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChessmovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 57, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChessmovemessagerequest = function() {
  return this.setChessmovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChessmovemessagerequest = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional ChessMoveMessageResponse chessMoveMessageResponse = 58;
 * @return {?proto.app.gambo.ChessMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getChessmovemessageresponse = function() {
  return /** @type{?proto.app.gambo.ChessMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessMoveMessageResponse, 58));
};


/**
 * @param {?proto.app.gambo.ChessMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChessmovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 58, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChessmovemessageresponse = function() {
  return this.setChessmovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChessmovemessageresponse = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional ChessCurrentStateMessageRequest chessCurrentStateMessageRequest = 59;
 * @return {?proto.app.gambo.ChessCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getChesscurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.ChessCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessCurrentStateMessageRequest, 59));
};


/**
 * @param {?proto.app.gambo.ChessCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChesscurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 59, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChesscurrentstatemessagerequest = function() {
  return this.setChesscurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChesscurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional ChessCurrentStateMessageResponse chessCurrentStateMessageResponse = 60;
 * @return {?proto.app.gambo.ChessCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getChesscurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.ChessCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessCurrentStateMessageResponse, 60));
};


/**
 * @param {?proto.app.gambo.ChessCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChesscurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 60, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChesscurrentstatemessageresponse = function() {
  return this.setChesscurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChesscurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional ChessRestartMessageRequest chessRestartMessageRequest = 61;
 * @return {?proto.app.gambo.ChessRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getChessrestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.ChessRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessRestartMessageRequest, 61));
};


/**
 * @param {?proto.app.gambo.ChessRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChessrestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 61, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChessrestartmessagerequest = function() {
  return this.setChessrestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChessrestartmessagerequest = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional ChessRestartMessageResponse chessRestartMessageResponse = 62;
 * @return {?proto.app.gambo.ChessRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getChessrestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.ChessRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessRestartMessageResponse, 62));
};


/**
 * @param {?proto.app.gambo.ChessRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChessrestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 62, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChessrestartmessageresponse = function() {
  return this.setChessrestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChessrestartmessageresponse = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional ChessFinishMessageRequest chessFinishMessageRequest = 63;
 * @return {?proto.app.gambo.ChessFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getChessfinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.ChessFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessFinishMessageRequest, 63));
};


/**
 * @param {?proto.app.gambo.ChessFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChessfinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 63, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChessfinishmessagerequest = function() {
  return this.setChessfinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChessfinishmessagerequest = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional ChessFinishMessageResponse chessFinishMessageResponse = 64;
 * @return {?proto.app.gambo.ChessFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getChessfinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.ChessFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessFinishMessageResponse, 64));
};


/**
 * @param {?proto.app.gambo.ChessFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setChessfinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 64, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearChessfinishmessageresponse = function() {
  return this.setChessfinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasChessfinishmessageresponse = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional GoStartGameMessageRequest goStartGameMessageRequest = 65;
 * @return {?proto.app.gambo.GoStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getGostartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.GoStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoStartGameMessageRequest, 65));
};


/**
 * @param {?proto.app.gambo.GoStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGostartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 65, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGostartgamemessagerequest = function() {
  return this.setGostartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGostartgamemessagerequest = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional GoMoveMessageRequest goMoveMessageRequest = 66;
 * @return {?proto.app.gambo.GoMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getGomovemessagerequest = function() {
  return /** @type{?proto.app.gambo.GoMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoMoveMessageRequest, 66));
};


/**
 * @param {?proto.app.gambo.GoMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGomovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 66, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGomovemessagerequest = function() {
  return this.setGomovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGomovemessagerequest = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional GoMoveMessageResponse goMoveMessageResponse = 67;
 * @return {?proto.app.gambo.GoMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getGomovemessageresponse = function() {
  return /** @type{?proto.app.gambo.GoMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoMoveMessageResponse, 67));
};


/**
 * @param {?proto.app.gambo.GoMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGomovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 67, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGomovemessageresponse = function() {
  return this.setGomovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGomovemessageresponse = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional GoCurrentStateMessageRequest goCurrentStateMessageRequest = 68;
 * @return {?proto.app.gambo.GoCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getGocurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.GoCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoCurrentStateMessageRequest, 68));
};


/**
 * @param {?proto.app.gambo.GoCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGocurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 68, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGocurrentstatemessagerequest = function() {
  return this.setGocurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGocurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional GoCurrentStateMessageResponse goCurrentStateMessageResponse = 69;
 * @return {?proto.app.gambo.GoCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getGocurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.GoCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoCurrentStateMessageResponse, 69));
};


/**
 * @param {?proto.app.gambo.GoCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGocurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 69, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGocurrentstatemessageresponse = function() {
  return this.setGocurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGocurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional GoRestartMessageRequest goRestartMessageRequest = 70;
 * @return {?proto.app.gambo.GoRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getGorestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.GoRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoRestartMessageRequest, 70));
};


/**
 * @param {?proto.app.gambo.GoRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGorestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 70, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGorestartmessagerequest = function() {
  return this.setGorestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGorestartmessagerequest = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional GoRestartMessageResponse goRestartMessageResponse = 71;
 * @return {?proto.app.gambo.GoRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getGorestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.GoRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoRestartMessageResponse, 71));
};


/**
 * @param {?proto.app.gambo.GoRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGorestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 71, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGorestartmessageresponse = function() {
  return this.setGorestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGorestartmessageresponse = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional GoFinishMessageRequest goFinishMessageRequest = 72;
 * @return {?proto.app.gambo.GoFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getGofinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.GoFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoFinishMessageRequest, 72));
};


/**
 * @param {?proto.app.gambo.GoFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGofinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 72, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGofinishmessagerequest = function() {
  return this.setGofinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGofinishmessagerequest = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional GoFinishMessageResponse goFinishMessageResponse = 73;
 * @return {?proto.app.gambo.GoFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getGofinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.GoFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoFinishMessageResponse, 73));
};


/**
 * @param {?proto.app.gambo.GoFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setGofinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 73, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearGofinishmessageresponse = function() {
  return this.setGofinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasGofinishmessageresponse = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional BackgammonStartGameMessageRequest backgammonStartGameMessageRequest = 74;
 * @return {?proto.app.gambo.BackgammonStartGameMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammonstartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.BackgammonStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonStartGameMessageRequest, 74));
};


/**
 * @param {?proto.app.gambo.BackgammonStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammonstartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 74, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammonstartgamemessagerequest = function() {
  return this.setBackgammonstartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammonstartgamemessagerequest = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional BackgammonMoveMessageRequest backgammonMoveMessageRequest = 75;
 * @return {?proto.app.gambo.BackgammonMoveMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammonmovemessagerequest = function() {
  return /** @type{?proto.app.gambo.BackgammonMoveMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonMoveMessageRequest, 75));
};


/**
 * @param {?proto.app.gambo.BackgammonMoveMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammonmovemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 75, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammonmovemessagerequest = function() {
  return this.setBackgammonmovemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammonmovemessagerequest = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional BackgammonMoveMessageResponse backgammonMoveMessageResponse = 76;
 * @return {?proto.app.gambo.BackgammonMoveMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammonmovemessageresponse = function() {
  return /** @type{?proto.app.gambo.BackgammonMoveMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonMoveMessageResponse, 76));
};


/**
 * @param {?proto.app.gambo.BackgammonMoveMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammonmovemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 76, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammonmovemessageresponse = function() {
  return this.setBackgammonmovemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammonmovemessageresponse = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional BackgammonCurrentStateMessageRequest backgammonCurrentStateMessageRequest = 77;
 * @return {?proto.app.gambo.BackgammonCurrentStateMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammoncurrentstatemessagerequest = function() {
  return /** @type{?proto.app.gambo.BackgammonCurrentStateMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonCurrentStateMessageRequest, 77));
};


/**
 * @param {?proto.app.gambo.BackgammonCurrentStateMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammoncurrentstatemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 77, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammoncurrentstatemessagerequest = function() {
  return this.setBackgammoncurrentstatemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammoncurrentstatemessagerequest = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional BackgammonCurrentStateMessageResponse backgammonCurrentStateMessageResponse = 78;
 * @return {?proto.app.gambo.BackgammonCurrentStateMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammoncurrentstatemessageresponse = function() {
  return /** @type{?proto.app.gambo.BackgammonCurrentStateMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonCurrentStateMessageResponse, 78));
};


/**
 * @param {?proto.app.gambo.BackgammonCurrentStateMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammoncurrentstatemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 78, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammoncurrentstatemessageresponse = function() {
  return this.setBackgammoncurrentstatemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammoncurrentstatemessageresponse = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional BackgammonRestartMessageRequest backgammonRestartMessageRequest = 79;
 * @return {?proto.app.gambo.BackgammonRestartMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammonrestartmessagerequest = function() {
  return /** @type{?proto.app.gambo.BackgammonRestartMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonRestartMessageRequest, 79));
};


/**
 * @param {?proto.app.gambo.BackgammonRestartMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammonrestartmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 79, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammonrestartmessagerequest = function() {
  return this.setBackgammonrestartmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammonrestartmessagerequest = function() {
  return jspb.Message.getField(this, 79) != null;
};


/**
 * optional BackgammonRestartMessageResponse backgammonRestartMessageResponse = 80;
 * @return {?proto.app.gambo.BackgammonRestartMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammonrestartmessageresponse = function() {
  return /** @type{?proto.app.gambo.BackgammonRestartMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonRestartMessageResponse, 80));
};


/**
 * @param {?proto.app.gambo.BackgammonRestartMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammonrestartmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 80, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammonrestartmessageresponse = function() {
  return this.setBackgammonrestartmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammonrestartmessageresponse = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional BackgammonFinishMessageRequest backgammonFinishMessageRequest = 81;
 * @return {?proto.app.gambo.BackgammonFinishMessageRequest}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammonfinishmessagerequest = function() {
  return /** @type{?proto.app.gambo.BackgammonFinishMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonFinishMessageRequest, 81));
};


/**
 * @param {?proto.app.gambo.BackgammonFinishMessageRequest|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammonfinishmessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 81, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammonfinishmessagerequest = function() {
  return this.setBackgammonfinishmessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammonfinishmessagerequest = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional BackgammonFinishMessageResponse backgammonFinishMessageResponse = 82;
 * @return {?proto.app.gambo.BackgammonFinishMessageResponse}
 */
proto.app.gambo.GeneralMessage.prototype.getBackgammonfinishmessageresponse = function() {
  return /** @type{?proto.app.gambo.BackgammonFinishMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.BackgammonFinishMessageResponse, 82));
};


/**
 * @param {?proto.app.gambo.BackgammonFinishMessageResponse|undefined} value
 * @return {!proto.app.gambo.GeneralMessage} returns this
*/
proto.app.gambo.GeneralMessage.prototype.setBackgammonfinishmessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 82, proto.app.gambo.GeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GeneralMessage} returns this
 */
proto.app.gambo.GeneralMessage.prototype.clearBackgammonfinishmessageresponse = function() {
  return this.setBackgammonfinishmessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GeneralMessage.prototype.hasBackgammonfinishmessageresponse = function() {
  return jspb.Message.getField(this, 82) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.PingMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.PingMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.PingMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PingMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.PingMessage}
 */
proto.app.gambo.PingMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.PingMessage;
  return proto.app.gambo.PingMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.PingMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.PingMessage}
 */
proto.app.gambo.PingMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.PingMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.PingMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.PingMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PingMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.app.gambo.PingMessage.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.PingMessage} returns this
 */
proto.app.gambo.PingMessage.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.PongMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.PongMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.PongMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PongMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.PongMessage}
 */
proto.app.gambo.PongMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.PongMessage;
  return proto.app.gambo.PongMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.PongMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.PongMessage}
 */
proto.app.gambo.PongMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.PongMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.PongMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.PongMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PongMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.app.gambo.PongMessage.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.PongMessage} returns this
 */
proto.app.gambo.PongMessage.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ErrorServerMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ErrorServerMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ErrorServerMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ErrorServerMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    errormessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ErrorServerMessage}
 */
proto.app.gambo.ErrorServerMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ErrorServerMessage;
  return proto.app.gambo.ErrorServerMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ErrorServerMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ErrorServerMessage}
 */
proto.app.gambo.ErrorServerMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ErrorServerMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ErrorServerMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ErrorServerMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ErrorServerMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string errorMessage = 1;
 * @return {string}
 */
proto.app.gambo.ErrorServerMessage.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.ErrorServerMessage} returns this
 */
proto.app.gambo.ErrorServerMessage.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string errorCode = 2;
 * @return {string}
 */
proto.app.gambo.ErrorServerMessage.prototype.getErrorcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.ErrorServerMessage} returns this
 */
proto.app.gambo.ErrorServerMessage.prototype.setErrorcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ErrorClientMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ErrorClientMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ErrorClientMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ErrorClientMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    errormessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ErrorClientMessage}
 */
proto.app.gambo.ErrorClientMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ErrorClientMessage;
  return proto.app.gambo.ErrorClientMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ErrorClientMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ErrorClientMessage}
 */
proto.app.gambo.ErrorClientMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ErrorClientMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ErrorClientMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ErrorClientMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ErrorClientMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string errorMessage = 1;
 * @return {string}
 */
proto.app.gambo.ErrorClientMessage.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.ErrorClientMessage} returns this
 */
proto.app.gambo.ErrorClientMessage.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string errorCode = 2;
 * @return {string}
 */
proto.app.gambo.ErrorClientMessage.prototype.getErrorcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.ErrorClientMessage} returns this
 */
proto.app.gambo.ErrorClientMessage.prototype.setErrorcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleStartGameMessageRequest}
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleStartGameMessageRequest;
  return proto.app.gambo.SeaBattleStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleStartGameMessageRequest}
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleStartGameMessageRequest} returns this
 */
proto.app.gambo.SeaBattleStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesStartGameMessageRequest}
 */
proto.app.gambo.DominoesStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesStartGameMessageRequest;
  return proto.app.gambo.DominoesStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesStartGameMessageRequest}
 */
proto.app.gambo.DominoesStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DominoesStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesStartGameMessageRequest} returns this
 */
proto.app.gambo.DominoesStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesTile.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesTile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesTile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesTile.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rank2: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesTile}
 */
proto.app.gambo.DominoesTile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesTile;
  return proto.app.gambo.DominoesTile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesTile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesTile}
 */
proto.app.gambo.DominoesTile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesTile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesTile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesTile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesTile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank1();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRank2();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 rank1 = 1;
 * @return {number}
 */
proto.app.gambo.DominoesTile.prototype.getRank1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesTile} returns this
 */
proto.app.gambo.DominoesTile.prototype.setRank1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 rank2 = 2;
 * @return {number}
 */
proto.app.gambo.DominoesTile.prototype.getRank2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesTile} returns this
 */
proto.app.gambo.DominoesTile.prototype.setRank2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetpoint: (f = msg.getTargetpoint()) && proto.app.gambo.SeaBattlePoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleMoveMessageRequest}
 */
proto.app.gambo.SeaBattleMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleMoveMessageRequest;
  return proto.app.gambo.SeaBattleMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleMoveMessageRequest}
 */
proto.app.gambo.SeaBattleMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = new proto.app.gambo.SeaBattlePoint;
      reader.readMessage(value,proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader);
      msg.setTargetpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTargetpoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageRequest} returns this
 */
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SeaBattlePoint targetPoint = 2;
 * @return {?proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.getTargetpoint = function() {
  return /** @type{?proto.app.gambo.SeaBattlePoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePoint, 2));
};


/**
 * @param {?proto.app.gambo.SeaBattlePoint|undefined} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageRequest} returns this
*/
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.setTargetpoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.SeaBattleMoveMessageRequest} returns this
 */
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.clearTargetpoint = function() {
  return this.setTargetpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.SeaBattleMoveMessageRequest.prototype.hasTargetpoint = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.SeaBattleMoveMessageResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetpoint: (f = msg.getTargetpoint()) && proto.app.gambo.SeaBattlePoint.toObject(includeInstance, f),
    pointtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    shipedgepointsList: jspb.Message.toObjectList(msg.getShipedgepointsList(),
    proto.app.gambo.SeaBattlePoint.toObject, includeInstance),
    destroyedpointsList: jspb.Message.toObjectList(msg.getDestroyedpointsList(),
    proto.app.gambo.SeaBattlePoint.toObject, includeInstance),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    winner: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleMoveMessageResponse;
  return proto.app.gambo.SeaBattleMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.SeaBattlePoint;
      reader.readMessage(value,proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader);
      msg.setTargetpoint(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.SeaBattlePointType} */ (reader.readEnum());
      msg.setPointtype(value);
      break;
    case 3:
      var value = new proto.app.gambo.SeaBattlePoint;
      reader.readMessage(value,proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader);
      msg.addShipedgepoints(value);
      break;
    case 4:
      var value = new proto.app.gambo.SeaBattlePoint;
      reader.readMessage(value,proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader);
      msg.addDestroyedpoints(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetpoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter
    );
  }
  f = message.getPointtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getShipedgepointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter
    );
  }
  f = message.getDestroyedpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional SeaBattlePoint targetPoint = 1;
 * @return {?proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.getTargetpoint = function() {
  return /** @type{?proto.app.gambo.SeaBattlePoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePoint, 1));
};


/**
 * @param {?proto.app.gambo.SeaBattlePoint|undefined} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
*/
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.setTargetpoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.clearTargetpoint = function() {
  return this.setTargetpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.hasTargetpoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SeaBattlePointType pointType = 2;
 * @return {!proto.app.gambo.SeaBattlePointType}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.getPointtype = function() {
  return /** @type {!proto.app.gambo.SeaBattlePointType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.SeaBattlePointType} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.setPointtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated SeaBattlePoint shipEdgePoints = 3;
 * @return {!Array<!proto.app.gambo.SeaBattlePoint>}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.getShipedgepointsList = function() {
  return /** @type{!Array<!proto.app.gambo.SeaBattlePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.SeaBattlePoint, 3));
};


/**
 * @param {!Array<!proto.app.gambo.SeaBattlePoint>} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
*/
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.setShipedgepointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.SeaBattlePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.addShipedgepoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.SeaBattlePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.clearShipedgepointsList = function() {
  return this.setShipedgepointsList([]);
};


/**
 * repeated SeaBattlePoint destroyedPoints = 4;
 * @return {!Array<!proto.app.gambo.SeaBattlePoint>}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.getDestroyedpointsList = function() {
  return /** @type{!Array<!proto.app.gambo.SeaBattlePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.SeaBattlePoint, 4));
};


/**
 * @param {!Array<!proto.app.gambo.SeaBattlePoint>} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
*/
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.setDestroyedpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.gambo.SeaBattlePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.addDestroyedpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.gambo.SeaBattlePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.clearDestroyedpointsList = function() {
  return this.setDestroyedpointsList([]);
};


/**
 * optional bool gameFinished = 5;
 * @return {boolean}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 winner = 6;
 * @return {number}
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleMoveMessageResponse} returns this
 */
proto.app.gambo.SeaBattleMoveMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattlePlaceShipsMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shipsList: jspb.Message.toObjectList(msg.getShipsList(),
    proto.app.gambo.SeaBattleShip.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattlePlaceShipsMessageRequest;
  return proto.app.gambo.SeaBattlePlaceShipsMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = new proto.app.gambo.SeaBattleShip;
      reader.readMessage(value,proto.app.gambo.SeaBattleShip.deserializeBinaryFromReader);
      msg.addShips(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattlePlaceShipsMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.app.gambo.SeaBattleShip.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest} returns this
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SeaBattleShip ships = 2;
 * @return {!Array<!proto.app.gambo.SeaBattleShip>}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.getShipsList = function() {
  return /** @type{!Array<!proto.app.gambo.SeaBattleShip>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.SeaBattleShip, 2));
};


/**
 * @param {!Array<!proto.app.gambo.SeaBattleShip>} value
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest} returns this
*/
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.setShipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.app.gambo.SeaBattleShip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.SeaBattleShip}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.addShips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.app.gambo.SeaBattleShip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageRequest} returns this
 */
proto.app.gambo.SeaBattlePlaceShipsMessageRequest.prototype.clearShipsList = function() {
  return this.setShipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattlePlaceShipsMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattlePlaceShipsMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageResponse}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattlePlaceShipsMessageResponse;
  return proto.app.gambo.SeaBattlePlaceShipsMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattlePlaceShipsMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageResponse}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattlePlaceShipsMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattlePlaceShipsMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.SeaBattlePlaceShipsMessageResponse} returns this
 */
proto.app.gambo.SeaBattlePlaceShipsMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattlePlayersReadyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattlePlayersReadyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattlePlayersReadyResponse}
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattlePlayersReadyResponse;
  return proto.app.gambo.SeaBattlePlayersReadyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattlePlayersReadyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattlePlayersReadyResponse}
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattlePlayersReadyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattlePlayersReadyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.SeaBattlePlayersReadyResponse} returns this
 */
proto.app.gambo.SeaBattlePlayersReadyResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageRequest}
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleCurrentStateMessageRequest;
  return proto.app.gambo.SeaBattleCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageRequest}
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageRequest} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.repeatedFields_ = [1,2,3,4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipsList: jspb.Message.toObjectList(msg.getShipsList(),
    proto.app.gambo.SeaBattleShip.toObject, includeInstance),
    enemydestroyedshipsList: jspb.Message.toObjectList(msg.getEnemydestroyedshipsList(),
    proto.app.gambo.SeaBattleShip.toObject, includeInstance),
    playertypedpointsList: jspb.Message.toObjectList(msg.getPlayertypedpointsList(),
    proto.app.gambo.SeaBattleTypedPoint.toObject, includeInstance),
    enemytypedpointsList: jspb.Message.toObjectList(msg.getEnemytypedpointsList(),
    proto.app.gambo.SeaBattleTypedPoint.toObject, includeInstance),
    playernumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 8, 0),
    playersready: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    winner: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleCurrentStateMessageResponse;
  return proto.app.gambo.SeaBattleCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.SeaBattleShip;
      reader.readMessage(value,proto.app.gambo.SeaBattleShip.deserializeBinaryFromReader);
      msg.addShips(value);
      break;
    case 2:
      var value = new proto.app.gambo.SeaBattleShip;
      reader.readMessage(value,proto.app.gambo.SeaBattleShip.deserializeBinaryFromReader);
      msg.addEnemydestroyedships(value);
      break;
    case 3:
      var value = new proto.app.gambo.SeaBattleTypedPoint;
      reader.readMessage(value,proto.app.gambo.SeaBattleTypedPoint.deserializeBinaryFromReader);
      msg.addPlayertypedpoints(value);
      break;
    case 4:
      var value = new proto.app.gambo.SeaBattleTypedPoint;
      reader.readMessage(value,proto.app.gambo.SeaBattleTypedPoint.deserializeBinaryFromReader);
      msg.addEnemytypedpoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 7:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlayersready(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.app.gambo.SeaBattleShip.serializeBinaryToWriter
    );
  }
  f = message.getEnemydestroyedshipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.app.gambo.SeaBattleShip.serializeBinaryToWriter
    );
  }
  f = message.getPlayertypedpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.SeaBattleTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getEnemytypedpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.gambo.SeaBattleTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPlayersready();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * repeated SeaBattleShip ships = 1;
 * @return {!Array<!proto.app.gambo.SeaBattleShip>}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getShipsList = function() {
  return /** @type{!Array<!proto.app.gambo.SeaBattleShip>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.SeaBattleShip, 1));
};


/**
 * @param {!Array<!proto.app.gambo.SeaBattleShip>} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
*/
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setShipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.app.gambo.SeaBattleShip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.SeaBattleShip}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.addShips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.app.gambo.SeaBattleShip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.clearShipsList = function() {
  return this.setShipsList([]);
};


/**
 * repeated SeaBattleShip enemyDestroyedShips = 2;
 * @return {!Array<!proto.app.gambo.SeaBattleShip>}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getEnemydestroyedshipsList = function() {
  return /** @type{!Array<!proto.app.gambo.SeaBattleShip>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.SeaBattleShip, 2));
};


/**
 * @param {!Array<!proto.app.gambo.SeaBattleShip>} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
*/
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setEnemydestroyedshipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.app.gambo.SeaBattleShip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.SeaBattleShip}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.addEnemydestroyedships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.app.gambo.SeaBattleShip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.clearEnemydestroyedshipsList = function() {
  return this.setEnemydestroyedshipsList([]);
};


/**
 * repeated SeaBattleTypedPoint playerTypedPoints = 3;
 * @return {!Array<!proto.app.gambo.SeaBattleTypedPoint>}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getPlayertypedpointsList = function() {
  return /** @type{!Array<!proto.app.gambo.SeaBattleTypedPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.SeaBattleTypedPoint, 3));
};


/**
 * @param {!Array<!proto.app.gambo.SeaBattleTypedPoint>} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
*/
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setPlayertypedpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.SeaBattleTypedPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.SeaBattleTypedPoint}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.addPlayertypedpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.SeaBattleTypedPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.clearPlayertypedpointsList = function() {
  return this.setPlayertypedpointsList([]);
};


/**
 * repeated SeaBattleTypedPoint enemyTypedPoints = 4;
 * @return {!Array<!proto.app.gambo.SeaBattleTypedPoint>}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getEnemytypedpointsList = function() {
  return /** @type{!Array<!proto.app.gambo.SeaBattleTypedPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.SeaBattleTypedPoint, 4));
};


/**
 * @param {!Array<!proto.app.gambo.SeaBattleTypedPoint>} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
*/
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setEnemytypedpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.gambo.SeaBattleTypedPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.SeaBattleTypedPoint}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.addEnemytypedpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.gambo.SeaBattleTypedPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.clearEnemytypedpointsList = function() {
  return this.setEnemytypedpointsList([]);
};


/**
 * optional int32 playerNumber = 5;
 * @return {number}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 playerCount = 6;
 * @return {number}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated GameUserInfo players = 7;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 7));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
*/
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * optional int32 movePlayer = 8;
 * @return {number}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool playersReady = 9;
 * @return {boolean}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getPlayersready = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setPlayersready = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool gameFinished = 10;
 * @return {boolean}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 winner = 11;
 * @return {number}
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleCurrentStateMessageResponse} returns this
 */
proto.app.gambo.SeaBattleCurrentStateMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleTypedPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleTypedPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleTypedPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleTypedPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && proto.app.gambo.SeaBattlePoint.toObject(includeInstance, f),
    pointtype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleTypedPoint}
 */
proto.app.gambo.SeaBattleTypedPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleTypedPoint;
  return proto.app.gambo.SeaBattleTypedPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleTypedPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleTypedPoint}
 */
proto.app.gambo.SeaBattleTypedPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.SeaBattlePoint;
      reader.readMessage(value,proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.SeaBattlePointType} */ (reader.readEnum());
      msg.setPointtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleTypedPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleTypedPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleTypedPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleTypedPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter
    );
  }
  f = message.getPointtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SeaBattlePoint point = 1;
 * @return {?proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattleTypedPoint.prototype.getPoint = function() {
  return /** @type{?proto.app.gambo.SeaBattlePoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePoint, 1));
};


/**
 * @param {?proto.app.gambo.SeaBattlePoint|undefined} value
 * @return {!proto.app.gambo.SeaBattleTypedPoint} returns this
*/
proto.app.gambo.SeaBattleTypedPoint.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.SeaBattleTypedPoint} returns this
 */
proto.app.gambo.SeaBattleTypedPoint.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.SeaBattleTypedPoint.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SeaBattlePointType pointType = 2;
 * @return {!proto.app.gambo.SeaBattlePointType}
 */
proto.app.gambo.SeaBattleTypedPoint.prototype.getPointtype = function() {
  return /** @type {!proto.app.gambo.SeaBattlePointType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.SeaBattlePointType} value
 * @return {!proto.app.gambo.SeaBattleTypedPoint} returns this
 */
proto.app.gambo.SeaBattleTypedPoint.prototype.setPointtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleShip.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleShip.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleShip} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleShip.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startpoint: (f = msg.getStartpoint()) && proto.app.gambo.SeaBattlePoint.toObject(includeInstance, f),
    endpoint: (f = msg.getEndpoint()) && proto.app.gambo.SeaBattlePoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleShip}
 */
proto.app.gambo.SeaBattleShip.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleShip;
  return proto.app.gambo.SeaBattleShip.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleShip} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleShip}
 */
proto.app.gambo.SeaBattleShip.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 2:
      var value = new proto.app.gambo.SeaBattlePoint;
      reader.readMessage(value,proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader);
      msg.setStartpoint(value);
      break;
    case 3:
      var value = new proto.app.gambo.SeaBattlePoint;
      reader.readMessage(value,proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader);
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleShip.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleShip.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleShip} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleShip.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartpoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter
    );
  }
  f = message.getEndpoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 size = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattleShip.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleShip} returns this
 */
proto.app.gambo.SeaBattleShip.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SeaBattlePoint startPoint = 2;
 * @return {?proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattleShip.prototype.getStartpoint = function() {
  return /** @type{?proto.app.gambo.SeaBattlePoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePoint, 2));
};


/**
 * @param {?proto.app.gambo.SeaBattlePoint|undefined} value
 * @return {!proto.app.gambo.SeaBattleShip} returns this
*/
proto.app.gambo.SeaBattleShip.prototype.setStartpoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.SeaBattleShip} returns this
 */
proto.app.gambo.SeaBattleShip.prototype.clearStartpoint = function() {
  return this.setStartpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.SeaBattleShip.prototype.hasStartpoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SeaBattlePoint endPoint = 3;
 * @return {?proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattleShip.prototype.getEndpoint = function() {
  return /** @type{?proto.app.gambo.SeaBattlePoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.SeaBattlePoint, 3));
};


/**
 * @param {?proto.app.gambo.SeaBattlePoint|undefined} value
 * @return {!proto.app.gambo.SeaBattleShip} returns this
*/
proto.app.gambo.SeaBattleShip.prototype.setEndpoint = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.SeaBattleShip} returns this
 */
proto.app.gambo.SeaBattleShip.prototype.clearEndpoint = function() {
  return this.setEndpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.SeaBattleShip.prototype.hasEndpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattlePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattlePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattlePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinate: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattlePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattlePoint;
  return proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattlePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattlePoint}
 */
proto.app.gambo.SeaBattlePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoordinate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattlePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattlePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattlePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 coordinate = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattlePoint.prototype.getCoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattlePoint} returns this
 */
proto.app.gambo.SeaBattlePoint.prototype.setCoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tile: (f = msg.getTile()) && proto.app.gambo.DominoesTile.toObject(includeInstance, f),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0),
    side: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesMoveMessageRequest}
 */
proto.app.gambo.DominoesMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesMoveMessageRequest;
  return proto.app.gambo.DominoesMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesMoveMessageRequest}
 */
proto.app.gambo.DominoesMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.DominoesMoveAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = new proto.app.gambo.DominoesTile;
      reader.readMessage(value,proto.app.gambo.DominoesTile.deserializeBinaryFromReader);
      msg.setTile(value);
      break;
    case 4:
      var value = /** @type {!proto.app.gambo.DominoesTileDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 5:
      var value = /** @type {!proto.app.gambo.DominoesMoveSide} */ (reader.readEnum());
      msg.setSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.DominoesTile.serializeBinaryToWriter
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesMoveMessageRequest} returns this
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DominoesMoveAction action = 2;
 * @return {!proto.app.gambo.DominoesMoveAction}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.getAction = function() {
  return /** @type {!proto.app.gambo.DominoesMoveAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.DominoesMoveAction} value
 * @return {!proto.app.gambo.DominoesMoveMessageRequest} returns this
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional DominoesTile tile = 3;
 * @return {?proto.app.gambo.DominoesTile}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.getTile = function() {
  return /** @type{?proto.app.gambo.DominoesTile} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesTile, 3));
};


/**
 * @param {?proto.app.gambo.DominoesTile|undefined} value
 * @return {!proto.app.gambo.DominoesMoveMessageRequest} returns this
*/
proto.app.gambo.DominoesMoveMessageRequest.prototype.setTile = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.DominoesMoveMessageRequest} returns this
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.clearTile = function() {
  return this.setTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.hasTile = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DominoesTileDirection direction = 4;
 * @return {!proto.app.gambo.DominoesTileDirection}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.getDirection = function() {
  return /** @type {!proto.app.gambo.DominoesTileDirection} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.app.gambo.DominoesTileDirection} value
 * @return {!proto.app.gambo.DominoesMoveMessageRequest} returns this
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional DominoesMoveSide side = 5;
 * @return {!proto.app.gambo.DominoesMoveSide}
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.getSide = function() {
  return /** @type {!proto.app.gambo.DominoesMoveSide} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.app.gambo.DominoesMoveSide} value
 * @return {!proto.app.gambo.DominoesMoveMessageRequest} returns this
 */
proto.app.gambo.DominoesMoveMessageRequest.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromplayer: jspb.Message.getFieldWithDefault(msg, 1, 0),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 2, 0),
    action: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tile: (f = msg.getTile()) && proto.app.gambo.DominoesTile.toObject(includeInstance, f),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    side: jspb.Message.getFieldWithDefault(msg, 6, 0),
    remainingtiles: jspb.Message.getFieldWithDefault(msg, 7, 0),
    headrank: jspb.Message.getFieldWithDefault(msg, 8, 0),
    tailrank: jspb.Message.getFieldWithDefault(msg, 9, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    winner: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesMoveMessageResponse}
 */
proto.app.gambo.DominoesMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesMoveMessageResponse;
  return proto.app.gambo.DominoesMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesMoveMessageResponse}
 */
proto.app.gambo.DominoesMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromplayer(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 3:
      var value = /** @type {!proto.app.gambo.DominoesMoveAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 4:
      var value = new proto.app.gambo.DominoesTile;
      reader.readMessage(value,proto.app.gambo.DominoesTile.deserializeBinaryFromReader);
      msg.setTile(value);
      break;
    case 5:
      var value = /** @type {!proto.app.gambo.DominoesTileDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {!proto.app.gambo.DominoesMoveSide} */ (reader.readEnum());
      msg.setSide(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingtiles(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeadrank(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTailrank(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromplayer();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTile();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.gambo.DominoesTile.serializeBinaryToWriter
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRemainingtiles();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHeadrank();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTailrank();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional int32 fromPlayer = 1;
 * @return {number}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getFromplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setFromplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 movePlayer = 2;
 * @return {number}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DominoesMoveAction action = 3;
 * @return {!proto.app.gambo.DominoesMoveAction}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getAction = function() {
  return /** @type {!proto.app.gambo.DominoesMoveAction} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.app.gambo.DominoesMoveAction} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DominoesTile tile = 4;
 * @return {?proto.app.gambo.DominoesTile}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getTile = function() {
  return /** @type{?proto.app.gambo.DominoesTile} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DominoesTile, 4));
};


/**
 * @param {?proto.app.gambo.DominoesTile|undefined} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
*/
proto.app.gambo.DominoesMoveMessageResponse.prototype.setTile = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.clearTile = function() {
  return this.setTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.hasTile = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DominoesTileDirection direction = 5;
 * @return {!proto.app.gambo.DominoesTileDirection}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getDirection = function() {
  return /** @type {!proto.app.gambo.DominoesTileDirection} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.app.gambo.DominoesTileDirection} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional DominoesMoveSide side = 6;
 * @return {!proto.app.gambo.DominoesMoveSide}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getSide = function() {
  return /** @type {!proto.app.gambo.DominoesMoveSide} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.app.gambo.DominoesMoveSide} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 remainingTiles = 7;
 * @return {number}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getRemainingtiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setRemainingtiles = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 headRank = 8;
 * @return {number}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getHeadrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setHeadrank = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 tailRank = 9;
 * @return {number}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getTailrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setTailrank = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool gameFinished = 10;
 * @return {boolean}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 winner = 11;
 * @return {number}
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesMoveMessageResponse} returns this
 */
proto.app.gambo.DominoesMoveMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageRequest}
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesCurrentStateMessageRequest;
  return proto.app.gambo.DominoesCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageRequest}
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageRequest} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.repeatedFields_ = [3,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 4, 0),
    playerstilecountList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    playertilesList: jspb.Message.toObjectList(msg.getPlayertilesList(),
    proto.app.gambo.DominoesTile.toObject, includeInstance),
    tabletilesList: jspb.Message.toObjectList(msg.getTabletilesList(),
    proto.app.gambo.DominoesTableTile.toObject, includeInstance),
    maintileindex: jspb.Message.getFieldWithDefault(msg, 8, 0),
    headrank: jspb.Message.getFieldWithDefault(msg, 9, 0),
    tailrank: jspb.Message.getFieldWithDefault(msg, 10, 0),
    remainingtiles: jspb.Message.getFieldWithDefault(msg, 11, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    winner: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesCurrentStateMessageResponse;
  return proto.app.gambo.DominoesCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlayerstilecount(values[i]);
      }
      break;
    case 6:
      var value = new proto.app.gambo.DominoesTile;
      reader.readMessage(value,proto.app.gambo.DominoesTile.deserializeBinaryFromReader);
      msg.addPlayertiles(value);
      break;
    case 7:
      var value = new proto.app.gambo.DominoesTableTile;
      reader.readMessage(value,proto.app.gambo.DominoesTableTile.deserializeBinaryFromReader);
      msg.addTabletiles(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaintileindex(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeadrank(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTailrank(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingtiles(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPlayerstilecountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getPlayertilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.app.gambo.DominoesTile.serializeBinaryToWriter
    );
  }
  f = message.getTabletilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.app.gambo.DominoesTableTile.serializeBinaryToWriter
    );
  }
  f = message.getMaintileindex();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getHeadrank();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTailrank();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getRemainingtiles();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
*/
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * optional int32 movePlayer = 4;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated int32 playersTileCount = 5;
 * @return {!Array<number>}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getPlayerstilecountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setPlayerstilecountList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.addPlayerstilecount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.clearPlayerstilecountList = function() {
  return this.setPlayerstilecountList([]);
};


/**
 * repeated DominoesTile playerTiles = 6;
 * @return {!Array<!proto.app.gambo.DominoesTile>}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getPlayertilesList = function() {
  return /** @type{!Array<!proto.app.gambo.DominoesTile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.DominoesTile, 6));
};


/**
 * @param {!Array<!proto.app.gambo.DominoesTile>} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
*/
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setPlayertilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.app.gambo.DominoesTile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DominoesTile}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.addPlayertiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.app.gambo.DominoesTile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.clearPlayertilesList = function() {
  return this.setPlayertilesList([]);
};


/**
 * repeated DominoesTableTile tableTiles = 7;
 * @return {!Array<!proto.app.gambo.DominoesTableTile>}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getTabletilesList = function() {
  return /** @type{!Array<!proto.app.gambo.DominoesTableTile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.DominoesTableTile, 7));
};


/**
 * @param {!Array<!proto.app.gambo.DominoesTableTile>} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
*/
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setTabletilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.app.gambo.DominoesTableTile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DominoesTableTile}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.addTabletiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.app.gambo.DominoesTableTile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.clearTabletilesList = function() {
  return this.setTabletilesList([]);
};


/**
 * optional int32 mainTileIndex = 8;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getMaintileindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setMaintileindex = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 headRank = 9;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getHeadrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setHeadrank = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 tailRank = 10;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getTailrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setTailrank = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 remainingTiles = 11;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getRemainingtiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setRemainingtiles = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool gameFinished = 12;
 * @return {boolean}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 winner = 13;
 * @return {number}
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DominoesCurrentStateMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesTableTile.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesTableTile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesTableTile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesTableTile.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankprevious: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ranknext: jspb.Message.getFieldWithDefault(msg, 2, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesTableTile}
 */
proto.app.gambo.DominoesTableTile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesTableTile;
  return proto.app.gambo.DominoesTableTile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesTableTile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesTableTile}
 */
proto.app.gambo.DominoesTableTile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRankprevious(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRanknext(value);
      break;
    case 3:
      var value = /** @type {!proto.app.gambo.DominoesTileDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesTableTile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesTableTile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesTableTile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesTableTile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankprevious();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRanknext();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 rankPrevious = 1;
 * @return {number}
 */
proto.app.gambo.DominoesTableTile.prototype.getRankprevious = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesTableTile} returns this
 */
proto.app.gambo.DominoesTableTile.prototype.setRankprevious = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 rankNext = 2;
 * @return {number}
 */
proto.app.gambo.DominoesTableTile.prototype.getRanknext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesTableTile} returns this
 */
proto.app.gambo.DominoesTableTile.prototype.setRanknext = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DominoesTileDirection direction = 3;
 * @return {!proto.app.gambo.DominoesTileDirection}
 */
proto.app.gambo.DominoesTableTile.prototype.getDirection = function() {
  return /** @type {!proto.app.gambo.DominoesTileDirection} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.app.gambo.DominoesTileDirection} value
 * @return {!proto.app.gambo.DominoesTableTile} returns this
 */
proto.app.gambo.DominoesTableTile.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesRestartMessageRequest}
 */
proto.app.gambo.DominoesRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesRestartMessageRequest;
  return proto.app.gambo.DominoesRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesRestartMessageRequest}
 */
proto.app.gambo.DominoesRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DominoesRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesRestartMessageRequest} returns this
 */
proto.app.gambo.DominoesRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesRestartMessageResponse}
 */
proto.app.gambo.DominoesRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesRestartMessageResponse;
  return proto.app.gambo.DominoesRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesRestartMessageResponse}
 */
proto.app.gambo.DominoesRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.DominoesRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DominoesRestartMessageResponse} returns this
 */
proto.app.gambo.DominoesRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesFinishMessageRequest}
 */
proto.app.gambo.DominoesFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesFinishMessageRequest;
  return proto.app.gambo.DominoesFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesFinishMessageRequest}
 */
proto.app.gambo.DominoesFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DominoesFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DominoesFinishMessageRequest} returns this
 */
proto.app.gambo.DominoesFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DominoesFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DominoesFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DominoesFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DominoesFinishMessageResponse}
 */
proto.app.gambo.DominoesFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DominoesFinishMessageResponse;
  return proto.app.gambo.DominoesFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DominoesFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DominoesFinishMessageResponse}
 */
proto.app.gambo.DominoesFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DominoesFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DominoesFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DominoesFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DominoesFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.DominoesFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DominoesFinishMessageResponse} returns this
 */
proto.app.gambo.DominoesFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleRestartMessageRequest}
 */
proto.app.gambo.SeaBattleRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleRestartMessageRequest;
  return proto.app.gambo.SeaBattleRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleRestartMessageRequest}
 */
proto.app.gambo.SeaBattleRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattleRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleRestartMessageRequest} returns this
 */
proto.app.gambo.SeaBattleRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleRestartMessageResponse}
 */
proto.app.gambo.SeaBattleRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleRestartMessageResponse;
  return proto.app.gambo.SeaBattleRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleRestartMessageResponse}
 */
proto.app.gambo.SeaBattleRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.SeaBattleRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.SeaBattleRestartMessageResponse} returns this
 */
proto.app.gambo.SeaBattleRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleFinishMessageRequest}
 */
proto.app.gambo.SeaBattleFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleFinishMessageRequest;
  return proto.app.gambo.SeaBattleFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleFinishMessageRequest}
 */
proto.app.gambo.SeaBattleFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.SeaBattleFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.SeaBattleFinishMessageRequest} returns this
 */
proto.app.gambo.SeaBattleFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.SeaBattleFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.SeaBattleFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.SeaBattleFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.SeaBattleFinishMessageResponse}
 */
proto.app.gambo.SeaBattleFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.SeaBattleFinishMessageResponse;
  return proto.app.gambo.SeaBattleFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.SeaBattleFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.SeaBattleFinishMessageResponse}
 */
proto.app.gambo.SeaBattleFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.SeaBattleFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.SeaBattleFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.SeaBattleFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.SeaBattleFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.SeaBattleFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.SeaBattleFinishMessageResponse} returns this
 */
proto.app.gambo.SeaBattleFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GameUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GameUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GameUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GameUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nickname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatarurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.GameUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GameUserInfo;
  return proto.app.gambo.GameUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GameUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.GameUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GameUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GameUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GameUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GameUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatarurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.GameUserInfo.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GameUserInfo} returns this
 */
proto.app.gambo.GameUserInfo.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string nickname = 2;
 * @return {string}
 */
proto.app.gambo.GameUserInfo.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.GameUserInfo} returns this
 */
proto.app.gambo.GameUserInfo.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string avatarUrl = 3;
 * @return {string}
 */
proto.app.gambo.GameUserInfo.prototype.getAvatarurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.GameUserInfo} returns this
 */
proto.app.gambo.GameUserInfo.prototype.setAvatarurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonStartGameMessageRequest}
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonStartGameMessageRequest;
  return proto.app.gambo.QuizButtonStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonStartGameMessageRequest}
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonStartGameMessageRequest} returns this
 */
proto.app.gambo.QuizButtonStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    number: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonMoveMessageRequest}
 */
proto.app.gambo.QuizButtonMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonMoveMessageRequest;
  return proto.app.gambo.QuizButtonMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonMoveMessageRequest}
 */
proto.app.gambo.QuizButtonMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.QuizButtonAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageRequest} returns this
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional QuizButtonAction action = 2;
 * @return {!proto.app.gambo.QuizButtonAction}
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.getAction = function() {
  return /** @type {!proto.app.gambo.QuizButtonAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.QuizButtonAction} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageRequest} returns this
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 number = 3;
 * @return {number}
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageRequest} returns this
 */
proto.app.gambo.QuizButtonMoveMessageRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.QuizButtonMoveMessageResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoresList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    blockedplayermovesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    buttonlocked: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    winner: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonMoveMessageResponse;
  return proto.app.gambo.QuizButtonMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScores(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBlockedplayermoves(values[i]);
      }
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setButtonlocked(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getBlockedplayermovesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getButtonlocked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * repeated int32 scores = 1;
 * @return {!Array<number>}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.getScoresList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.setScoresList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.addScores = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};


/**
 * repeated int32 blockedPlayerMoves = 2;
 * @return {!Array<number>}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.getBlockedplayermovesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.setBlockedplayermovesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.addBlockedplayermoves = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.clearBlockedplayermovesList = function() {
  return this.setBlockedplayermovesList([]);
};


/**
 * optional bool buttonLocked = 3;
 * @return {boolean}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.getButtonlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.setButtonlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 winner = 4;
 * @return {number}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool gameFinished = 5;
 * @return {boolean}
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizButtonMoveMessageResponse} returns this
 */
proto.app.gambo.QuizButtonMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageRequest}
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonCurrentStateMessageRequest;
  return proto.app.gambo.QuizButtonCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageRequest}
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageRequest} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.repeatedFields_ = [3,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    hostusernumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    scoresList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    blockedplayermovesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    buttonlocked: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    winner: jspb.Message.getFieldWithDefault(msg, 8, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonCurrentStateMessageResponse;
  return proto.app.gambo.QuizButtonCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHostusernumber(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScores(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBlockedplayermoves(values[i]);
      }
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setButtonlocked(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getHostusernumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getBlockedplayermovesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getButtonlocked();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
*/
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * optional int32 hostUserNumber = 4;
 * @return {number}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getHostusernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setHostusernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated int32 scores = 5;
 * @return {!Array<number>}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getScoresList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setScoresList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.addScores = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};


/**
 * repeated int32 blockedPlayerMoves = 6;
 * @return {!Array<number>}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getBlockedplayermovesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setBlockedplayermovesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.addBlockedplayermoves = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.clearBlockedplayermovesList = function() {
  return this.setBlockedplayermovesList([]);
};


/**
 * optional bool buttonLocked = 7;
 * @return {boolean}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getButtonlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setButtonlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 winner = 8;
 * @return {number}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool gameFinished = 9;
 * @return {boolean}
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizButtonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizButtonCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonRestartMessageRequest}
 */
proto.app.gambo.QuizButtonRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonRestartMessageRequest;
  return proto.app.gambo.QuizButtonRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonRestartMessageRequest}
 */
proto.app.gambo.QuizButtonRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizButtonRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonRestartMessageRequest} returns this
 */
proto.app.gambo.QuizButtonRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonRestartMessageResponse}
 */
proto.app.gambo.QuizButtonRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonRestartMessageResponse;
  return proto.app.gambo.QuizButtonRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonRestartMessageResponse}
 */
proto.app.gambo.QuizButtonRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.QuizButtonRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizButtonRestartMessageResponse} returns this
 */
proto.app.gambo.QuizButtonRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonFinishMessageRequest}
 */
proto.app.gambo.QuizButtonFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonFinishMessageRequest;
  return proto.app.gambo.QuizButtonFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonFinishMessageRequest}
 */
proto.app.gambo.QuizButtonFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizButtonFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizButtonFinishMessageRequest} returns this
 */
proto.app.gambo.QuizButtonFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizButtonFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizButtonFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizButtonFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizButtonFinishMessageResponse}
 */
proto.app.gambo.QuizButtonFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizButtonFinishMessageResponse;
  return proto.app.gambo.QuizButtonFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizButtonFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizButtonFinishMessageResponse}
 */
proto.app.gambo.QuizButtonFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizButtonFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizButtonFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizButtonFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizButtonFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.QuizButtonFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizButtonFinishMessageResponse} returns this
 */
proto.app.gambo.QuizButtonFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersStartGameMessageRequest}
 */
proto.app.gambo.CheckersStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersStartGameMessageRequest;
  return proto.app.gambo.CheckersStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersStartGameMessageRequest}
 */
proto.app.gambo.CheckersStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.CheckersStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersStartGameMessageRequest} returns this
 */
proto.app.gambo.CheckersStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinate: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersPoint;
  return proto.app.gambo.CheckersPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoordinate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 coordinate = 1;
 * @return {number}
 */
proto.app.gambo.CheckersPoint.prototype.getCoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersPoint} returns this
 */
proto.app.gambo.CheckersPoint.prototype.setCoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersTypedPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersTypedPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersTypedPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersTypedPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && proto.app.gambo.CheckersPoint.toObject(includeInstance, f),
    pointcolor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pointstate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersTypedPoint}
 */
proto.app.gambo.CheckersTypedPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersTypedPoint;
  return proto.app.gambo.CheckersTypedPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersTypedPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersTypedPoint}
 */
proto.app.gambo.CheckersTypedPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.CheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersPoint.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.CheckersPointColor} */ (reader.readEnum());
      msg.setPointcolor(value);
      break;
    case 3:
      var value = /** @type {!proto.app.gambo.CheckersPointState} */ (reader.readEnum());
      msg.setPointstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersTypedPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersTypedPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersTypedPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersTypedPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.CheckersPoint.serializeBinaryToWriter
    );
  }
  f = message.getPointcolor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPointstate();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional CheckersPoint point = 1;
 * @return {?proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersTypedPoint.prototype.getPoint = function() {
  return /** @type{?proto.app.gambo.CheckersPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersPoint, 1));
};


/**
 * @param {?proto.app.gambo.CheckersPoint|undefined} value
 * @return {!proto.app.gambo.CheckersTypedPoint} returns this
*/
proto.app.gambo.CheckersTypedPoint.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.CheckersTypedPoint} returns this
 */
proto.app.gambo.CheckersTypedPoint.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.CheckersTypedPoint.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CheckersPointColor pointColor = 2;
 * @return {!proto.app.gambo.CheckersPointColor}
 */
proto.app.gambo.CheckersTypedPoint.prototype.getPointcolor = function() {
  return /** @type {!proto.app.gambo.CheckersPointColor} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.CheckersPointColor} value
 * @return {!proto.app.gambo.CheckersTypedPoint} returns this
 */
proto.app.gambo.CheckersTypedPoint.prototype.setPointcolor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional CheckersPointState pointState = 3;
 * @return {!proto.app.gambo.CheckersPointState}
 */
proto.app.gambo.CheckersTypedPoint.prototype.getPointstate = function() {
  return /** @type {!proto.app.gambo.CheckersPointState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.app.gambo.CheckersPointState} value
 * @return {!proto.app.gambo.CheckersTypedPoint} returns this
 */
proto.app.gambo.CheckersTypedPoint.prototype.setPointstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    initialpoint: (f = msg.getInitialpoint()) && proto.app.gambo.CheckersPoint.toObject(includeInstance, f),
    targetpoint: (f = msg.getTargetpoint()) && proto.app.gambo.CheckersPoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersMoveMessageRequest}
 */
proto.app.gambo.CheckersMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersMoveMessageRequest;
  return proto.app.gambo.CheckersMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersMoveMessageRequest}
 */
proto.app.gambo.CheckersMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = new proto.app.gambo.CheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersPoint.deserializeBinaryFromReader);
      msg.setInitialpoint(value);
      break;
    case 3:
      var value = new proto.app.gambo.CheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersPoint.deserializeBinaryFromReader);
      msg.setTargetpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInitialpoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.gambo.CheckersPoint.serializeBinaryToWriter
    );
  }
  f = message.getTargetpoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.CheckersPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersMoveMessageRequest} returns this
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CheckersPoint initialPoint = 2;
 * @return {?proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.getInitialpoint = function() {
  return /** @type{?proto.app.gambo.CheckersPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersPoint, 2));
};


/**
 * @param {?proto.app.gambo.CheckersPoint|undefined} value
 * @return {!proto.app.gambo.CheckersMoveMessageRequest} returns this
*/
proto.app.gambo.CheckersMoveMessageRequest.prototype.setInitialpoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.CheckersMoveMessageRequest} returns this
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.clearInitialpoint = function() {
  return this.setInitialpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.hasInitialpoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CheckersPoint targetPoint = 3;
 * @return {?proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.getTargetpoint = function() {
  return /** @type{?proto.app.gambo.CheckersPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersPoint, 3));
};


/**
 * @param {?proto.app.gambo.CheckersPoint|undefined} value
 * @return {!proto.app.gambo.CheckersMoveMessageRequest} returns this
*/
proto.app.gambo.CheckersMoveMessageRequest.prototype.setTargetpoint = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.CheckersMoveMessageRequest} returns this
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.clearTargetpoint = function() {
  return this.setTargetpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.CheckersMoveMessageRequest.prototype.hasTargetpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.CheckersMoveMessageResponse.repeatedFields_ = [1,2,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    removedcheckersList: jspb.Message.toObjectList(msg.getRemovedcheckersList(),
    proto.app.gambo.CheckersPoint.toObject, includeInstance),
    previousmovesList: jspb.Message.toObjectList(msg.getPreviousmovesList(),
    proto.app.gambo.CheckersPoint.toObject, includeInstance),
    finishpoint: (f = msg.getFinishpoint()) && proto.app.gambo.CheckersTypedPoint.toObject(includeInstance, f),
    changedstate: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    winner: jspb.Message.getFieldWithDefault(msg, 7, 0),
    possiblecapturesList: jspb.Message.toObjectList(msg.getPossiblecapturesList(),
    proto.app.gambo.CheckersListOfCheckersPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersMoveMessageResponse}
 */
proto.app.gambo.CheckersMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersMoveMessageResponse;
  return proto.app.gambo.CheckersMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersMoveMessageResponse}
 */
proto.app.gambo.CheckersMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.CheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersPoint.deserializeBinaryFromReader);
      msg.addRemovedcheckers(value);
      break;
    case 2:
      var value = new proto.app.gambo.CheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersPoint.deserializeBinaryFromReader);
      msg.addPreviousmoves(value);
      break;
    case 3:
      var value = new proto.app.gambo.CheckersTypedPoint;
      reader.readMessage(value,proto.app.gambo.CheckersTypedPoint.deserializeBinaryFromReader);
      msg.setFinishpoint(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChangedstate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    case 8:
      var value = new proto.app.gambo.CheckersListOfCheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersListOfCheckersPoint.deserializeBinaryFromReader);
      msg.addPossiblecaptures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemovedcheckersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.app.gambo.CheckersPoint.serializeBinaryToWriter
    );
  }
  f = message.getPreviousmovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.app.gambo.CheckersPoint.serializeBinaryToWriter
    );
  }
  f = message.getFinishpoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.CheckersTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getChangedstate();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPossiblecapturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.app.gambo.CheckersListOfCheckersPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CheckersPoint removedCheckers = 1;
 * @return {!Array<!proto.app.gambo.CheckersPoint>}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getRemovedcheckersList = function() {
  return /** @type{!Array<!proto.app.gambo.CheckersPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.CheckersPoint, 1));
};


/**
 * @param {!Array<!proto.app.gambo.CheckersPoint>} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
*/
proto.app.gambo.CheckersMoveMessageResponse.prototype.setRemovedcheckersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.app.gambo.CheckersPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.addRemovedcheckers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.app.gambo.CheckersPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.clearRemovedcheckersList = function() {
  return this.setRemovedcheckersList([]);
};


/**
 * repeated CheckersPoint previousMoves = 2;
 * @return {!Array<!proto.app.gambo.CheckersPoint>}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getPreviousmovesList = function() {
  return /** @type{!Array<!proto.app.gambo.CheckersPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.CheckersPoint, 2));
};


/**
 * @param {!Array<!proto.app.gambo.CheckersPoint>} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
*/
proto.app.gambo.CheckersMoveMessageResponse.prototype.setPreviousmovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.app.gambo.CheckersPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.addPreviousmoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.app.gambo.CheckersPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.clearPreviousmovesList = function() {
  return this.setPreviousmovesList([]);
};


/**
 * optional CheckersTypedPoint finishPoint = 3;
 * @return {?proto.app.gambo.CheckersTypedPoint}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getFinishpoint = function() {
  return /** @type{?proto.app.gambo.CheckersTypedPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CheckersTypedPoint, 3));
};


/**
 * @param {?proto.app.gambo.CheckersTypedPoint|undefined} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
*/
proto.app.gambo.CheckersMoveMessageResponse.prototype.setFinishpoint = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.clearFinishpoint = function() {
  return this.setFinishpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.hasFinishpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool changedState = 4;
 * @return {boolean}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getChangedstate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.setChangedstate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 movePlayer = 5;
 * @return {number}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool gameFinished = 6;
 * @return {boolean}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 winner = 7;
 * @return {number}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated CheckersListOfCheckersPoint possibleCaptures = 8;
 * @return {!Array<!proto.app.gambo.CheckersListOfCheckersPoint>}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.getPossiblecapturesList = function() {
  return /** @type{!Array<!proto.app.gambo.CheckersListOfCheckersPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.CheckersListOfCheckersPoint, 8));
};


/**
 * @param {!Array<!proto.app.gambo.CheckersListOfCheckersPoint>} value
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
*/
proto.app.gambo.CheckersMoveMessageResponse.prototype.setPossiblecapturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.app.gambo.CheckersListOfCheckersPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.CheckersListOfCheckersPoint}
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.addPossiblecaptures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.app.gambo.CheckersListOfCheckersPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersMoveMessageResponse} returns this
 */
proto.app.gambo.CheckersMoveMessageResponse.prototype.clearPossiblecapturesList = function() {
  return this.setPossiblecapturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageRequest}
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersCurrentStateMessageRequest;
  return proto.app.gambo.CheckersCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageRequest}
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageRequest} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.repeatedFields_ = [3,4,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    checkersmapList: jspb.Message.toObjectList(msg.getCheckersmapList(),
    proto.app.gambo.CheckersTypedPoint.toObject, includeInstance),
    whitecheckers: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 6, 0),
    possiblecapturesList: jspb.Message.toObjectList(msg.getPossiblecapturesList(),
    proto.app.gambo.CheckersListOfCheckersPoint.toObject, includeInstance),
    previousmovesList: jspb.Message.toObjectList(msg.getPreviousmovesList(),
    proto.app.gambo.CheckersPoint.toObject, includeInstance),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    winner: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersCurrentStateMessageResponse;
  return proto.app.gambo.CheckersCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = new proto.app.gambo.CheckersTypedPoint;
      reader.readMessage(value,proto.app.gambo.CheckersTypedPoint.deserializeBinaryFromReader);
      msg.addCheckersmap(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhitecheckers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 7:
      var value = new proto.app.gambo.CheckersListOfCheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersListOfCheckersPoint.deserializeBinaryFromReader);
      msg.addPossiblecaptures(value);
      break;
    case 8:
      var value = new proto.app.gambo.CheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersPoint.deserializeBinaryFromReader);
      msg.addPreviousmoves(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getCheckersmapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.gambo.CheckersTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getWhitecheckers();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPossiblecapturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.app.gambo.CheckersListOfCheckersPoint.serializeBinaryToWriter
    );
  }
  f = message.getPreviousmovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.app.gambo.CheckersPoint.serializeBinaryToWriter
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
*/
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * repeated CheckersTypedPoint checkersMap = 4;
 * @return {!Array<!proto.app.gambo.CheckersTypedPoint>}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getCheckersmapList = function() {
  return /** @type{!Array<!proto.app.gambo.CheckersTypedPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.CheckersTypedPoint, 4));
};


/**
 * @param {!Array<!proto.app.gambo.CheckersTypedPoint>} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
*/
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setCheckersmapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.gambo.CheckersTypedPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.CheckersTypedPoint}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.addCheckersmap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.gambo.CheckersTypedPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.clearCheckersmapList = function() {
  return this.setCheckersmapList([]);
};


/**
 * optional bool whiteCheckers = 5;
 * @return {boolean}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getWhitecheckers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setWhitecheckers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 movePlayer = 6;
 * @return {number}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated CheckersListOfCheckersPoint possibleCaptures = 7;
 * @return {!Array<!proto.app.gambo.CheckersListOfCheckersPoint>}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getPossiblecapturesList = function() {
  return /** @type{!Array<!proto.app.gambo.CheckersListOfCheckersPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.CheckersListOfCheckersPoint, 7));
};


/**
 * @param {!Array<!proto.app.gambo.CheckersListOfCheckersPoint>} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
*/
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setPossiblecapturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.app.gambo.CheckersListOfCheckersPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.CheckersListOfCheckersPoint}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.addPossiblecaptures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.app.gambo.CheckersListOfCheckersPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.clearPossiblecapturesList = function() {
  return this.setPossiblecapturesList([]);
};


/**
 * repeated CheckersPoint previousMoves = 8;
 * @return {!Array<!proto.app.gambo.CheckersPoint>}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getPreviousmovesList = function() {
  return /** @type{!Array<!proto.app.gambo.CheckersPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.CheckersPoint, 8));
};


/**
 * @param {!Array<!proto.app.gambo.CheckersPoint>} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
*/
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setPreviousmovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.app.gambo.CheckersPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.addPreviousmoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.app.gambo.CheckersPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.clearPreviousmovesList = function() {
  return this.setPreviousmovesList([]);
};


/**
 * optional bool gameFinished = 9;
 * @return {boolean}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 winner = 10;
 * @return {number}
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersCurrentStateMessageResponse} returns this
 */
proto.app.gambo.CheckersCurrentStateMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersRestartMessageRequest}
 */
proto.app.gambo.CheckersRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersRestartMessageRequest;
  return proto.app.gambo.CheckersRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersRestartMessageRequest}
 */
proto.app.gambo.CheckersRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.CheckersRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersRestartMessageRequest} returns this
 */
proto.app.gambo.CheckersRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersRestartMessageResponse}
 */
proto.app.gambo.CheckersRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersRestartMessageResponse;
  return proto.app.gambo.CheckersRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersRestartMessageResponse}
 */
proto.app.gambo.CheckersRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.CheckersRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CheckersRestartMessageResponse} returns this
 */
proto.app.gambo.CheckersRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersFinishMessageRequest}
 */
proto.app.gambo.CheckersFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersFinishMessageRequest;
  return proto.app.gambo.CheckersFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersFinishMessageRequest}
 */
proto.app.gambo.CheckersFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.CheckersFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CheckersFinishMessageRequest} returns this
 */
proto.app.gambo.CheckersFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersFinishMessageResponse}
 */
proto.app.gambo.CheckersFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersFinishMessageResponse;
  return proto.app.gambo.CheckersFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersFinishMessageResponse}
 */
proto.app.gambo.CheckersFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.CheckersFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CheckersFinishMessageResponse} returns this
 */
proto.app.gambo.CheckersFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.CheckersListOfCheckersPoint.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CheckersListOfCheckersPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CheckersListOfCheckersPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CheckersListOfCheckersPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersListOfCheckersPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.app.gambo.CheckersPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CheckersListOfCheckersPoint}
 */
proto.app.gambo.CheckersListOfCheckersPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CheckersListOfCheckersPoint;
  return proto.app.gambo.CheckersListOfCheckersPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CheckersListOfCheckersPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CheckersListOfCheckersPoint}
 */
proto.app.gambo.CheckersListOfCheckersPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.CheckersPoint;
      reader.readMessage(value,proto.app.gambo.CheckersPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CheckersListOfCheckersPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CheckersListOfCheckersPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CheckersListOfCheckersPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CheckersListOfCheckersPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.app.gambo.CheckersPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CheckersPoint points = 1;
 * @return {!Array<!proto.app.gambo.CheckersPoint>}
 */
proto.app.gambo.CheckersListOfCheckersPoint.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.app.gambo.CheckersPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.CheckersPoint, 1));
};


/**
 * @param {!Array<!proto.app.gambo.CheckersPoint>} value
 * @return {!proto.app.gambo.CheckersListOfCheckersPoint} returns this
*/
proto.app.gambo.CheckersListOfCheckersPoint.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.app.gambo.CheckersPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.CheckersPoint}
 */
proto.app.gambo.CheckersListOfCheckersPoint.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.app.gambo.CheckersPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CheckersListOfCheckersPoint} returns this
 */
proto.app.gambo.CheckersListOfCheckersPoint.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakStartGameMessageRequest}
 */
proto.app.gambo.DurakStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakStartGameMessageRequest;
  return proto.app.gambo.DurakStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakStartGameMessageRequest}
 */
proto.app.gambo.DurakStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DurakStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakStartGameMessageRequest} returns this
 */
proto.app.gambo.DurakStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    card: (f = msg.getCard()) && proto.app.gambo.DurakCard.toObject(includeInstance, f),
    beatencard: (f = msg.getBeatencard()) && proto.app.gambo.DurakCard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakMoveMessageRequest}
 */
proto.app.gambo.DurakMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakMoveMessageRequest;
  return proto.app.gambo.DurakMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakMoveMessageRequest}
 */
proto.app.gambo.DurakMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.DurakMoveAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 4:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.setBeatencard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
  f = message.getBeatencard();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakMoveMessageRequest} returns this
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DurakMoveAction action = 2;
 * @return {!proto.app.gambo.DurakMoveAction}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.getAction = function() {
  return /** @type {!proto.app.gambo.DurakMoveAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.DurakMoveAction} value
 * @return {!proto.app.gambo.DurakMoveMessageRequest} returns this
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional DurakCard card = 3;
 * @return {?proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.getCard = function() {
  return /** @type{?proto.app.gambo.DurakCard} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCard, 3));
};


/**
 * @param {?proto.app.gambo.DurakCard|undefined} value
 * @return {!proto.app.gambo.DurakMoveMessageRequest} returns this
*/
proto.app.gambo.DurakMoveMessageRequest.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.DurakMoveMessageRequest} returns this
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.hasCard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DurakCard beatenCard = 4;
 * @return {?proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.getBeatencard = function() {
  return /** @type{?proto.app.gambo.DurakCard} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCard, 4));
};


/**
 * @param {?proto.app.gambo.DurakCard|undefined} value
 * @return {!proto.app.gambo.DurakMoveMessageRequest} returns this
*/
proto.app.gambo.DurakMoveMessageRequest.prototype.setBeatencard = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.DurakMoveMessageRequest} returns this
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.clearBeatencard = function() {
  return this.setBeatencard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.DurakMoveMessageRequest.prototype.hasBeatencard = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.DurakMoveMessageResponse.repeatedFields_ = [3,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromplayer: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersallowedactionList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    card: (f = msg.getCard()) && proto.app.gambo.DurakCard.toObject(includeInstance, f),
    beatencard: (f = msg.getBeatencard()) && proto.app.gambo.DurakCard.toObject(includeInstance, f),
    playercardsList: jspb.Message.toObjectList(msg.getPlayercardsList(),
    proto.app.gambo.DurakCard.toObject, includeInstance),
    playerscardcountList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    remainingcards: jspb.Message.getFieldWithDefault(msg, 8, 0),
    fold: jspb.Message.getFieldWithDefault(msg, 9, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    loser: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakMoveMessageResponse}
 */
proto.app.gambo.DurakMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakMoveMessageResponse;
  return proto.app.gambo.DurakMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakMoveMessageResponse}
 */
proto.app.gambo.DurakMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromplayer(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.DurakMoveAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.app.gambo.DurakAllowedAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlayersallowedaction(values[i]);
      }
      break;
    case 4:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 5:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.setBeatencard(value);
      break;
    case 6:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.addPlayercards(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlayerscardcount(values[i]);
      }
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingcards(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFold(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromplayer();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPlayersallowedactionList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
  f = message.getBeatencard();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
  f = message.getPlayercardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
  f = message.getPlayerscardcountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getRemainingcards();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getFold();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getLoser();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional int32 fromPlayer = 1;
 * @return {number}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getFromplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setFromplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DurakMoveAction action = 2;
 * @return {!proto.app.gambo.DurakMoveAction}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getAction = function() {
  return /** @type {!proto.app.gambo.DurakMoveAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.DurakMoveAction} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated DurakAllowedAction playersAllowedAction = 3;
 * @return {!Array<!proto.app.gambo.DurakAllowedAction>}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getPlayersallowedactionList = function() {
  return /** @type {!Array<!proto.app.gambo.DurakAllowedAction>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.app.gambo.DurakAllowedAction>} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setPlayersallowedactionList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.app.gambo.DurakAllowedAction} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.addPlayersallowedaction = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.clearPlayersallowedactionList = function() {
  return this.setPlayersallowedactionList([]);
};


/**
 * optional DurakCard card = 4;
 * @return {?proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getCard = function() {
  return /** @type{?proto.app.gambo.DurakCard} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCard, 4));
};


/**
 * @param {?proto.app.gambo.DurakCard|undefined} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
*/
proto.app.gambo.DurakMoveMessageResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DurakCard beatenCard = 5;
 * @return {?proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getBeatencard = function() {
  return /** @type{?proto.app.gambo.DurakCard} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCard, 5));
};


/**
 * @param {?proto.app.gambo.DurakCard|undefined} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
*/
proto.app.gambo.DurakMoveMessageResponse.prototype.setBeatencard = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.clearBeatencard = function() {
  return this.setBeatencard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.hasBeatencard = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated DurakCard playerCards = 6;
 * @return {!Array<!proto.app.gambo.DurakCard>}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getPlayercardsList = function() {
  return /** @type{!Array<!proto.app.gambo.DurakCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.DurakCard, 6));
};


/**
 * @param {!Array<!proto.app.gambo.DurakCard>} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
*/
proto.app.gambo.DurakMoveMessageResponse.prototype.setPlayercardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.app.gambo.DurakCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.addPlayercards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.app.gambo.DurakCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.clearPlayercardsList = function() {
  return this.setPlayercardsList([]);
};


/**
 * repeated int32 playersCardCount = 7;
 * @return {!Array<number>}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getPlayerscardcountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setPlayerscardcountList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.addPlayerscardcount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.clearPlayerscardcountList = function() {
  return this.setPlayerscardcountList([]);
};


/**
 * optional int32 remainingCards = 8;
 * @return {number}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getRemainingcards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setRemainingcards = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 fold = 9;
 * @return {number}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getFold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setFold = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool gameFinished = 10;
 * @return {boolean}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 loser = 11;
 * @return {number}
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.getLoser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakMoveMessageResponse} returns this
 */
proto.app.gambo.DurakMoveMessageResponse.prototype.setLoser = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakCurrentStateMessageRequest}
 */
proto.app.gambo.DurakCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakCurrentStateMessageRequest;
  return proto.app.gambo.DurakCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakCurrentStateMessageRequest}
 */
proto.app.gambo.DurakCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DurakCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageRequest} returns this
 */
proto.app.gambo.DurakCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.DurakCurrentStateMessageResponse.repeatedFields_ = [3,4,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    playercardsList: jspb.Message.toObjectList(msg.getPlayercardsList(),
    proto.app.gambo.DurakCard.toObject, includeInstance),
    trumpcard: (f = msg.getTrumpcard()) && proto.app.gambo.DurakCard.toObject(includeInstance, f),
    playersallowedactionList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    playerscardcountList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    attackandbeatingcardsList: jspb.Message.toObjectList(msg.getAttackandbeatingcardsList(),
    proto.app.gambo.PairOfDurakCards.toObject, includeInstance),
    attackplayernumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    takingcards: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    remainingcards: jspb.Message.getFieldWithDefault(msg, 11, 0),
    fold: jspb.Message.getFieldWithDefault(msg, 12, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    loser: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakCurrentStateMessageResponse;
  return proto.app.gambo.DurakCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.addPlayercards(value);
      break;
    case 5:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.setTrumpcard(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.app.gambo.DurakAllowedAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlayersallowedaction(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlayerscardcount(values[i]);
      }
      break;
    case 8:
      var value = new proto.app.gambo.PairOfDurakCards;
      reader.readMessage(value,proto.app.gambo.PairOfDurakCards.deserializeBinaryFromReader);
      msg.addAttackandbeatingcards(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttackplayernumber(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTakingcards(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingcards(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFold(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getPlayercardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
  f = message.getTrumpcard();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
  f = message.getPlayersallowedactionList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getPlayerscardcountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getAttackandbeatingcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.app.gambo.PairOfDurakCards.serializeBinaryToWriter
    );
  }
  f = message.getAttackplayernumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTakingcards();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getRemainingcards();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getFold();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLoser();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
*/
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * repeated DurakCard playerCards = 4;
 * @return {!Array<!proto.app.gambo.DurakCard>}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getPlayercardsList = function() {
  return /** @type{!Array<!proto.app.gambo.DurakCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.DurakCard, 4));
};


/**
 * @param {!Array<!proto.app.gambo.DurakCard>} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
*/
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setPlayercardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.gambo.DurakCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.addPlayercards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.gambo.DurakCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.clearPlayercardsList = function() {
  return this.setPlayercardsList([]);
};


/**
 * optional DurakCard trumpCard = 5;
 * @return {?proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getTrumpcard = function() {
  return /** @type{?proto.app.gambo.DurakCard} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCard, 5));
};


/**
 * @param {?proto.app.gambo.DurakCard|undefined} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
*/
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setTrumpcard = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.clearTrumpcard = function() {
  return this.setTrumpcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.hasTrumpcard = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated DurakAllowedAction playersAllowedAction = 6;
 * @return {!Array<!proto.app.gambo.DurakAllowedAction>}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getPlayersallowedactionList = function() {
  return /** @type {!Array<!proto.app.gambo.DurakAllowedAction>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.app.gambo.DurakAllowedAction>} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setPlayersallowedactionList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.app.gambo.DurakAllowedAction} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.addPlayersallowedaction = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.clearPlayersallowedactionList = function() {
  return this.setPlayersallowedactionList([]);
};


/**
 * repeated int32 playersCardCount = 7;
 * @return {!Array<number>}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getPlayerscardcountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setPlayerscardcountList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.addPlayerscardcount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.clearPlayerscardcountList = function() {
  return this.setPlayerscardcountList([]);
};


/**
 * repeated PairOfDurakCards attackAndBeatingCards = 8;
 * @return {!Array<!proto.app.gambo.PairOfDurakCards>}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getAttackandbeatingcardsList = function() {
  return /** @type{!Array<!proto.app.gambo.PairOfDurakCards>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.PairOfDurakCards, 8));
};


/**
 * @param {!Array<!proto.app.gambo.PairOfDurakCards>} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
*/
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setAttackandbeatingcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.app.gambo.PairOfDurakCards=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.PairOfDurakCards}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.addAttackandbeatingcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.app.gambo.PairOfDurakCards, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.clearAttackandbeatingcardsList = function() {
  return this.setAttackandbeatingcardsList([]);
};


/**
 * optional int32 attackPlayerNumber = 9;
 * @return {number}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getAttackplayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setAttackplayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool takingCards = 10;
 * @return {boolean}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getTakingcards = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setTakingcards = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 remainingCards = 11;
 * @return {number}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getRemainingcards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setRemainingcards = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 fold = 12;
 * @return {number}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getFold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setFold = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool gameFinished = 13;
 * @return {boolean}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 loser = 14;
 * @return {number}
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.getLoser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCurrentStateMessageResponse} returns this
 */
proto.app.gambo.DurakCurrentStateMessageResponse.prototype.setLoser = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakRestartMessageRequest}
 */
proto.app.gambo.DurakRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakRestartMessageRequest;
  return proto.app.gambo.DurakRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakRestartMessageRequest}
 */
proto.app.gambo.DurakRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DurakRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakRestartMessageRequest} returns this
 */
proto.app.gambo.DurakRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakRestartMessageResponse}
 */
proto.app.gambo.DurakRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakRestartMessageResponse;
  return proto.app.gambo.DurakRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakRestartMessageResponse}
 */
proto.app.gambo.DurakRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.DurakRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DurakRestartMessageResponse} returns this
 */
proto.app.gambo.DurakRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakFinishMessageRequest}
 */
proto.app.gambo.DurakFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakFinishMessageRequest;
  return proto.app.gambo.DurakFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakFinishMessageRequest}
 */
proto.app.gambo.DurakFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.DurakFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakFinishMessageRequest} returns this
 */
proto.app.gambo.DurakFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakFinishMessageResponse}
 */
proto.app.gambo.DurakFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakFinishMessageResponse;
  return proto.app.gambo.DurakFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakFinishMessageResponse}
 */
proto.app.gambo.DurakFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.DurakFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.DurakFinishMessageResponse} returns this
 */
proto.app.gambo.DurakFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.PairOfDurakCards.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.PairOfDurakCards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.PairOfDurakCards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PairOfDurakCards.toObject = function(includeInstance, msg) {
  var f, obj = {
    card1: (f = msg.getCard1()) && proto.app.gambo.DurakCard.toObject(includeInstance, f),
    card2: (f = msg.getCard2()) && proto.app.gambo.DurakCard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.PairOfDurakCards}
 */
proto.app.gambo.PairOfDurakCards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.PairOfDurakCards;
  return proto.app.gambo.PairOfDurakCards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.PairOfDurakCards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.PairOfDurakCards}
 */
proto.app.gambo.PairOfDurakCards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.setCard1(value);
      break;
    case 2:
      var value = new proto.app.gambo.DurakCard;
      reader.readMessage(value,proto.app.gambo.DurakCard.deserializeBinaryFromReader);
      msg.setCard2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.PairOfDurakCards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.PairOfDurakCards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.PairOfDurakCards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PairOfDurakCards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
  f = message.getCard2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.gambo.DurakCard.serializeBinaryToWriter
    );
  }
};


/**
 * optional DurakCard card1 = 1;
 * @return {?proto.app.gambo.DurakCard}
 */
proto.app.gambo.PairOfDurakCards.prototype.getCard1 = function() {
  return /** @type{?proto.app.gambo.DurakCard} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCard, 1));
};


/**
 * @param {?proto.app.gambo.DurakCard|undefined} value
 * @return {!proto.app.gambo.PairOfDurakCards} returns this
*/
proto.app.gambo.PairOfDurakCards.prototype.setCard1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.PairOfDurakCards} returns this
 */
proto.app.gambo.PairOfDurakCards.prototype.clearCard1 = function() {
  return this.setCard1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.PairOfDurakCards.prototype.hasCard1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DurakCard card2 = 2;
 * @return {?proto.app.gambo.DurakCard}
 */
proto.app.gambo.PairOfDurakCards.prototype.getCard2 = function() {
  return /** @type{?proto.app.gambo.DurakCard} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.DurakCard, 2));
};


/**
 * @param {?proto.app.gambo.DurakCard|undefined} value
 * @return {!proto.app.gambo.PairOfDurakCards} returns this
*/
proto.app.gambo.PairOfDurakCards.prototype.setCard2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.PairOfDurakCards} returns this
 */
proto.app.gambo.PairOfDurakCards.prototype.clearCard2 = function() {
  return this.setCard2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.PairOfDurakCards.prototype.hasCard2 = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.DurakCard.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.DurakCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.DurakCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    suit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.DurakCard;
  return proto.app.gambo.DurakCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.DurakCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.DurakCard}
 */
proto.app.gambo.DurakCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.DurakCardSuit} */ (reader.readEnum());
      msg.setSuit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.DurakCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.DurakCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.DurakCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.DurakCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSuit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 rank = 1;
 * @return {number}
 */
proto.app.gambo.DurakCard.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.DurakCard} returns this
 */
proto.app.gambo.DurakCard.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DurakCardSuit suit = 2;
 * @return {!proto.app.gambo.DurakCardSuit}
 */
proto.app.gambo.DurakCard.prototype.getSuit = function() {
  return /** @type {!proto.app.gambo.DurakCardSuit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.DurakCardSuit} value
 * @return {!proto.app.gambo.DurakCard} returns this
 */
proto.app.gambo.DurakCard.prototype.setSuit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessStartGameMessageRequest}
 */
proto.app.gambo.ChessStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessStartGameMessageRequest;
  return proto.app.gambo.ChessStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessStartGameMessageRequest}
 */
proto.app.gambo.ChessStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.ChessStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessStartGameMessageRequest} returns this
 */
proto.app.gambo.ChessStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinate: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessPoint;
  return proto.app.gambo.ChessPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoordinate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 coordinate = 1;
 * @return {number}
 */
proto.app.gambo.ChessPoint.prototype.getCoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessPoint} returns this
 */
proto.app.gambo.ChessPoint.prototype.setCoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessTypedPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessTypedPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessTypedPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessTypedPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && proto.app.gambo.ChessPoint.toObject(includeInstance, f),
    pointcolor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pointstate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessTypedPoint}
 */
proto.app.gambo.ChessTypedPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessTypedPoint;
  return proto.app.gambo.ChessTypedPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessTypedPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessTypedPoint}
 */
proto.app.gambo.ChessTypedPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.ChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessPoint.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.ChessPointColor} */ (reader.readEnum());
      msg.setPointcolor(value);
      break;
    case 3:
      var value = /** @type {!proto.app.gambo.ChessPointState} */ (reader.readEnum());
      msg.setPointstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessTypedPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessTypedPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessTypedPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessTypedPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.ChessPoint.serializeBinaryToWriter
    );
  }
  f = message.getPointcolor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPointstate();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional ChessPoint point = 1;
 * @return {?proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessTypedPoint.prototype.getPoint = function() {
  return /** @type{?proto.app.gambo.ChessPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessPoint, 1));
};


/**
 * @param {?proto.app.gambo.ChessPoint|undefined} value
 * @return {!proto.app.gambo.ChessTypedPoint} returns this
*/
proto.app.gambo.ChessTypedPoint.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.ChessTypedPoint} returns this
 */
proto.app.gambo.ChessTypedPoint.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.ChessTypedPoint.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChessPointColor pointColor = 2;
 * @return {!proto.app.gambo.ChessPointColor}
 */
proto.app.gambo.ChessTypedPoint.prototype.getPointcolor = function() {
  return /** @type {!proto.app.gambo.ChessPointColor} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.ChessPointColor} value
 * @return {!proto.app.gambo.ChessTypedPoint} returns this
 */
proto.app.gambo.ChessTypedPoint.prototype.setPointcolor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ChessPointState pointState = 3;
 * @return {!proto.app.gambo.ChessPointState}
 */
proto.app.gambo.ChessTypedPoint.prototype.getPointstate = function() {
  return /** @type {!proto.app.gambo.ChessPointState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.app.gambo.ChessPointState} value
 * @return {!proto.app.gambo.ChessTypedPoint} returns this
 */
proto.app.gambo.ChessTypedPoint.prototype.setPointstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    movetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    initialpoint: (f = msg.getInitialpoint()) && proto.app.gambo.ChessPoint.toObject(includeInstance, f),
    targetpoint: (f = msg.getTargetpoint()) && proto.app.gambo.ChessPoint.toObject(includeInstance, f),
    pawnreplacement: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessMoveMessageRequest}
 */
proto.app.gambo.ChessMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessMoveMessageRequest;
  return proto.app.gambo.ChessMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessMoveMessageRequest}
 */
proto.app.gambo.ChessMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.ChessMoveType} */ (reader.readEnum());
      msg.setMovetype(value);
      break;
    case 3:
      var value = new proto.app.gambo.ChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessPoint.deserializeBinaryFromReader);
      msg.setInitialpoint(value);
      break;
    case 4:
      var value = new proto.app.gambo.ChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessPoint.deserializeBinaryFromReader);
      msg.setTargetpoint(value);
      break;
    case 5:
      var value = /** @type {!proto.app.gambo.ChessPointState} */ (reader.readEnum());
      msg.setPawnreplacement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInitialpoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.ChessPoint.serializeBinaryToWriter
    );
  }
  f = message.getTargetpoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.gambo.ChessPoint.serializeBinaryToWriter
    );
  }
  f = message.getPawnreplacement();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessMoveMessageRequest} returns this
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ChessMoveType moveType = 2;
 * @return {!proto.app.gambo.ChessMoveType}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.getMovetype = function() {
  return /** @type {!proto.app.gambo.ChessMoveType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.ChessMoveType} value
 * @return {!proto.app.gambo.ChessMoveMessageRequest} returns this
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.setMovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ChessPoint initialPoint = 3;
 * @return {?proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.getInitialpoint = function() {
  return /** @type{?proto.app.gambo.ChessPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessPoint, 3));
};


/**
 * @param {?proto.app.gambo.ChessPoint|undefined} value
 * @return {!proto.app.gambo.ChessMoveMessageRequest} returns this
*/
proto.app.gambo.ChessMoveMessageRequest.prototype.setInitialpoint = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.ChessMoveMessageRequest} returns this
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.clearInitialpoint = function() {
  return this.setInitialpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.hasInitialpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ChessPoint targetPoint = 4;
 * @return {?proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.getTargetpoint = function() {
  return /** @type{?proto.app.gambo.ChessPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessPoint, 4));
};


/**
 * @param {?proto.app.gambo.ChessPoint|undefined} value
 * @return {!proto.app.gambo.ChessMoveMessageRequest} returns this
*/
proto.app.gambo.ChessMoveMessageRequest.prototype.setTargetpoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.ChessMoveMessageRequest} returns this
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.clearTargetpoint = function() {
  return this.setTargetpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.hasTargetpoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ChessPointState pawnReplacement = 5;
 * @return {!proto.app.gambo.ChessPointState}
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.getPawnreplacement = function() {
  return /** @type {!proto.app.gambo.ChessPointState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.app.gambo.ChessPointState} value
 * @return {!proto.app.gambo.ChessMoveMessageRequest} returns this
 */
proto.app.gambo.ChessMoveMessageRequest.prototype.setPawnreplacement = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.ChessMoveMessageResponse.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    movetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    initialpoint: (f = msg.getInitialpoint()) && proto.app.gambo.ChessPoint.toObject(includeInstance, f),
    targetpoint: (f = msg.getTargetpoint()) && proto.app.gambo.ChessPoint.toObject(includeInstance, f),
    removedpoint: (f = msg.getRemovedpoint()) && proto.app.gambo.ChessTypedPoint.toObject(includeInstance, f),
    pawnreplacement: jspb.Message.getFieldWithDefault(msg, 5, 0),
    shouldchangestate: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    check: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 8, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    winner: jspb.Message.getFieldWithDefault(msg, 10, 0),
    possiblemovesList: jspb.Message.toObjectList(msg.getPossiblemovesList(),
    proto.app.gambo.ChessListOfChessPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessMoveMessageResponse}
 */
proto.app.gambo.ChessMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessMoveMessageResponse;
  return proto.app.gambo.ChessMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessMoveMessageResponse}
 */
proto.app.gambo.ChessMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.gambo.ChessMoveType} */ (reader.readEnum());
      msg.setMovetype(value);
      break;
    case 2:
      var value = new proto.app.gambo.ChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessPoint.deserializeBinaryFromReader);
      msg.setInitialpoint(value);
      break;
    case 3:
      var value = new proto.app.gambo.ChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessPoint.deserializeBinaryFromReader);
      msg.setTargetpoint(value);
      break;
    case 4:
      var value = new proto.app.gambo.ChessTypedPoint;
      reader.readMessage(value,proto.app.gambo.ChessTypedPoint.deserializeBinaryFromReader);
      msg.setRemovedpoint(value);
      break;
    case 5:
      var value = /** @type {!proto.app.gambo.ChessPointState} */ (reader.readEnum());
      msg.setPawnreplacement(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldchangestate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheck(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    case 11:
      var value = new proto.app.gambo.ChessListOfChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessListOfChessPoint.deserializeBinaryFromReader);
      msg.addPossiblemoves(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getInitialpoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.gambo.ChessPoint.serializeBinaryToWriter
    );
  }
  f = message.getTargetpoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.ChessPoint.serializeBinaryToWriter
    );
  }
  f = message.getRemovedpoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.gambo.ChessTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getPawnreplacement();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getShouldchangestate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCheck();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPossiblemovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.app.gambo.ChessListOfChessPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChessMoveType moveType = 1;
 * @return {!proto.app.gambo.ChessMoveType}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getMovetype = function() {
  return /** @type {!proto.app.gambo.ChessMoveType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.gambo.ChessMoveType} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.setMovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ChessPoint initialPoint = 2;
 * @return {?proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getInitialpoint = function() {
  return /** @type{?proto.app.gambo.ChessPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessPoint, 2));
};


/**
 * @param {?proto.app.gambo.ChessPoint|undefined} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
*/
proto.app.gambo.ChessMoveMessageResponse.prototype.setInitialpoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.clearInitialpoint = function() {
  return this.setInitialpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.hasInitialpoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ChessPoint targetPoint = 3;
 * @return {?proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getTargetpoint = function() {
  return /** @type{?proto.app.gambo.ChessPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessPoint, 3));
};


/**
 * @param {?proto.app.gambo.ChessPoint|undefined} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
*/
proto.app.gambo.ChessMoveMessageResponse.prototype.setTargetpoint = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.clearTargetpoint = function() {
  return this.setTargetpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.hasTargetpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ChessTypedPoint removedPoint = 4;
 * @return {?proto.app.gambo.ChessTypedPoint}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getRemovedpoint = function() {
  return /** @type{?proto.app.gambo.ChessTypedPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.ChessTypedPoint, 4));
};


/**
 * @param {?proto.app.gambo.ChessTypedPoint|undefined} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
*/
proto.app.gambo.ChessMoveMessageResponse.prototype.setRemovedpoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.clearRemovedpoint = function() {
  return this.setRemovedpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.hasRemovedpoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ChessPointState pawnReplacement = 5;
 * @return {!proto.app.gambo.ChessPointState}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getPawnreplacement = function() {
  return /** @type {!proto.app.gambo.ChessPointState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.app.gambo.ChessPointState} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.setPawnreplacement = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool shouldChangeState = 6;
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getShouldchangestate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.setShouldchangestate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool check = 7;
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.setCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 movePlayer = 8;
 * @return {number}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool gameFinished = 9;
 * @return {boolean}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 winner = 10;
 * @return {number}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated ChessListOfChessPoint possibleMoves = 11;
 * @return {!Array<!proto.app.gambo.ChessListOfChessPoint>}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.getPossiblemovesList = function() {
  return /** @type{!Array<!proto.app.gambo.ChessListOfChessPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.ChessListOfChessPoint, 11));
};


/**
 * @param {!Array<!proto.app.gambo.ChessListOfChessPoint>} value
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
*/
proto.app.gambo.ChessMoveMessageResponse.prototype.setPossiblemovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.app.gambo.ChessListOfChessPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.ChessListOfChessPoint}
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.addPossiblemoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.app.gambo.ChessListOfChessPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.ChessMoveMessageResponse} returns this
 */
proto.app.gambo.ChessMoveMessageResponse.prototype.clearPossiblemovesList = function() {
  return this.setPossiblemovesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessCurrentStateMessageRequest}
 */
proto.app.gambo.ChessCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessCurrentStateMessageRequest;
  return proto.app.gambo.ChessCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessCurrentStateMessageRequest}
 */
proto.app.gambo.ChessCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.ChessCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageRequest} returns this
 */
proto.app.gambo.ChessCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.ChessCurrentStateMessageResponse.repeatedFields_ = [3,4,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    chessmapList: jspb.Message.toObjectList(msg.getChessmapList(),
    proto.app.gambo.ChessTypedPoint.toObject, includeInstance),
    whitechess: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 6, 0),
    check: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    lastmove: jspb.Message.getFieldWithDefault(msg, 8, 0),
    lastmoveplayer: jspb.Message.getFieldWithDefault(msg, 9, 0),
    possiblemovesList: jspb.Message.toObjectList(msg.getPossiblemovesList(),
    proto.app.gambo.ChessListOfChessPoint.toObject, includeInstance),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    winner: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessCurrentStateMessageResponse;
  return proto.app.gambo.ChessCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = new proto.app.gambo.ChessTypedPoint;
      reader.readMessage(value,proto.app.gambo.ChessTypedPoint.deserializeBinaryFromReader);
      msg.addChessmap(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhitechess(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheck(value);
      break;
    case 8:
      var value = /** @type {!proto.app.gambo.ChessMoveType} */ (reader.readEnum());
      msg.setLastmove(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastmoveplayer(value);
      break;
    case 10:
      var value = new proto.app.gambo.ChessListOfChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessListOfChessPoint.deserializeBinaryFromReader);
      msg.addPossiblemoves(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getChessmapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.gambo.ChessTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getWhitechess();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCheck();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLastmove();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getLastmoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPossiblemovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.app.gambo.ChessListOfChessPoint.serializeBinaryToWriter
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
*/
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * repeated ChessTypedPoint chessMap = 4;
 * @return {!Array<!proto.app.gambo.ChessTypedPoint>}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getChessmapList = function() {
  return /** @type{!Array<!proto.app.gambo.ChessTypedPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.ChessTypedPoint, 4));
};


/**
 * @param {!Array<!proto.app.gambo.ChessTypedPoint>} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
*/
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setChessmapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.gambo.ChessTypedPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.ChessTypedPoint}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.addChessmap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.gambo.ChessTypedPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.clearChessmapList = function() {
  return this.setChessmapList([]);
};


/**
 * optional bool whiteChess = 5;
 * @return {boolean}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getWhitechess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setWhitechess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 movePlayer = 6;
 * @return {number}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool check = 7;
 * @return {boolean}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional ChessMoveType lastMove = 8;
 * @return {!proto.app.gambo.ChessMoveType}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getLastmove = function() {
  return /** @type {!proto.app.gambo.ChessMoveType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.app.gambo.ChessMoveType} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setLastmove = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 lastMovePlayer = 9;
 * @return {number}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getLastmoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setLastmoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated ChessListOfChessPoint possibleMoves = 10;
 * @return {!Array<!proto.app.gambo.ChessListOfChessPoint>}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getPossiblemovesList = function() {
  return /** @type{!Array<!proto.app.gambo.ChessListOfChessPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.ChessListOfChessPoint, 10));
};


/**
 * @param {!Array<!proto.app.gambo.ChessListOfChessPoint>} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
*/
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setPossiblemovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.app.gambo.ChessListOfChessPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.ChessListOfChessPoint}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.addPossiblemoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.app.gambo.ChessListOfChessPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.clearPossiblemovesList = function() {
  return this.setPossiblemovesList([]);
};


/**
 * optional bool gameFinished = 11;
 * @return {boolean}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 winner = 12;
 * @return {number}
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessCurrentStateMessageResponse} returns this
 */
proto.app.gambo.ChessCurrentStateMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessRestartMessageRequest}
 */
proto.app.gambo.ChessRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessRestartMessageRequest;
  return proto.app.gambo.ChessRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessRestartMessageRequest}
 */
proto.app.gambo.ChessRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.ChessRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessRestartMessageRequest} returns this
 */
proto.app.gambo.ChessRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessRestartMessageResponse}
 */
proto.app.gambo.ChessRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessRestartMessageResponse;
  return proto.app.gambo.ChessRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessRestartMessageResponse}
 */
proto.app.gambo.ChessRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.ChessRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessRestartMessageResponse} returns this
 */
proto.app.gambo.ChessRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessFinishMessageRequest}
 */
proto.app.gambo.ChessFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessFinishMessageRequest;
  return proto.app.gambo.ChessFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessFinishMessageRequest}
 */
proto.app.gambo.ChessFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.ChessFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.ChessFinishMessageRequest} returns this
 */
proto.app.gambo.ChessFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessFinishMessageResponse}
 */
proto.app.gambo.ChessFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessFinishMessageResponse;
  return proto.app.gambo.ChessFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessFinishMessageResponse}
 */
proto.app.gambo.ChessFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.ChessFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.ChessFinishMessageResponse} returns this
 */
proto.app.gambo.ChessFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.ChessListOfChessPoint.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ChessListOfChessPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ChessListOfChessPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ChessListOfChessPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessListOfChessPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.app.gambo.ChessPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ChessListOfChessPoint}
 */
proto.app.gambo.ChessListOfChessPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ChessListOfChessPoint;
  return proto.app.gambo.ChessListOfChessPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ChessListOfChessPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ChessListOfChessPoint}
 */
proto.app.gambo.ChessListOfChessPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.ChessPoint;
      reader.readMessage(value,proto.app.gambo.ChessPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ChessListOfChessPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ChessListOfChessPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ChessListOfChessPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ChessListOfChessPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.app.gambo.ChessPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChessPoint points = 1;
 * @return {!Array<!proto.app.gambo.ChessPoint>}
 */
proto.app.gambo.ChessListOfChessPoint.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.app.gambo.ChessPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.ChessPoint, 1));
};


/**
 * @param {!Array<!proto.app.gambo.ChessPoint>} value
 * @return {!proto.app.gambo.ChessListOfChessPoint} returns this
*/
proto.app.gambo.ChessListOfChessPoint.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.app.gambo.ChessPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.ChessPoint}
 */
proto.app.gambo.ChessListOfChessPoint.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.app.gambo.ChessPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.ChessListOfChessPoint} returns this
 */
proto.app.gambo.ChessListOfChessPoint.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoStartGameMessageRequest}
 */
proto.app.gambo.GoStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoStartGameMessageRequest;
  return proto.app.gambo.GoStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoStartGameMessageRequest}
 */
proto.app.gambo.GoStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.GoStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoStartGameMessageRequest} returns this
 */
proto.app.gambo.GoStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinate: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoPoint}
 */
proto.app.gambo.GoPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoPoint;
  return proto.app.gambo.GoPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoPoint}
 */
proto.app.gambo.GoPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoordinate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 coordinate = 1;
 * @return {number}
 */
proto.app.gambo.GoPoint.prototype.getCoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoPoint} returns this
 */
proto.app.gambo.GoPoint.prototype.setCoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoTypedPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoTypedPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoTypedPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoTypedPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && proto.app.gambo.GoPoint.toObject(includeInstance, f),
    pointcolor: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoTypedPoint}
 */
proto.app.gambo.GoTypedPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoTypedPoint;
  return proto.app.gambo.GoTypedPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoTypedPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoTypedPoint}
 */
proto.app.gambo.GoTypedPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.GoPoint;
      reader.readMessage(value,proto.app.gambo.GoPoint.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.GoPointColor} */ (reader.readEnum());
      msg.setPointcolor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoTypedPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoTypedPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoTypedPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoTypedPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.GoPoint.serializeBinaryToWriter
    );
  }
  f = message.getPointcolor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional GoPoint point = 1;
 * @return {?proto.app.gambo.GoPoint}
 */
proto.app.gambo.GoTypedPoint.prototype.getPoint = function() {
  return /** @type{?proto.app.gambo.GoPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoPoint, 1));
};


/**
 * @param {?proto.app.gambo.GoPoint|undefined} value
 * @return {!proto.app.gambo.GoTypedPoint} returns this
*/
proto.app.gambo.GoTypedPoint.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GoTypedPoint} returns this
 */
proto.app.gambo.GoTypedPoint.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GoTypedPoint.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GoPointColor pointColor = 2;
 * @return {!proto.app.gambo.GoPointColor}
 */
proto.app.gambo.GoTypedPoint.prototype.getPointcolor = function() {
  return /** @type {!proto.app.gambo.GoPointColor} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.GoPointColor} value
 * @return {!proto.app.gambo.GoTypedPoint} returns this
 */
proto.app.gambo.GoTypedPoint.prototype.setPointcolor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    movetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    point: (f = msg.getPoint()) && proto.app.gambo.GoPoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoMoveMessageRequest}
 */
proto.app.gambo.GoMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoMoveMessageRequest;
  return proto.app.gambo.GoMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoMoveMessageRequest}
 */
proto.app.gambo.GoMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.GoMoveType} */ (reader.readEnum());
      msg.setMovetype(value);
      break;
    case 3:
      var value = new proto.app.gambo.GoPoint;
      reader.readMessage(value,proto.app.gambo.GoPoint.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.app.gambo.GoPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.GoMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoMoveMessageRequest} returns this
 */
proto.app.gambo.GoMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GoMoveType moveType = 2;
 * @return {!proto.app.gambo.GoMoveType}
 */
proto.app.gambo.GoMoveMessageRequest.prototype.getMovetype = function() {
  return /** @type {!proto.app.gambo.GoMoveType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.GoMoveType} value
 * @return {!proto.app.gambo.GoMoveMessageRequest} returns this
 */
proto.app.gambo.GoMoveMessageRequest.prototype.setMovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional GoPoint point = 3;
 * @return {?proto.app.gambo.GoPoint}
 */
proto.app.gambo.GoMoveMessageRequest.prototype.getPoint = function() {
  return /** @type{?proto.app.gambo.GoPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoPoint, 3));
};


/**
 * @param {?proto.app.gambo.GoPoint|undefined} value
 * @return {!proto.app.gambo.GoMoveMessageRequest} returns this
*/
proto.app.gambo.GoMoveMessageRequest.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GoMoveMessageRequest} returns this
 */
proto.app.gambo.GoMoveMessageRequest.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GoMoveMessageRequest.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.GoMoveMessageResponse.repeatedFields_ = [3,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    movetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    point: (f = msg.getPoint()) && proto.app.gambo.GoTypedPoint.toObject(includeInstance, f),
    removedpointsList: jspb.Message.toObjectList(msg.getRemovedpointsList(),
    proto.app.gambo.GoPoint.toObject, includeInstance),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    winner: jspb.Message.getFieldWithDefault(msg, 5, 0),
    capturedstonescountList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    impossiblemovesList: jspb.Message.toObjectList(msg.getImpossiblemovesList(),
    proto.app.gambo.GoPoint.toObject, includeInstance),
    remainingstonescountList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    moveplayer: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoMoveMessageResponse}
 */
proto.app.gambo.GoMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoMoveMessageResponse;
  return proto.app.gambo.GoMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoMoveMessageResponse}
 */
proto.app.gambo.GoMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.gambo.GoMoveType} */ (reader.readEnum());
      msg.setMovetype(value);
      break;
    case 2:
      var value = new proto.app.gambo.GoTypedPoint;
      reader.readMessage(value,proto.app.gambo.GoTypedPoint.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 3:
      var value = new proto.app.gambo.GoPoint;
      reader.readMessage(value,proto.app.gambo.GoPoint.deserializeBinaryFromReader);
      msg.addRemovedpoints(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCapturedstonescount(values[i]);
      }
      break;
    case 7:
      var value = new proto.app.gambo.GoPoint;
      reader.readMessage(value,proto.app.gambo.GoPoint.deserializeBinaryFromReader);
      msg.addImpossiblemoves(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRemainingstonescount(values[i]);
      }
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.gambo.GoTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getRemovedpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GoPoint.serializeBinaryToWriter
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCapturedstonescountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getImpossiblemovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.app.gambo.GoPoint.serializeBinaryToWriter
    );
  }
  f = message.getRemainingstonescountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional GoMoveType moveType = 1;
 * @return {!proto.app.gambo.GoMoveType}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getMovetype = function() {
  return /** @type {!proto.app.gambo.GoMoveType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.gambo.GoMoveType} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.setMovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional GoTypedPoint point = 2;
 * @return {?proto.app.gambo.GoTypedPoint}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getPoint = function() {
  return /** @type{?proto.app.gambo.GoTypedPoint} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.GoTypedPoint, 2));
};


/**
 * @param {?proto.app.gambo.GoTypedPoint|undefined} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
*/
proto.app.gambo.GoMoveMessageResponse.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GoPoint removedPoints = 3;
 * @return {!Array<!proto.app.gambo.GoPoint>}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getRemovedpointsList = function() {
  return /** @type{!Array<!proto.app.gambo.GoPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GoPoint, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GoPoint>} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
*/
proto.app.gambo.GoMoveMessageResponse.prototype.setRemovedpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GoPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoPoint}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.addRemovedpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GoPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.clearRemovedpointsList = function() {
  return this.setRemovedpointsList([]);
};


/**
 * optional bool gameFinished = 4;
 * @return {boolean}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 winner = 5;
 * @return {number}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated int32 capturedStonesCount = 6;
 * @return {!Array<number>}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getCapturedstonescountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.setCapturedstonescountList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.addCapturedstonescount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.clearCapturedstonescountList = function() {
  return this.setCapturedstonescountList([]);
};


/**
 * repeated GoPoint impossibleMoves = 7;
 * @return {!Array<!proto.app.gambo.GoPoint>}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getImpossiblemovesList = function() {
  return /** @type{!Array<!proto.app.gambo.GoPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GoPoint, 7));
};


/**
 * @param {!Array<!proto.app.gambo.GoPoint>} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
*/
proto.app.gambo.GoMoveMessageResponse.prototype.setImpossiblemovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.app.gambo.GoPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoPoint}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.addImpossiblemoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.app.gambo.GoPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.clearImpossiblemovesList = function() {
  return this.setImpossiblemovesList([]);
};


/**
 * repeated int32 remainingStonesCount = 8;
 * @return {!Array<number>}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getRemainingstonescountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.setRemainingstonescountList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.addRemainingstonescount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.clearRemainingstonescountList = function() {
  return this.setRemainingstonescountList([]);
};


/**
 * optional int32 movePlayer = 9;
 * @return {number}
 */
proto.app.gambo.GoMoveMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoMoveMessageResponse} returns this
 */
proto.app.gambo.GoMoveMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoCurrentStateMessageRequest}
 */
proto.app.gambo.GoCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoCurrentStateMessageRequest;
  return proto.app.gambo.GoCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoCurrentStateMessageRequest}
 */
proto.app.gambo.GoCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.GoCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoCurrentStateMessageRequest} returns this
 */
proto.app.gambo.GoCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.GoCurrentStateMessageResponse.repeatedFields_ = [3,4,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    gomapList: jspb.Message.toObjectList(msg.getGomapList(),
    proto.app.gambo.GoTypedPoint.toObject, includeInstance),
    blackstones: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 6, 0),
    capturedstonescountList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    impossiblemovesList: jspb.Message.toObjectList(msg.getImpossiblemovesList(),
    proto.app.gambo.GoPoint.toObject, includeInstance),
    remainingstonescountList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    winner: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse}
 */
proto.app.gambo.GoCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoCurrentStateMessageResponse;
  return proto.app.gambo.GoCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse}
 */
proto.app.gambo.GoCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = new proto.app.gambo.GoTypedPoint;
      reader.readMessage(value,proto.app.gambo.GoTypedPoint.deserializeBinaryFromReader);
      msg.addGomap(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlackstones(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCapturedstonescount(values[i]);
      }
      break;
    case 8:
      var value = new proto.app.gambo.GoPoint;
      reader.readMessage(value,proto.app.gambo.GoPoint.deserializeBinaryFromReader);
      msg.addImpossiblemoves(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRemainingstonescount(values[i]);
      }
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getGomapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.gambo.GoTypedPoint.serializeBinaryToWriter
    );
  }
  f = message.getBlackstones();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCapturedstonescountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getImpossiblemovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.app.gambo.GoPoint.serializeBinaryToWriter
    );
  }
  f = message.getRemainingstonescountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      9,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
*/
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * repeated GoTypedPoint goMap = 4;
 * @return {!Array<!proto.app.gambo.GoTypedPoint>}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getGomapList = function() {
  return /** @type{!Array<!proto.app.gambo.GoTypedPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GoTypedPoint, 4));
};


/**
 * @param {!Array<!proto.app.gambo.GoTypedPoint>} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
*/
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setGomapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.gambo.GoTypedPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoTypedPoint}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.addGomap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.gambo.GoTypedPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.clearGomapList = function() {
  return this.setGomapList([]);
};


/**
 * optional bool blackStones = 5;
 * @return {boolean}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getBlackstones = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setBlackstones = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 movePlayer = 6;
 * @return {number}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated int32 capturedStonesCount = 7;
 * @return {!Array<number>}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getCapturedstonescountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setCapturedstonescountList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.addCapturedstonescount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.clearCapturedstonescountList = function() {
  return this.setCapturedstonescountList([]);
};


/**
 * repeated GoPoint impossibleMoves = 8;
 * @return {!Array<!proto.app.gambo.GoPoint>}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getImpossiblemovesList = function() {
  return /** @type{!Array<!proto.app.gambo.GoPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GoPoint, 8));
};


/**
 * @param {!Array<!proto.app.gambo.GoPoint>} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
*/
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setImpossiblemovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.app.gambo.GoPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoPoint}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.addImpossiblemoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.app.gambo.GoPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.clearImpossiblemovesList = function() {
  return this.setImpossiblemovesList([]);
};


/**
 * repeated int32 remainingStonesCount = 9;
 * @return {!Array<number>}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getRemainingstonescountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setRemainingstonescountList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.addRemainingstonescount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.clearRemainingstonescountList = function() {
  return this.setRemainingstonescountList([]);
};


/**
 * optional bool gameFinished = 10;
 * @return {boolean}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 winner = 11;
 * @return {number}
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoCurrentStateMessageResponse} returns this
 */
proto.app.gambo.GoCurrentStateMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoRestartMessageRequest}
 */
proto.app.gambo.GoRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoRestartMessageRequest;
  return proto.app.gambo.GoRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoRestartMessageRequest}
 */
proto.app.gambo.GoRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.GoRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoRestartMessageRequest} returns this
 */
proto.app.gambo.GoRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoRestartMessageResponse}
 */
proto.app.gambo.GoRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoRestartMessageResponse;
  return proto.app.gambo.GoRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoRestartMessageResponse}
 */
proto.app.gambo.GoRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.GoRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.GoRestartMessageResponse} returns this
 */
proto.app.gambo.GoRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoFinishMessageRequest}
 */
proto.app.gambo.GoFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoFinishMessageRequest;
  return proto.app.gambo.GoFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoFinishMessageRequest}
 */
proto.app.gambo.GoFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.GoFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.GoFinishMessageRequest} returns this
 */
proto.app.gambo.GoFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.GoFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.GoFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.GoFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.GoFinishMessageResponse}
 */
proto.app.gambo.GoFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.GoFinishMessageResponse;
  return proto.app.gambo.GoFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.GoFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.GoFinishMessageResponse}
 */
proto.app.gambo.GoFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.GoFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.GoFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.GoFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.GoFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.GoFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.GoFinishMessageResponse} returns this
 */
proto.app.gambo.GoFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonPointColorAndCount.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonPointColorAndCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonPointColorAndCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonPointColorAndCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointcolor: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonPointColorAndCount}
 */
proto.app.gambo.BackgammonPointColorAndCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonPointColorAndCount;
  return proto.app.gambo.BackgammonPointColorAndCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonPointColorAndCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonPointColorAndCount}
 */
proto.app.gambo.BackgammonPointColorAndCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.gambo.BackgammonPointColor} */ (reader.readEnum());
      msg.setPointcolor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonPointColorAndCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonPointColorAndCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonPointColorAndCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonPointColorAndCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointcolor();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional BackgammonPointColor pointColor = 1;
 * @return {!proto.app.gambo.BackgammonPointColor}
 */
proto.app.gambo.BackgammonPointColorAndCount.prototype.getPointcolor = function() {
  return /** @type {!proto.app.gambo.BackgammonPointColor} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.gambo.BackgammonPointColor} value
 * @return {!proto.app.gambo.BackgammonPointColorAndCount} returns this
 */
proto.app.gambo.BackgammonPointColorAndCount.prototype.setPointcolor = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.app.gambo.BackgammonPointColorAndCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonPointColorAndCount} returns this
 */
proto.app.gambo.BackgammonPointColorAndCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.ListOfInt32.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.ListOfInt32.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.ListOfInt32.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.ListOfInt32} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ListOfInt32.toObject = function(includeInstance, msg) {
  var f, obj = {
    numbersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.ListOfInt32}
 */
proto.app.gambo.ListOfInt32.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.ListOfInt32;
  return proto.app.gambo.ListOfInt32.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.ListOfInt32} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.ListOfInt32}
 */
proto.app.gambo.ListOfInt32.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNumbers(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.ListOfInt32.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.ListOfInt32.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.ListOfInt32} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.ListOfInt32.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumbersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 numbers = 1;
 * @return {!Array<number>}
 */
proto.app.gambo.ListOfInt32.prototype.getNumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.ListOfInt32} returns this
 */
proto.app.gambo.ListOfInt32.prototype.setNumbersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.ListOfInt32} returns this
 */
proto.app.gambo.ListOfInt32.prototype.addNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.ListOfInt32} returns this
 */
proto.app.gambo.ListOfInt32.prototype.clearNumbersList = function() {
  return this.setNumbersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    backgammontype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonStartGameMessageRequest}
 */
proto.app.gambo.BackgammonStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonStartGameMessageRequest;
  return proto.app.gambo.BackgammonStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonStartGameMessageRequest}
 */
proto.app.gambo.BackgammonStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.BackgammonType} */ (reader.readEnum());
      msg.setBackgammontype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBackgammontype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.BackgammonStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonStartGameMessageRequest} returns this
 */
proto.app.gambo.BackgammonStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BackgammonType backgammonType = 2;
 * @return {!proto.app.gambo.BackgammonType}
 */
proto.app.gambo.BackgammonStartGameMessageRequest.prototype.getBackgammontype = function() {
  return /** @type {!proto.app.gambo.BackgammonType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.BackgammonType} value
 * @return {!proto.app.gambo.BackgammonStartGameMessageRequest} returns this
 */
proto.app.gambo.BackgammonStartGameMessageRequest.prototype.setBackgammontype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.BackgammonMoveMessageRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    movetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startcoordinate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endcoordinate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dicenumbersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest}
 */
proto.app.gambo.BackgammonMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonMoveMessageRequest;
  return proto.app.gambo.BackgammonMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest}
 */
proto.app.gambo.BackgammonMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.BackgammonMoveType} */ (reader.readEnum());
      msg.setMovetype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartcoordinate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndcoordinate(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDicenumbers(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartcoordinate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEndcoordinate();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDicenumbersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest} returns this
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BackgammonMoveType moveType = 2;
 * @return {!proto.app.gambo.BackgammonMoveType}
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.getMovetype = function() {
  return /** @type {!proto.app.gambo.BackgammonMoveType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.BackgammonMoveType} value
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest} returns this
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.setMovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 startCoordinate = 3;
 * @return {number}
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.getStartcoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest} returns this
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.setStartcoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 endCoordinate = 4;
 * @return {number}
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.getEndcoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest} returns this
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.setEndcoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated int32 diceNumbers = 5;
 * @return {!Array<number>}
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.getDicenumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest} returns this
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.setDicenumbersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest} returns this
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.addDicenumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonMoveMessageRequest} returns this
 */
proto.app.gambo.BackgammonMoveMessageRequest.prototype.clearDicenumbersList = function() {
  return this.setDicenumbersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.BackgammonMoveMessageResponse.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    movetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startcoordinate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endcoordinate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dicenumbersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    pathList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    boardedpointscoordinatesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    possiblemovesList: jspb.Message.toObjectList(msg.getPossiblemovesList(),
    proto.app.gambo.ListOfInt32.toObject, includeInstance),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nextmovetype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    winner: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse}
 */
proto.app.gambo.BackgammonMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonMoveMessageResponse;
  return proto.app.gambo.BackgammonMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse}
 */
proto.app.gambo.BackgammonMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.gambo.BackgammonMoveType} */ (reader.readEnum());
      msg.setMovetype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartcoordinate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndcoordinate(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDicenumbers(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPath(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBoardedpointscoordinates(values[i]);
      }
      break;
    case 7:
      var value = new proto.app.gambo.ListOfInt32;
      reader.readMessage(value,proto.app.gambo.ListOfInt32.deserializeBinaryFromReader);
      msg.addPossiblemoves(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 9:
      var value = /** @type {!proto.app.gambo.BackgammonMoveType} */ (reader.readEnum());
      msg.setNextmovetype(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartcoordinate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEndcoordinate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDicenumbersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getPathList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getBoardedpointscoordinatesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getPossiblemovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.app.gambo.ListOfInt32.serializeBinaryToWriter
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNextmovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional BackgammonMoveType moveType = 1;
 * @return {!proto.app.gambo.BackgammonMoveType}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getMovetype = function() {
  return /** @type {!proto.app.gambo.BackgammonMoveType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.gambo.BackgammonMoveType} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setMovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 startCoordinate = 2;
 * @return {number}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getStartcoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setStartcoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 endCoordinate = 3;
 * @return {number}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getEndcoordinate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setEndcoordinate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated int32 diceNumbers = 4;
 * @return {!Array<number>}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getDicenumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setDicenumbersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.addDicenumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.clearDicenumbersList = function() {
  return this.setDicenumbersList([]);
};


/**
 * repeated int32 path = 5;
 * @return {!Array<number>}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getPathList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setPathList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.addPath = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.clearPathList = function() {
  return this.setPathList([]);
};


/**
 * repeated int32 boardedPointsCoordinates = 6;
 * @return {!Array<number>}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getBoardedpointscoordinatesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setBoardedpointscoordinatesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.addBoardedpointscoordinates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.clearBoardedpointscoordinatesList = function() {
  return this.setBoardedpointscoordinatesList([]);
};


/**
 * repeated ListOfInt32 possibleMoves = 7;
 * @return {!Array<!proto.app.gambo.ListOfInt32>}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getPossiblemovesList = function() {
  return /** @type{!Array<!proto.app.gambo.ListOfInt32>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.ListOfInt32, 7));
};


/**
 * @param {!Array<!proto.app.gambo.ListOfInt32>} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
*/
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setPossiblemovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.app.gambo.ListOfInt32=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.ListOfInt32}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.addPossiblemoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.app.gambo.ListOfInt32, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.clearPossiblemovesList = function() {
  return this.setPossiblemovesList([]);
};


/**
 * optional int32 movePlayer = 8;
 * @return {number}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional BackgammonMoveType nextMoveType = 9;
 * @return {!proto.app.gambo.BackgammonMoveType}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getNextmovetype = function() {
  return /** @type {!proto.app.gambo.BackgammonMoveType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.app.gambo.BackgammonMoveType} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setNextmovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool gameFinished = 10;
 * @return {boolean}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 winner = 11;
 * @return {number}
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonMoveMessageResponse} returns this
 */
proto.app.gambo.BackgammonMoveMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageRequest}
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonCurrentStateMessageRequest;
  return proto.app.gambo.BackgammonCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageRequest}
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageRequest} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.repeatedFields_ = [3,4,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    backgammonmapList: jspb.Message.toObjectList(msg.getBackgammonmapList(),
    proto.app.gambo.BackgammonPointColorAndCount.toObject, includeInstance),
    whitepoints: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    moveplayer: jspb.Message.getFieldWithDefault(msg, 6, 0),
    nextmovetype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dicenumbersList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    possiblemovesList: jspb.Message.toObjectList(msg.getPossiblemovesList(),
    proto.app.gambo.ListOfInt32.toObject, includeInstance),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    winner: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonCurrentStateMessageResponse;
  return proto.app.gambo.BackgammonCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = new proto.app.gambo.BackgammonPointColorAndCount;
      reader.readMessage(value,proto.app.gambo.BackgammonPointColorAndCount.deserializeBinaryFromReader);
      msg.addBackgammonmap(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhitepoints(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveplayer(value);
      break;
    case 7:
      var value = /** @type {!proto.app.gambo.BackgammonMoveType} */ (reader.readEnum());
      msg.setNextmovetype(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDicenumbers(values[i]);
      }
      break;
    case 9:
      var value = new proto.app.gambo.ListOfInt32;
      reader.readMessage(value,proto.app.gambo.ListOfInt32.deserializeBinaryFromReader);
      msg.addPossiblemoves(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getBackgammonmapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.app.gambo.BackgammonPointColorAndCount.serializeBinaryToWriter
    );
  }
  f = message.getWhitepoints();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMoveplayer();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNextmovetype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDicenumbersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getPossiblemovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.app.gambo.ListOfInt32.serializeBinaryToWriter
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWinner();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
*/
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * repeated BackgammonPointColorAndCount backgammonMap = 4;
 * @return {!Array<!proto.app.gambo.BackgammonPointColorAndCount>}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getBackgammonmapList = function() {
  return /** @type{!Array<!proto.app.gambo.BackgammonPointColorAndCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.BackgammonPointColorAndCount, 4));
};


/**
 * @param {!Array<!proto.app.gambo.BackgammonPointColorAndCount>} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
*/
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setBackgammonmapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.app.gambo.BackgammonPointColorAndCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.BackgammonPointColorAndCount}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.addBackgammonmap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.app.gambo.BackgammonPointColorAndCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.clearBackgammonmapList = function() {
  return this.setBackgammonmapList([]);
};


/**
 * optional bool whitePoints = 5;
 * @return {boolean}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getWhitepoints = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setWhitepoints = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 movePlayer = 6;
 * @return {number}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getMoveplayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setMoveplayer = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional BackgammonMoveType nextMoveType = 7;
 * @return {!proto.app.gambo.BackgammonMoveType}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getNextmovetype = function() {
  return /** @type {!proto.app.gambo.BackgammonMoveType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.app.gambo.BackgammonMoveType} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setNextmovetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated int32 diceNumbers = 8;
 * @return {!Array<number>}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getDicenumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setDicenumbersList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.addDicenumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.clearDicenumbersList = function() {
  return this.setDicenumbersList([]);
};


/**
 * repeated ListOfInt32 possibleMoves = 9;
 * @return {!Array<!proto.app.gambo.ListOfInt32>}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getPossiblemovesList = function() {
  return /** @type{!Array<!proto.app.gambo.ListOfInt32>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.ListOfInt32, 9));
};


/**
 * @param {!Array<!proto.app.gambo.ListOfInt32>} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
*/
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setPossiblemovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.app.gambo.ListOfInt32=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.ListOfInt32}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.addPossiblemoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.app.gambo.ListOfInt32, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.clearPossiblemovesList = function() {
  return this.setPossiblemovesList([]);
};


/**
 * optional bool gameFinished = 10;
 * @return {boolean}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 winner = 11;
 * @return {number}
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.getWinner = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonCurrentStateMessageResponse} returns this
 */
proto.app.gambo.BackgammonCurrentStateMessageResponse.prototype.setWinner = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonRestartMessageRequest}
 */
proto.app.gambo.BackgammonRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonRestartMessageRequest;
  return proto.app.gambo.BackgammonRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonRestartMessageRequest}
 */
proto.app.gambo.BackgammonRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.BackgammonRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonRestartMessageRequest} returns this
 */
proto.app.gambo.BackgammonRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonRestartMessageResponse}
 */
proto.app.gambo.BackgammonRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonRestartMessageResponse;
  return proto.app.gambo.BackgammonRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonRestartMessageResponse}
 */
proto.app.gambo.BackgammonRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.BackgammonRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.BackgammonRestartMessageResponse} returns this
 */
proto.app.gambo.BackgammonRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonFinishMessageRequest}
 */
proto.app.gambo.BackgammonFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonFinishMessageRequest;
  return proto.app.gambo.BackgammonFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonFinishMessageRequest}
 */
proto.app.gambo.BackgammonFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.BackgammonFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.BackgammonFinishMessageRequest} returns this
 */
proto.app.gambo.BackgammonFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.BackgammonFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.BackgammonFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.BackgammonFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.BackgammonFinishMessageResponse}
 */
proto.app.gambo.BackgammonFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.BackgammonFinishMessageResponse;
  return proto.app.gambo.BackgammonFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.BackgammonFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.BackgammonFinishMessageResponse}
 */
proto.app.gambo.BackgammonFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.BackgammonFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.BackgammonFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.BackgammonFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.BackgammonFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.BackgammonFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.BackgammonFinishMessageResponse} returns this
 */
proto.app.gambo.BackgammonFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    value: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answertype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    correctanswer: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizQuestion}
 */
proto.app.gambo.QuizQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizQuestion;
  return proto.app.gambo.QuizQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizQuestion}
 */
proto.app.gambo.QuizQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.app.gambo.QuizQuestionContentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {!proto.app.gambo.QuizQuestionAnswerType} */ (reader.readEnum());
      msg.setAnswertype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrectanswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswertype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCorrectanswer();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.app.gambo.QuizQuestion.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.QuizQuestion} returns this
 */
proto.app.gambo.QuizQuestion.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 number = 2;
 * @return {number}
 */
proto.app.gambo.QuizQuestion.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizQuestion} returns this
 */
proto.app.gambo.QuizQuestion.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional QuizQuestionContentType type = 3;
 * @return {!proto.app.gambo.QuizQuestionContentType}
 */
proto.app.gambo.QuizQuestion.prototype.getType = function() {
  return /** @type {!proto.app.gambo.QuizQuestionContentType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.app.gambo.QuizQuestionContentType} value
 * @return {!proto.app.gambo.QuizQuestion} returns this
 */
proto.app.gambo.QuizQuestion.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.app.gambo.QuizQuestion.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.QuizQuestion} returns this
 */
proto.app.gambo.QuizQuestion.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional QuizQuestionAnswerType answerType = 5;
 * @return {!proto.app.gambo.QuizQuestionAnswerType}
 */
proto.app.gambo.QuizQuestion.prototype.getAnswertype = function() {
  return /** @type {!proto.app.gambo.QuizQuestionAnswerType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.app.gambo.QuizQuestionAnswerType} value
 * @return {!proto.app.gambo.QuizQuestion} returns this
 */
proto.app.gambo.QuizQuestion.prototype.setAnswertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string correctAnswer = 6;
 * @return {string}
 */
proto.app.gambo.QuizQuestion.prototype.getCorrectanswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.QuizQuestion} returns this
 */
proto.app.gambo.QuizQuestion.prototype.setCorrectanswer = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.QuizScoreData.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizScoreData.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizScoreData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizScoreData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizScoreData.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalscore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    questionscoresList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizScoreData}
 */
proto.app.gambo.QuizScoreData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizScoreData;
  return proto.app.gambo.QuizScoreData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizScoreData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizScoreData}
 */
proto.app.gambo.QuizScoreData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalscore(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addQuestionscores(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizScoreData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizScoreData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizScoreData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizScoreData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalscore();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getQuestionscoresList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.QuizScoreData.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizScoreData} returns this
 */
proto.app.gambo.QuizScoreData.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 totalScore = 2;
 * @return {number}
 */
proto.app.gambo.QuizScoreData.prototype.getTotalscore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizScoreData} returns this
 */
proto.app.gambo.QuizScoreData.prototype.setTotalscore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int32 questionScores = 3;
 * @return {!Array<number>}
 */
proto.app.gambo.QuizScoreData.prototype.getQuestionscoresList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.QuizScoreData} returns this
 */
proto.app.gambo.QuizScoreData.prototype.setQuestionscoresList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizScoreData} returns this
 */
proto.app.gambo.QuizScoreData.prototype.addQuestionscores = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizScoreData} returns this
 */
proto.app.gambo.QuizScoreData.prototype.clearQuestionscoresList = function() {
  return this.setQuestionscoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizStartGameMessageRequest}
 */
proto.app.gambo.QuizStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizStartGameMessageRequest;
  return proto.app.gambo.QuizStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizStartGameMessageRequest}
 */
proto.app.gambo.QuizStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizStartGameMessageRequest} returns this
 */
proto.app.gambo.QuizStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizMoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizMoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizMoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    questionnumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answer: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizMoveMessageRequest}
 */
proto.app.gambo.QuizMoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizMoveMessageRequest;
  return proto.app.gambo.QuizMoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizMoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizMoveMessageRequest}
 */
proto.app.gambo.QuizMoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.QuizAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionnumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizMoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizMoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizMoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getQuestionnumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizMoveMessageRequest} returns this
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional QuizAction action = 2;
 * @return {!proto.app.gambo.QuizAction}
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.getAction = function() {
  return /** @type {!proto.app.gambo.QuizAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.QuizAction} value
 * @return {!proto.app.gambo.QuizMoveMessageRequest} returns this
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 questionNumber = 3;
 * @return {number}
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.getQuestionnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizMoveMessageRequest} returns this
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.setQuestionnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string answer = 4;
 * @return {string}
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.QuizMoveMessageRequest} returns this
 */
proto.app.gambo.QuizMoveMessageRequest.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.QuizMoveMessageResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizMoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizMoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizMoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && proto.app.gambo.QuizQuestion.toObject(includeInstance, f),
    responsetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    elapsedtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    playeranswer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scoreboardList: jspb.Message.toObjectList(msg.getScoreboardList(),
    proto.app.gambo.QuizScoreData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizMoveMessageResponse}
 */
proto.app.gambo.QuizMoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizMoveMessageResponse;
  return proto.app.gambo.QuizMoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizMoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizMoveMessageResponse}
 */
proto.app.gambo.QuizMoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.QuizQuestion;
      reader.readMessage(value,proto.app.gambo.QuizQuestion.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    case 2:
      var value = /** @type {!proto.app.gambo.QuizMoveResponseType} */ (reader.readEnum());
      msg.setResponsetype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setElapsedtime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayeranswer(value);
      break;
    case 5:
      var value = new proto.app.gambo.QuizScoreData;
      reader.readMessage(value,proto.app.gambo.QuizScoreData.deserializeBinaryFromReader);
      msg.addScoreboard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizMoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizMoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizMoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.QuizQuestion.serializeBinaryToWriter
    );
  }
  f = message.getResponsetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getElapsedtime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPlayeranswer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScoreboardList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.app.gambo.QuizScoreData.serializeBinaryToWriter
    );
  }
};


/**
 * optional QuizQuestion question = 1;
 * @return {?proto.app.gambo.QuizQuestion}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.getQuestion = function() {
  return /** @type{?proto.app.gambo.QuizQuestion} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizQuestion, 1));
};


/**
 * @param {?proto.app.gambo.QuizQuestion|undefined} value
 * @return {!proto.app.gambo.QuizMoveMessageResponse} returns this
*/
proto.app.gambo.QuizMoveMessageResponse.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.QuizMoveMessageResponse} returns this
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QuizMoveResponseType responseType = 2;
 * @return {!proto.app.gambo.QuizMoveResponseType}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.getResponsetype = function() {
  return /** @type {!proto.app.gambo.QuizMoveResponseType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.gambo.QuizMoveResponseType} value
 * @return {!proto.app.gambo.QuizMoveMessageResponse} returns this
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.setResponsetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 elapsedTime = 3;
 * @return {number}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.getElapsedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizMoveMessageResponse} returns this
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.setElapsedtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string playerAnswer = 4;
 * @return {string}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.getPlayeranswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.QuizMoveMessageResponse} returns this
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.setPlayeranswer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated QuizScoreData scoreboard = 5;
 * @return {!Array<!proto.app.gambo.QuizScoreData>}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.getScoreboardList = function() {
  return /** @type{!Array<!proto.app.gambo.QuizScoreData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.QuizScoreData, 5));
};


/**
 * @param {!Array<!proto.app.gambo.QuizScoreData>} value
 * @return {!proto.app.gambo.QuizMoveMessageResponse} returns this
*/
proto.app.gambo.QuizMoveMessageResponse.prototype.setScoreboardList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.app.gambo.QuizScoreData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizScoreData}
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.addScoreboard = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.app.gambo.QuizScoreData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizMoveMessageResponse} returns this
 */
proto.app.gambo.QuizMoveMessageResponse.prototype.clearScoreboardList = function() {
  return this.setScoreboardList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizCurrentStateMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizCurrentStateMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizCurrentStateMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizCurrentStateMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizCurrentStateMessageRequest}
 */
proto.app.gambo.QuizCurrentStateMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizCurrentStateMessageRequest;
  return proto.app.gambo.QuizCurrentStateMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizCurrentStateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizCurrentStateMessageRequest}
 */
proto.app.gambo.QuizCurrentStateMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizCurrentStateMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizCurrentStateMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizCurrentStateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizCurrentStateMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizCurrentStateMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageRequest} returns this
 */
proto.app.gambo.QuizCurrentStateMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.QuizCurrentStateMessageResponse.repeatedFields_ = [3,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizCurrentStateMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizCurrentStateMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizCurrentStateMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    playernumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    playercount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.GameUserInfo.toObject, includeInstance),
    remainingtime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    question: (f = msg.getQuestion()) && proto.app.gambo.QuizQuestion.toObject(includeInstance, f),
    totalquestions: jspb.Message.getFieldWithDefault(msg, 6, 0),
    answeredplayersList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    scoreboardList: jspb.Message.toObjectList(msg.getScoreboardList(),
    proto.app.gambo.QuizScoreData.toObject, includeInstance),
    gamefinished: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizCurrentStateMessageResponse;
  return proto.app.gambo.QuizCurrentStateMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizCurrentStateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayernumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlayercount(value);
      break;
    case 3:
      var value = new proto.app.gambo.GameUserInfo;
      reader.readMessage(value,proto.app.gambo.GameUserInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingtime(value);
      break;
    case 5:
      var value = new proto.app.gambo.QuizQuestion;
      reader.readMessage(value,proto.app.gambo.QuizQuestion.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalquestions(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAnsweredplayers(values[i]);
      }
      break;
    case 8:
      var value = new proto.app.gambo.QuizScoreData;
      reader.readMessage(value,proto.app.gambo.QuizScoreData.deserializeBinaryFromReader);
      msg.addScoreboard(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGamefinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizCurrentStateMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizCurrentStateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizCurrentStateMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayernumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlayercount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.GameUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getRemainingtime();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.app.gambo.QuizQuestion.serializeBinaryToWriter
    );
  }
  f = message.getTotalquestions();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAnsweredplayersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getScoreboardList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.app.gambo.QuizScoreData.serializeBinaryToWriter
    );
  }
  f = message.getGamefinished();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 playerNumber = 1;
 * @return {number}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getPlayernumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setPlayernumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 playerCount = 2;
 * @return {number}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getPlayercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setPlayercount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated GameUserInfo players = 3;
 * @return {!Array<!proto.app.gambo.GameUserInfo>}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.GameUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.GameUserInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.GameUserInfo>} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
*/
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.GameUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.GameUserInfo}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.GameUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * optional int32 remainingTime = 4;
 * @return {number}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getRemainingtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setRemainingtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional QuizQuestion question = 5;
 * @return {?proto.app.gambo.QuizQuestion}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getQuestion = function() {
  return /** @type{?proto.app.gambo.QuizQuestion} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.QuizQuestion, 5));
};


/**
 * @param {?proto.app.gambo.QuizQuestion|undefined} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
*/
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 totalQuestions = 6;
 * @return {number}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getTotalquestions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setTotalquestions = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated int32 answeredPlayers = 7;
 * @return {!Array<number>}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getAnsweredplayersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setAnsweredplayersList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.addAnsweredplayers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.clearAnsweredplayersList = function() {
  return this.setAnsweredplayersList([]);
};


/**
 * repeated QuizScoreData scoreboard = 8;
 * @return {!Array<!proto.app.gambo.QuizScoreData>}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getScoreboardList = function() {
  return /** @type{!Array<!proto.app.gambo.QuizScoreData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.QuizScoreData, 8));
};


/**
 * @param {!Array<!proto.app.gambo.QuizScoreData>} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
*/
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setScoreboardList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.app.gambo.QuizScoreData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.QuizScoreData}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.addScoreboard = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.app.gambo.QuizScoreData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.clearScoreboardList = function() {
  return this.setScoreboardList([]);
};


/**
 * optional bool gameFinished = 9;
 * @return {boolean}
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.getGamefinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizCurrentStateMessageResponse} returns this
 */
proto.app.gambo.QuizCurrentStateMessageResponse.prototype.setGamefinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizRestartMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizRestartMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizRestartMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizRestartMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizRestartMessageRequest}
 */
proto.app.gambo.QuizRestartMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizRestartMessageRequest;
  return proto.app.gambo.QuizRestartMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizRestartMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizRestartMessageRequest}
 */
proto.app.gambo.QuizRestartMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizRestartMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizRestartMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizRestartMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizRestartMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizRestartMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizRestartMessageRequest} returns this
 */
proto.app.gambo.QuizRestartMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizRestartMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizRestartMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizRestartMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizRestartMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizRestartMessageResponse}
 */
proto.app.gambo.QuizRestartMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizRestartMessageResponse;
  return proto.app.gambo.QuizRestartMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizRestartMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizRestartMessageResponse}
 */
proto.app.gambo.QuizRestartMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizRestartMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizRestartMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizRestartMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizRestartMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.QuizRestartMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizRestartMessageResponse} returns this
 */
proto.app.gambo.QuizRestartMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizFinishMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizFinishMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizFinishMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizFinishMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizFinishMessageRequest}
 */
proto.app.gambo.QuizFinishMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizFinishMessageRequest;
  return proto.app.gambo.QuizFinishMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizFinishMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizFinishMessageRequest}
 */
proto.app.gambo.QuizFinishMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizFinishMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizFinishMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizFinishMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizFinishMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 roomId = 1;
 * @return {number}
 */
proto.app.gambo.QuizFinishMessageRequest.prototype.getRoomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.QuizFinishMessageRequest} returns this
 */
proto.app.gambo.QuizFinishMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.QuizFinishMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.QuizFinishMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.QuizFinishMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizFinishMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.QuizFinishMessageResponse}
 */
proto.app.gambo.QuizFinishMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.QuizFinishMessageResponse;
  return proto.app.gambo.QuizFinishMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.QuizFinishMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.QuizFinishMessageResponse}
 */
proto.app.gambo.QuizFinishMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.QuizFinishMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.QuizFinishMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.QuizFinishMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.QuizFinishMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.QuizFinishMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.QuizFinishMessageResponse} returns this
 */
proto.app.gambo.QuizFinishMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.app.gambo.SeaBattlePointType = {
  SEA_BATTLE_POINT_TYPE_UNKNOWN: 0,
  SEA_BATTLE_POINT_TYPE_EMPTY: 1,
  SEA_BATTLE_POINT_TYPE_SHIP_EDGE: 2,
  SEA_BATTLE_POINT_TYPE_DAMAGED: 3,
  SEA_BATTLE_POINT_TYPE_DESTROYED: 4
};

/**
 * @enum {number}
 */
proto.app.gambo.MessageType = {
  MESSAGE_TYPE_UNKNOWN: 0,
  MESSAGE_TYPE_PING: 1,
  MESSAGE_TYPE_PONG: 2,
  MESSAGE_TYPE_ERROR_SERVER: 3,
  MESSAGE_TYPE_ERROR_CLIENT: 4,
  MESSAGE_TYPE_DOMINOES_START_GAME_REQUEST: 5,
  MESSAGE_TYPE_DOMINOES_MOVE_REQUEST: 6,
  MESSAGE_TYPE_DOMINOES_MOVE_RESPONSE: 7,
  MESSAGE_TYPE_DOMINOES_CURRENT_STATE_REQUEST: 8,
  MESSAGE_TYPE_DOMINOES_CURRENT_STATE_RESPONSE: 9,
  MESSAGE_TYPE_DOMINOES_RESTART_REQUEST: 10,
  MESSAGE_TYPE_DOMINOES_RESTART_RESPONSE: 11,
  MESSAGE_TYPE_DOMINOES_FINISH_REQUEST: 12,
  MESSAGE_TYPE_DOMINOES_FINISH_RESPONSE: 13,
  MESSAGE_TYPE_SEA_BATTLE_START_GAME_REQUEST: 14,
  MESSAGE_TYPE_SEA_BATTLE_MOVE_REQUEST: 15,
  MESSAGE_TYPE_SEA_BATTLE_MOVE_RESPONSE: 16,
  MESSAGE_TYPE_SEA_BATTLE_PLACE_SHIPS_REQUEST: 17,
  MESSAGE_TYPE_SEA_BATTLE_PLACE_SHIPS_RESPONSE: 18,
  MESSAGE_TYPE_SEA_BATTLE_PLAYERS_READY_RESPONSE: 19,
  MESSAGE_TYPE_SEA_BATTLE_CURRENT_STATE_REQUEST: 20,
  MESSAGE_TYPE_SEA_BATTLE_CURRENT_STATE_RESPONSE: 21,
  MESSAGE_TYPE_SEA_BATTLE_RESTART_REQUEST: 22,
  MESSAGE_TYPE_SEA_BATTLE_RESTART_RESPONSE: 23,
  MESSAGE_TYPE_SEA_BATTLE_FINISH_REQUEST: 24,
  MESSAGE_TYPE_SEA_BATTLE_FINISH_RESPONSE: 25,
  MESSAGE_TYPE_QUIZ_BUTTON_START_GAME_REQUEST: 26,
  MESSAGE_TYPE_QUIZ_BUTTON_MOVE_REQUEST: 27,
  MESSAGE_TYPE_QUIZ_BUTTON_MOVE_RESPONSE: 28,
  MESSAGE_TYPE_QUIZ_BUTTON_CURRENT_STATE_REQUEST: 29,
  MESSAGE_TYPE_QUIZ_BUTTON_CURRENT_STATE_RESPONSE: 30,
  MESSAGE_TYPE_QUIZ_BUTTON_RESTART_REQUEST: 31,
  MESSAGE_TYPE_QUIZ_BUTTON_RESTART_RESPONSE: 32,
  MESSAGE_TYPE_QUIZ_BUTTON_FINISH_REQUEST: 33,
  MESSAGE_TYPE_QUIZ_BUTTON_FINISH_RESPONSE: 34,
  MESSAGE_TYPE_CHECKERS_START_GAME_REQUEST: 35,
  MESSAGE_TYPE_CHECKERS_MOVE_REQUEST: 36,
  MESSAGE_TYPE_CHECKERS_MOVE_RESPONSE: 37,
  MESSAGE_TYPE_CHECKERS_CURRENT_STATE_REQUEST: 38,
  MESSAGE_TYPE_CHECKERS_CURRENT_STATE_RESPONSE: 39,
  MESSAGE_TYPE_CHECKERS_RESTART_REQUEST: 40,
  MESSAGE_TYPE_CHECKERS_RESTART_RESPONSE: 41,
  MESSAGE_TYPE_CHECKERS_FINISH_REQUEST: 42,
  MESSAGE_TYPE_CHECKERS_FINISH_RESPONSE: 43,
  MESSAGE_TYPE_DURAK_START_GAME_REQUEST: 44,
  MESSAGE_TYPE_DURAK_MOVE_REQUEST: 45,
  MESSAGE_TYPE_DURAK_MOVE_RESPONSE: 46,
  MESSAGE_TYPE_DURAK_CURRENT_STATE_REQUEST: 47,
  MESSAGE_TYPE_DURAK_CURRENT_STATE_RESPONSE: 48,
  MESSAGE_TYPE_DURAK_RESTART_REQUEST: 49,
  MESSAGE_TYPE_DURAK_RESTART_RESPONSE: 50,
  MESSAGE_TYPE_DURAK_FINISH_REQUEST: 51,
  MESSAGE_TYPE_DURAK_FINISH_RESPONSE: 52,
  MESSAGE_TYPE_CHESS_START_GAME_REQUEST: 53,
  MESSAGE_TYPE_CHESS_MOVE_REQUEST: 54,
  MESSAGE_TYPE_CHESS_MOVE_RESPONSE: 55,
  MESSAGE_TYPE_CHESS_CURRENT_STATE_REQUEST: 56,
  MESSAGE_TYPE_CHESS_CURRENT_STATE_RESPONSE: 57,
  MESSAGE_TYPE_CHESS_RESTART_REQUEST: 58,
  MESSAGE_TYPE_CHESS_RESTART_RESPONSE: 59,
  MESSAGE_TYPE_CHESS_FINISH_REQUEST: 60,
  MESSAGE_TYPE_CHESS_FINISH_RESPONSE: 61,
  MESSAGE_TYPE_GO_START_GAME_REQUEST: 62,
  MESSAGE_TYPE_GO_MOVE_REQUEST: 63,
  MESSAGE_TYPE_GO_MOVE_RESPONSE: 64,
  MESSAGE_TYPE_GO_CURRENT_STATE_REQUEST: 65,
  MESSAGE_TYPE_GO_CURRENT_STATE_RESPONSE: 66,
  MESSAGE_TYPE_GO_RESTART_REQUEST: 67,
  MESSAGE_TYPE_GO_RESTART_RESPONSE: 68,
  MESSAGE_TYPE_GO_FINISH_REQUEST: 69,
  MESSAGE_TYPE_GO_FINISH_RESPONSE: 70,
  MESSAGE_TYPE_BACKGAMMON_START_GAME_REQUEST: 71,
  MESSAGE_TYPE_BACKGAMMON_MOVE_REQUEST: 72,
  MESSAGE_TYPE_BACKGAMMON_MOVE_RESPONSE: 73,
  MESSAGE_TYPE_BACKGAMMON_CURRENT_STATE_REQUEST: 74,
  MESSAGE_TYPE_BACKGAMMON_CURRENT_STATE_RESPONSE: 75,
  MESSAGE_TYPE_BACKGAMMON_RESTART_REQUEST: 76,
  MESSAGE_TYPE_BACKGAMMON_RESTART_RESPONSE: 77,
  MESSAGE_TYPE_BACKGAMMON_FINISH_REQUEST: 78,
  MESSAGE_TYPE_BACKGAMMON_FINISH_RESPONSE: 79
};

/**
 * @enum {number}
 */
proto.app.gambo.DominoesMoveAction = {
  DOMINOES_MOVE_ACTION_UNKNOWN: 0,
  DOMINOES_MOVE_ACTION_PUT: 1,
  DOMINOES_MOVE_ACTION_PASS: 2,
  DOMINOES_MOVE_ACTION_TAKE: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.DominoesTileDirection = {
  DOMINOES_TILE_DIRECTION_UNKNOWN: 0,
  DOMINOES_TILE_DIRECTION_ABSENT: 1,
  DOMINOES_TILE_DIRECTION_STRAIGHT: 2,
  DOMINOES_TILE_DIRECTION_LEFT: 3,
  DOMINOES_TILE_DIRECTION_RIGHT: 4
};

/**
 * @enum {number}
 */
proto.app.gambo.DominoesMoveSide = {
  DOMINOES_MOVE_SIDE_UNKNOWN: 0,
  DOMINOES_MOVE_SIDE_HEAD: 1,
  DOMINOES_MOVE_SIDE_TAIL: 2
};

/**
 * @enum {number}
 */
proto.app.gambo.QuizButtonAction = {
  QUIZ_BUTTON_ACTION_UNKNOWN: 0,
  QUIZ_BUTTON_ACTION_HIT_BUTTON: 1,
  QUIZ_BUTTON_ACTION_NOTHING: 2,
  QUIZ_BUTTON_ACTION_BLOCK_PLAYER: 3,
  QUIZ_BUTTON_ACTION_GIVE_POINTS: 4,
  QUIZ_BUTTON_ACTION_RESET_GAME: 5,
  QUIZ_BUTTON_ACTION_FINISH_GAME: 6
};

/**
 * @enum {number}
 */
proto.app.gambo.CheckersPointColor = {
  CHECKERS_POINT_COLOR_UNKNOWN: 0,
  CHECKERS_POINT_COLOR_WHITE: 1,
  CHECKERS_POINT_COLOR_BLACK: 2,
  CHECKERS_POINT_COLOR_EMPTY: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.CheckersPointState = {
  CHECKERS_POINT_STATE_UNKNOWN: 0,
  CHECKERS_POINT_STATE_REGULAR: 1,
  CHECKERS_POINT_STATE_KING: 2,
  CHECKERS_POINT_STATE_EMPTY: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.DurakCardSuit = {
  DURAK_CARD_SUIT_UNKNOWN: 0,
  DURAK_CARD_SUIT_SPADES: 1,
  DURAK_CARD_SUIT_HEARTS: 2,
  DURAK_CARD_SUIT_CLUBS: 3,
  DURAK_CARD_SUIT_DIAMONDS: 4
};

/**
 * @enum {number}
 */
proto.app.gambo.DurakMoveAction = {
  DURAK_MOVE_ACTION_UNKNOWN: 0,
  DURAK_MOVE_ACTION_ATTACK: 1,
  DURAK_MOVE_ACTION_DEFENSE: 2,
  DURAK_MOVE_ACTION_TAKE: 3,
  DURAK_MOVE_ACTION_FOLD: 4,
  DURAK_MOVE_ACTION_END_OF_MOVE: 5
};

/**
 * @enum {number}
 */
proto.app.gambo.DurakAllowedAction = {
  DURAK_ALLOWED_ACTION_UNKNOWN: 0,
  DURAK_ALLOWED_ACTION_ATTACK: 1,
  DURAK_ALLOWED_ACTION_DEFENSE: 2,
  DURAK_ALLOWED_ACTION_NONE: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.ChessPointColor = {
  CHESS_POINT_COLOR_UNKNOWN: 0,
  CHESS_POINT_COLOR_WHITE: 1,
  CHESS_POINT_COLOR_BLACK: 2,
  CHESS_POINT_COLOR_EMPTY: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.ChessPointState = {
  CHESS_POINT_STATE_UNKNOWN: 0,
  CHESS_POINT_STATE_PAWN: 1,
  CHESS_POINT_STATE_BISHOP: 2,
  CHESS_POINT_STATE_KNIGHT: 3,
  CHESS_POINT_STATE_ROOK: 4,
  CHESS_POINT_STATE_QUEEN: 5,
  CHESS_POINT_STATE_KING: 6,
  CHESS_POINT_STATE_EMPTY: 7
};

/**
 * @enum {number}
 */
proto.app.gambo.ChessMoveType = {
  CHESS_MOVE_TYPE_UNKNOWN: 0,
  CHESS_MOVE_TYPE_REGULAR: 1,
  CHESS_MOVE_TYPE_REPLACE_PAWN: 2,
  CHESS_MOVE_TYPE_DRAW: 3,
  CHESS_MOVE_TYPE_LOSE: 4,
  CHESS_MOVE_TYPE_WIN: 5,
  CHESS_MOVE_TYPE_ACCEPT_PROPOSAL: 6,
  CHESS_MOVE_TYPE_DECLINE_PROPOSAL: 7
};

/**
 * @enum {number}
 */
proto.app.gambo.GoPointColor = {
  GO_POINT_COLOR_UNKNOWN: 0,
  GO_POINT_COLOR_WHITE: 1,
  GO_POINT_COLOR_BLACK: 2,
  GO_POINT_COLOR_EMPTY: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.GoMoveType = {
  GO_MOVE_TYPE_UNKNOWN: 0,
  GO_MOVE_TYPE_REGULAR: 1,
  GO_MOVE_TYPE_PASS: 2
};

/**
 * @enum {number}
 */
proto.app.gambo.BackgammonPointColor = {
  BACKGAMMON_POINT_COLOR_UNKNOWN: 0,
  BACKGAMMON_POINT_COLOR_WHITE: 1,
  BACKGAMMON_POINT_COLOR_BLACK: 2,
  BACKGAMMON_POINT_COLOR_EMPTY: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.BackgammonMoveType = {
  BACKGAMMON_MOVE_TYPE_UNKNOWN: 0,
  BACKGAMMON_MOVE_TYPE_REGULAR: 1,
  BACKGAMMON_MOVE_TYPE_ROLL_DICE: 2
};

/**
 * @enum {number}
 */
proto.app.gambo.BackgammonType = {
  BACKGAMMON_TYPE_UNKNOWN: 0,
  BACKGAMMON_TYPE_SHORT: 1,
  BACKGAMMON_TYPE_LONG: 2
};

/**
 * @enum {number}
 */
proto.app.gambo.QuizAction = {
  QUIZ_ACTION_UNKNOWN: 0,
  QUIZ_ACTION_START_QUESTION: 1,
  QUIZ_ACTION_FINISH_QUESTION: 2,
  QUIZ_ACTION_ANSWER_QUESTION: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.QuizMoveResponseType = {
  QUIZ_MOVE_RESPONSE_TYPE_UNKNOWN: 0,
  QUIZ_MOVE_RESPONSE_TYPE_STARTED_QUESTION: 1,
  QUIZ_MOVE_RESPONSE_TYPE_FINISHED_QUESTION_USER: 2,
  QUIZ_MOVE_RESPONSE_TYPE_FINISHED_QUESTION_HOST: 3
};

/**
 * @enum {number}
 */
proto.app.gambo.QuizQuestionContentType = {
  QUIZ_QUESTION_CONTENT_TYPE_UNKNOWN: 0,
  QUIZ_QUESTION_CONTENT_TYPE_TEXT: 1,
  QUIZ_QUESTION_CONTENT_TYPE_IMAGE: 2,
  QUIZ_QUESTION_CONTENT_TYPE_AUDIO: 3,
  QUIZ_QUESTION_CONTENT_TYPE_VIDEO: 4
};

/**
 * @enum {number}
 */
proto.app.gambo.QuizQuestionAnswerType = {
  QUIZ_QUESTION_ANSWER_TYPE_UNKNOWN: 0,
  QUIZ_QUESTION_ANSWER_TYPE_ONE: 1,
  QUIZ_QUESTION_ANSWER_TYPE_MULTI: 2,
  QUIZ_QUESTION_ANSWER_TYPE_SPEED: 3,
  QUIZ_QUESTION_ANSWER_TYPE_CUSTOM: 4
};

goog.object.extend(exports, proto.app.gambo);
