import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface QuizInfo {
    id: string;
    name: string;
    description: string;
    questions: Array<QuizQuestionInfo>;
}

export enum QuestionType {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO'
}

export enum AnswerType {
    SPEED = 'SPEED',
    ONE = 'ONE',
    MULTI = 'MULTI',
    CUSTOM = 'CUSTOM'
}

export interface QuizQuestionInfo {
    id?: string;
    number: number;
    type: QuestionType;
    value: string;
    answerType: AnswerType;
    correctAnswer: string;
}

@Injectable()
export class QuizConstructorService {

    private quizList: Array<QuizInfo>;
    private quiz: QuizInfo;
    public quizListSubject = new Subject<Array<QuizInfo>>();
    public quizSubject = new Subject<QuizInfo>();

    constructor(
        private httpClient: HttpClient
    ) { }

    public getQuiz(quizId: string) {
        this.httpClient.post<QuizInfo>(
            environment.URLPrefix + '/quiz/get',
            encodeURIComponent(JSON.stringify({
                id: quizId
            }))
        ).subscribe(ans => {
            this.quiz = ans;
            sessionStorage.setItem('SelectedQuizInfo', JSON.stringify(ans));
            this.quizSubject.next(ans);
        });
    }

    public getAllQuizzes() {
        this.httpClient.get<Array<QuizInfo>>(
            environment.URLPrefix + '/quiz/getAll',
            {}
        ).subscribe(ans => {
            this.quizList = ans;
            this.quizListSubject.next(ans);
        });
    }

    public createQuiz(name: string, description: string): Observable<{id: string}> {
        return this.httpClient.post<{id: string}>(
            environment.URLPrefix + '/quiz/create',
            encodeURIComponent(JSON.stringify({
                name,
                description
            }))
        );
    }

    public createQuestion(quizId: string, quizQuestionInfo: QuizQuestionInfo): Observable<{id: string}> {
        return this.httpClient.post<{id: string}>(
            environment.URLPrefix + '/quiz/question/create',
            encodeURIComponent(JSON.stringify({
                quizId,
                question: quizQuestionInfo
            }))
        ).pipe(
            tap(() => this.getQuiz(this.quiz.id))
        );
    }

    public updateQuestion(questionId: string, quizQuestionInfo: QuizQuestionInfo): Observable<{result: boolean}> {
        return this.httpClient.put<{result: boolean}>(
            environment.URLPrefix + '/quiz/question/update',
            encodeURIComponent(JSON.stringify({
                id: questionId,
                ...quizQuestionInfo
            }))
        ).pipe(
            tap(() => this.getQuiz(this.quiz.id))
        );
    }

    public deleteQuestion(questionId: string): Observable<{result: boolean}> {
        return this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/quiz/question/delete',
            encodeURIComponent(JSON.stringify({
                id: questionId
            }))
        ).pipe(
            tap(() => this.getQuiz(this.quiz.id))
        );
    }
}
