import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BetaAccessLandingModule } from '../modules/beta-access-landing/beta-access-landing.module';
import { PrivacyPolicyModule } from '../modules/privacy-policy/privacy-policy.module';
import { NewsletterUnsubscribeModule } from '../modules/newsletter-unsubscribe/newsletter-unsubscribe.module';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthInterceptor } from '../modules/auth/auth/auth.interceptor';
import { AuthModule } from '../modules/auth/auth.module';
import { GamesModule } from '../modules/games/games.module';
import { MaterialAllInOneModule } from '../libs/material-all-in-one/material-all-in-one.module';
import { FriendsModule } from '../modules/friends/friends.module';
import { I18NModule } from '../libs/translate/i18n.module';
import { QuizConstructorModule } from '../modules/quiz-constructor/quiz-constructor.module';
import { RoomManagerModule } from '../modules/room-manager/room-manager.module';

const firebaseConfig = {
    apiKey: environment.apiKey,
    authDomain: environment.authDomain,
    projectId: environment.projectId,
    storageBucket: environment.storageBucket,
    messagingSenderId: environment.messagingSenderId,
    appId: environment.appId,
    measurementId: environment.measurementId
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        MaterialAllInOneModule,
        AppRoutingModule,
        HttpClientModule,
        I18NModule.forRoot(),
        /*TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),*/
        BetaAccessLandingModule,
        PrivacyPolicyModule,
        NewsletterUnsubscribeModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AuthModule,
        GamesModule,
        FriendsModule,
        QuizConstructorModule,
        RoomManagerModule
    ],
    providers: [
        { provide: 'googleTagManagerId', useValue: 'GTM-59NLN42' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './i18n/', '.json');
}
