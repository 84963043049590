import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnswerType } from '../../quiz-constructor.service';

@Component({
    selector: 'app-answer-constructor',
    templateUrl: './answer-constructor.component.html',
    styleUrls: ['./answer-constructor.component.scss']
})
export class AnswerConstructorComponent implements OnInit {

    @Input()
    public selectedAnswerType = AnswerType.ONE;

    // TODO get premium info from service
    public premium = false;
    public answerFormGroup: FormGroup;
    public answerTypes = AnswerType;
    public time = 20;
    public points = 1;
    public numberOfAnswers = 4;
    public textAnswers = new Array<string>(8);
    public correctAnswers = new Array<number>();

    constructor(
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.answerFormGroup = this.formBuilder.group({
            timeControl: new FormControl(this.time, [Validators.required, Validators.min(1)]),
            pointsControl: new FormControl(this.points, [Validators.required, Validators.min(0)]),
            numberOfAnswersControl: new FormControl(this.numberOfAnswers, [Validators.required]),
            textAnswer1Control: new FormControl(
                this.textAnswers[0],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer2Control: new FormControl(
                this.textAnswers[1],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer3Control: new FormControl(
                this.textAnswers[2],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer4Control: new FormControl(
                this.textAnswers[3],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer5Control: new FormControl(
                this.textAnswers[4],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer6Control: new FormControl(
                this.textAnswers[5],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer7Control: new FormControl(
                this.textAnswers[6],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer8Control: new FormControl(
                this.textAnswers[7],
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            )
        });
    }

    changeSelectedAnswerType(answerType: AnswerType) {
        if (answerType === AnswerType.CUSTOM) {
            this.snackBar.open(
                'Buy subscription',
                '',
                {
                    duration: 3000
                }
            );
        } else {
            this.selectedAnswerType = answerType;
        }
    }

    public counter(i: number) {
        return new Array(i);
    }

    formatTimeLabel(value: number) {
        if (value >= 60) {
            return Math.round(value / 60) + ' m';
        }

        return value + ' s';
    }

    setCorrectAnswer(i: number) {
        if (this.selectedAnswerType === AnswerType.ONE) {
            this.correctAnswers = [i];
        } else if (this.selectedAnswerType === AnswerType.MULTI) {
            if (this.correctAnswers.includes(i)) {
                this.correctAnswers.splice(this.correctAnswers.indexOf(i), 1);
            } else {
                this.correctAnswers.push(i);
            }
        }
        console.log(this.correctAnswers);
    }
}
