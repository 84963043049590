import { Component, OnInit } from '@angular/core';
import { WebSocketConnect } from '../../../libs/websocket/WebSocketConnect';
import { StreamReader } from '../../../libs/websocket/StreamReader';
import { ErrorClientMessage, GeneralMessage, MessageType, PingMessage } from '../../../../proto/generated/gambo_pb';

@Component({
    selector: 'app-quiz-runner',
    templateUrl: './quiz-runner.component.html',
    styleUrls: ['./quiz-runner.component.scss']
})
export class QuizRunnerComponent implements OnInit {

    ws: WebSocketConnect;
    streamReader: StreamReader;

    constructor() {
    }

    ngOnInit(): void {
        this.ws = new WebSocketConnect('ws://188.166.159.131:9878/ws', null, 'arraybuffer', true);
        this.ws.init();
        this.streamReader = new StreamReader(this.ws);
        this.ws.onconnect = () => this.connectionHandler();
        this.ws.onmessage = (msg) => this.messageHandler(msg);
        this.ws.onclose = () => this.connectionCloseHandler();
        // this.ws.enableHeartbeat();
    }

    public connectionHandler() {
        console.log('Connected to the remote');
        const msg = new GeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_PING);
        const pingMsg = new PingMessage();
        pingMsg.setHash('numberOfMessage');
        msg.setPingmessage(pingMsg);
        this.ws.send(msg.serializeBinary());
        // this.ws.send('message string');
    }

    public messageHandler(msg: any) {
        const response = GeneralMessage.deserializeBinary(msg);
        console.log(response);
        console.log(response.getPongmessage().getHash());
    }

    public connectionCloseHandler() {
        console.log('Closing in handler');
    }

    public connect() {
        console.log('Connecting');
        this.ws.init();
        /*
         let a = this.service.messages$.pipe(
         map(rows => rows.data),
         catchError(error => { throw error }),
         tap({
         error: error => console.log('[Live component] Error:', error),
         complete: () => console.log('[Live component] Connection Closed')
         })
         ).subscribe(msg => {
         console.log(msg)
         })
         */
        // this.service.sendMessage({c: 'd'})
        // a.unsubscribe()
        // this.service.close()
    }

    public sendSomething() {
        // subject.subscribe()

        // subject.next({a: 'b'})
        // subject.complete()
        // alert(123)
        // this.service.sendMessage({a: 'b'})
        // console.log(654)
        const msg = new GeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_ERROR_CLIENT);
        const errorMsg = new ErrorClientMessage();
        errorMsg.setErrorcode('code');
        errorMsg.setErrormessage('message');
        msg.setErrorclientmessage(errorMsg);
        console.log(msg.serializeBinary());
        this.ws.send(msg.serializeBinary());
        // this.ws.send('hello again ' + this.v)
    }

    public disconnect() {
        this.ws.close();
    }

}
