import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuizInfo, QuizConstructorService } from '../quiz-constructor.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateQuizDialogComponent, QuizDescriptionFields } from './create-quiz-dialog/create-quiz-dialog.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth/auth.service';

@Component({
    selector: 'app-quiz-list',
    templateUrl: './quiz-list.component.html',
    styleUrls: ['./quiz-list.component.scss']
})
export class QuizListComponent implements OnInit, OnDestroy {

    public quizList = new Array<QuizInfo>();
    private quizListS: Subscription;

    constructor(
        private quizService: QuizConstructorService,
        private matDialog: MatDialog,
        private router: Router,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.quizListS = this.quizService.quizListSubject.subscribe(quizList => {
            this.quizList = quizList;
        });
        this.quizService.getAllQuizzes();
    }

    public openCreateQuizDialog() {
        console.log(this.quizList);
        const quizDescription: QuizDescriptionFields = {
            name: '',
            description: ''
        };
        this.matDialog.open(CreateQuizDialogComponent, {
            width: '400px',
            data: quizDescription
        }).afterClosed().subscribe(result => {
            if (result) {
                // TODO: need ID to edit
                this.quizService.createQuiz(result.name, result.description).subscribe(ans => {
                    console.log('Create result', ans);
                    this.quizService.getAllQuizzes();
                });
            }
        });
    }

    public openQuiz(quizInfo: QuizInfo) {
        sessionStorage.setItem('SelectedQuizInfo', JSON.stringify(quizInfo));
        this.router.navigate(['/quiz-editor']);
    }

    ngOnDestroy() {
        this.quizService.quizListSubject.unsubscribe();
    }

    runQuiz(quizId: string) {
        this.router.navigate(['/room-manager'], { queryParams: { action: 'run', game: 'quiz', id: quizId } });
    }

    signOut() {
        this.authService.signOut();
    }
}
