<!--<div *ngIf="quizInfo">-->
<button mat-raised-button
        (click)="backToQuizList()">
    BACK
</button>
<mat-card *ngFor="let quizQuestionInfo of quizInfo.questions"
          (click)="updateQuestion(quizQuestionInfo)"
          class="quiz-card">
    <mat-card-header>
        <mat-card-title>{{ quizQuestionInfo.id }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        {{ quizQuestionInfo.type }}
        {{ quizQuestionInfo.value }}
        {{ quizQuestionInfo.answerType }}
        {{ quizQuestionInfo.correctAnswer }}
    </mat-card-content>
    <mat-card-actions>
        <!--        ADD ACTIONS-->
        <button mat-raised-button
                (click)="removeQuestion(quizQuestionInfo.id)">
            REMOVE
<!--            {{ "FRIENDS.APPLY" | translate }}-->
        </button>
    </mat-card-actions>
</mat-card>
<!--</div>-->
<button mat-raised-button
        (click)="createQuizQuestion()">
    {{ "FRIENDS.ADD" | translate }}
</button>
