<mat-card *ngFor="let quiz of quizList"
          class="quiz-card">
    <mat-card-header>
        <mat-card-title>{{ quiz.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-subtitle>{{ quiz.id }}</mat-card-subtitle>
    <mat-card-content>{{ quiz.description }}</mat-card-content>
    <mat-card-actions>
        <button mat-raised-button
                (click)="runQuiz(quiz.id)">
            {{ "QUIZ.RUN" | translate }}
        </button>
    </mat-card-actions>
</mat-card>
<button mat-raised-button
        (click)="openCreateQuizDialog()">
    {{ "FRIENDS.ADD" | translate }}
</button>
<button mat-raised-button
        color="accent"
        (click)="signOut()">
    SIGN OUT
</button>
